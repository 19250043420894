<div id="content">

  <div id="menu-top">
    <button (click)="goBack()" class="btn default">{{'GO_BACK' | translate:locale.language}}</button>
    <div id="flags">
      <img (click)="setCurrentLanguage('fr')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/france-3-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('en')" src="https://cdn2.iconfinder.com/data/icons/flags/flags/48/united-kingdom-great-britain.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('de')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/germany-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('nl')" src="https://cdn4.iconfinder.com/data/icons/world-flags-6/900/netherlands_dutch_holland_national_country_flag-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('es')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/spain-128.png" alt="" width="25px"/>
    </div>
  </div>


  <span id="privacy-policy"><h1>{{'PRIVACY_POLICY' | translate:locale.language}}</h1></span>

  <ol>
    <li>{{'SCOPE_OF_APPLICATION' | translate:locale.language}}</li>
    <span>{{'SCOPE_OF_APPLICATION_TEXT' | translate:locale.language}}</span>

    <li>{{'CONTROLLER' | translate:locale.language}}</li>
    <span>{{'CONTROLLER_TEXT' | translate:locale.language}}</span>

    <li>{{'DATA_WE_COLLECT' | translate:locale.language}}</li>
    <ol>
      <li>{{'STANDARD_DATA' | translate:locale.language}}</li>
      <span>{{'STANDARD_DATA_TEXT' | translate:locale.language}}</span>

      <span>
         <br>
        - {{'YOUR_IP_ADDRESS' | translate:locale.language}}<br>
        - {{'THE_DNS_ADDRESS' | translate:locale.language}}<br>
        - {{'THE_DATE_AND_DURATION' | translate:locale.language}}<br>
        - {{'THE_PAGES_YOU_VISIT' | translate:locale.language}}<br>
        - {{'YOUR_BROWSER' | translate:locale.language}}<br>
        - {{'URL_REFERRER' | translate:locale.language}}
        <br>
      </span>

      <li>{{'PERSONAL_CONTACT_DATA' | translate:locale.language}}</li>
      <br>
      <span>{{'PERSONAL_CONTACT_DATA_TEXT' | translate:locale.language}}</span><br>
      <span>
        1. {{'YOUR_FIRST_AND_LAST_NAMES' | translate:locale.language}}<br>
        2. {{'YOUR_OCCUPATION' | translate:locale.language}}<br>
        3. {{'YOUR_EMAIL_ADDRESS' | translate:locale.language}}<br>
        4. {{'YOUR_WORK_PHONE_NUMBER' | translate:locale.language}}<br>
        5. {{'YOUR_TIME_ZONE' | translate:locale.language}}
      </span>

      <span>{{'THESE_DATA_ARE_ALSO_COLLECTED' | translate:locale.language}}</span><br>
      <span>1. {{'PHONE_CALLS' | translate:locale.language}}</span><br>
      <span>2. {{'MESSAGES_SENT_THROUGH' | translate:locale.language}}</span><br>

      <span>{{'NO_SENSITIVE_DATA_SHALL_BE_COLLECTED' | translate:locale.language}}</span><br>

      <li>{{'NEWSLETTER' | translate:locale.language}}</li>
      <span>{{'NEWSLETTER_TEXT' | translate:locale.language}}</span><br>

      <li>{{'CONNECTION_TO_THE_SERVICE' | translate:locale.language}}</li>
      <span>{{'CONNECTION_TO_THE_SERVICE_TEXT' | translate:locale.language}}</span><br>

      <li>{{'COOKIES' | translate:locale.language}}</li>
      <span>{{'COOKIES_TEXT' | translate:locale.language}}</span><br>

    </ol>

    <li>{{'PURPOSE_OF_YOUR_DATA_PROCESSING' | translate:locale.language}}</li>
    <span>{{'WE_ONLY_COLLECT_AND_PROCESS' | translate:locale.language}}</span><br>
    <span>
         <br>
        - {{'YOUR_CONSENT_TO_RECEIVE_OUR_NEWSLETTERS' | translate:locale.language}}<br>
        - {{'THE_USE_OF_OUR_SITE_AND_SERVICES' | translate:locale.language}}<br>
        - {{'the_implementation_of_the_service' | translate:locale.language}}
        <br>
      </span>

    <li>{{'HOW_LONG_YOUR_DATA_WILL_BE_STORED' | translate:locale.language}}</li>
    <span>{{'HOW_LONG_YOUR_DATA_WILL_BE_STORED_TEXT' | translate:locale.language}}</span><br>

    <li>{{'TRANSFER_OF_YOUR_DATA' | translate:locale.language}}</li>
    <span>{{'TRANSFER_OF_YOUR_DATA_TEXT' | translate:locale.language}}</span><br>

    <li>{{'SECURITY' | translate:locale.language}}</li>
    <span>{{'SECURITY_TEXT' | translate:locale.language}}</span><br>

    <li>{{'RIGHTS_OF_ACCESS_AND_RECTIFICATION' | translate:locale.language}}</li>
    <span>{{'RIGHTS_OF_ACCESS_AND_RECTIFICATION_TEXT' | translate:locale.language}}</span><br>

    <li>{{'CLAIMS' | translate:locale.language}}</li>
    <span>{{'CLAIMS_TEXT' | translate:locale.language}}</span><br>

  </ol>


</div>
