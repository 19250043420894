import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {debug} from '../../../rxjs-operators';
import {ACCESS_TOKEN, IMPERSONATION_TOKEN} from '../consts';
import {AppSettings} from '../models/app-settings';
import {RequestOptionsArgs} from '../models/request-options-args';
import {SendBackResult} from '../models/send-back-result';
import {tokenNotExpired} from '../utilities/token-not-expired';

import {AppSettingsService} from './app-settings.service';
import {BaseService} from './base.service';

export abstract class BaseRestService extends BaseService {
  private settings$: Observable<AppSettings>;

  constructor(private http: HttpClient, protected appSettingsService: AppSettingsService) {
    super();
    this.settings$ = this.appSettingsService.settings$;
  }

  protected abstract get className(): string;

  protected abstract get isAnonymous(): boolean;
  // tslint:disable-next-line:no-any
  protected get<T>(url: string, options?: RequestOptionsArgs, headers?: any): Observable<SendBackResult<T>> {
    this.log(`${this.className}.post started for URL ${url} with header: `, headers);

    return this.settings$.pipe(
      switchMap(appSettings => this.http.get<SendBackResult<T>>(`${appSettings.api.url}${url}`, { headers: this.mergeOptions(appSettings, options, headers) })),
      debug(`${this.className}.get payload`));
  }
  // tslint:disable-next-line:no-any
  protected download(url: string, options?: RequestOptionsArgs): Observable<any> {
    this.log(`${this.className}.download started for URL ${url}`);
    return this.settings$.pipe(switchMap(appSettings => this.http.get(`${appSettings.api.url}${url}`, { headers: this.mergeOptions(appSettings, options) })),
                               debug(`${this.className}.download response`));
  }
  // tslint:disable-next-line:no-any
  protected post<T>(url: string, body: any, options?: RequestOptionsArgs, headers?: any): Observable<SendBackResult<T>> {
    this.log(`${this.className}.post started for URL ${url} with body: `, body);
    this.log(`${this.className}.post started for URL ${url} with header: `, headers);
    return this.settings$.pipe(switchMap(appSettings => this.http.post<SendBackResult<T>>(
                                           `${appSettings.api.url}${url}`, body, { headers: this.mergeOptions(appSettings, options, headers) })),
                               debug(`${this.className}.post payload`));
  }
  // tslint:disable-next-line:no-any
  protected put<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<SendBackResult<T>> {
    this.log(`${this.className}.put started for URL ${url} with body: `, body);
    return this.settings$.pipe(
      switchMap(appSettings => this.http.put<SendBackResult<T>>(`${appSettings.api.url}${url}`, body, { headers: this.mergeOptions(appSettings, options) })),
      debug(`${this.className}.put payload`));
  }
  // tslint:disable-next-line:no-any
  protected patch<T>(url: string, body: any, options?: RequestOptionsArgs): Observable<SendBackResult<T>> {
    this.log(`${this.className}.patch started for URL ${url} with body: `, body);
    return this.settings$.pipe(
      switchMap(appSettings => this.http.patch<SendBackResult<T>>(`${appSettings.api.url}${url}`, body, { headers: this.mergeOptions(appSettings, options) })),
      debug(`${this.className}.patch payload`));
  }
  // tslint:disable-next-line:no-any
  protected delete<T>(url: string, options?: RequestOptionsArgs): Observable<any> {
    this.log(`${this.className}.delete started for URL ${url}`);
    return this.settings$.pipe(switchMap(appSettings => this.http.delete(`${appSettings.api.url}${url}`, { headers: this.mergeOptions(appSettings, options) })),
                               debug(`${this.className}.delete response`));
  }
  // tslint:disable-next-line:no-any
  private mergeOptions(appSettings: AppSettings, providedOptions: RequestOptionsArgs, headers?: any): any {

    const httpOptions = { headers: new HttpHeaders({}) };
    httpOptions.headers = httpOptions.headers.append('X-ApiKey', appSettings.api.key);

    if (headers) {
      httpOptions.headers = httpOptions.headers.append(headers['content'], headers['type']);
    }
    if (providedOptions) {
      const resp = providedOptions;
      // TODO: refactoring old code for the new HttpClient
      // let newOptions = new RequestOptions();
      // newOptions = newOptions.merge(new RequestOptions(providedOptions));
      // if (!newOptions.headers) {
      //   newOptions.headers = new Headers();
      // }
    }
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (!this.isAnonymous && tokenNotExpired(undefined, accessToken)) {
      // Add Authorization headers
      httpOptions.headers = httpOptions.headers.append('Authorization', `Bearer ${accessToken}`);
      // Add impersonation header if needed
      const impersonationToken = sessionStorage.getItem(IMPERSONATION_TOKEN);
      if (impersonationToken) {
        httpOptions.headers = httpOptions.headers.append('X-Impersonate', impersonationToken);
      }
    }

    return httpOptions.headers;

    // --------------------------------------

    // let newOptions = new RequestOptions();
    // newOptions = newOptions.merge(new RequestOptions(providedOptions));
    // if (!newOptions.headers) {
    //   newOptions.headers = new Headers();
    // }

    // newOptions.headers.set('X-ApiKey', appSettings.api.key);

    // const accessToken = localStorage.getItem(ACCESS_TOKEN);
    // if (!this.isAnonymous && tokenNotExpired(undefined, accessToken)) {
    //   // Add Authorization headers
    //   newOptions.headers.set('Authorization', `Bearer ${accessToken}`);
    //
    //   // Add impersonation header if needed
    //   const impersonationToken = sessionStorage.getItem(IMPERSONATION_TOKEN);
    //   if (impersonationToken) {
    //     newOptions.headers.set('X-Impersonate', impersonationToken);
    //   }
    // }

    // return newOptions;
  }
}
