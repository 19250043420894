import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {Player} from '../models/player';

export const PlayerDetailsActionType = {
  LOAD: type('[Player] Load'),
  RELOAD: type('[Player] Reload'),
  LOAD_SUCCESS: type('[Player] Load Success'),
  LOAD_FAIL: type('[Player] Load Fail')
};

export class LoadPlayerDetailsAction implements Action {
  readonly type = PlayerDetailsActionType.LOAD;
  /* payload is the player id */
  constructor(public payload?: string) {}
}

export class ReloadPlayerDetailsAction implements Action {
  readonly type = PlayerDetailsActionType.RELOAD;
  constructor() {}
}

export class LoadPlayerDetailsSuccessAction implements Action {
  readonly type = PlayerDetailsActionType.LOAD_SUCCESS;
  constructor(public payload?: Player) {}
}

export class LoadPlayerDetailsFailAction implements Action {
  readonly type = PlayerDetailsActionType.LOAD_FAIL;
  constructor(public payload?: number) {}
}
