import {Action} from '@ngrx/store';

import {ArrayResponse} from '../models/array-response';
import {QuickSearchResult} from '../models/quick-search-result';
import {type} from '../utilities/type';

export const QuickSearchActionType = {
  SEARCH: type('[Quick Search] Search'),
  CHANGE_PAGE_SIZE: type('[Quick Search] Change Page Size'),
  PAGE_TO: type('[Quick Search] Page To'),
  ORDER_BY: type('[Quick Search] Order By'),
  LOAD: type('[Quick Search] Load'),
  LOAD_SUCCESS: type('[Quick Search] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Quick Search] Load Validation Fail'),
  LOAD_FAIL: type('[Quick Search] Load Fail'),
  CLOSE: type('[Quick Search] Close'),
  GOTO_PLAYER: type('[Quick Search] Go to player')
};

export class QuickSearchAction implements Action {
  readonly type = QuickSearchActionType.SEARCH;

  constructor(public payload?: string) {}
}

export class ChangeQuickSearchPageSizeAction implements Action {
  readonly type = QuickSearchActionType.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageQuickSearchToAction implements Action {
  readonly type = QuickSearchActionType.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderQuickSearchByAction implements Action {
  readonly type = QuickSearchActionType.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadQuickSearchAction implements Action {
  readonly type = QuickSearchActionType.LOAD;

  constructor(public payload?: boolean) {}
}

export class LoadQuickSearchSuccessAction implements Action {
  readonly type = QuickSearchActionType.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<QuickSearchResult>) {}
}

export class LoadQuickSearchValidationFailAction implements Action {
  readonly type = QuickSearchActionType.LOAD_VALIDATION_FAIL;

  constructor() {}
}

export class LoadQuickSearchFailAction implements Action {
  readonly type = QuickSearchActionType.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class CloseQuickSearchAction implements Action {
  readonly type = QuickSearchActionType.CLOSE;

  constructor() {}
}

export class GotoPlayerAction implements Action {
  readonly type = QuickSearchActionType.GOTO_PLAYER;

  constructor(public payload?: QuickSearchResult) {}
}
