import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {EventLogsFilterForm} from '../models/event-logs-filter-form';
import {ExtendedEventLog} from '../models/extended-event-log';

export const EventLogsActionTypes = {
  TOGGLE_SEARCH: type('[Event Logs] Toggle Search Form'),
  SEARCH: type('[Event Logs] Search'),
  CHANGE_PAGE_SIZE: type('[Event Logs] Change Page Size'),
  PAGE_TO: type('[Event Logs] Page To'),
  ORDER_BY: type('[Event Logs] Order By'),
  LOAD: type('[Event Logs] Load'),
  LOAD_SUCCESS: type('[Event Logs] Load Success'),
  LOAD_FAIL: type('[Event Logs] Load Fail'),
  CLEAR: type('[Event Logs] Clear Data')
};

export class ToggleSearchEventLogsAction implements Action {
  readonly type = EventLogsActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class SearchEventLogsAction implements Action {
  readonly type = EventLogsActionTypes.SEARCH;
  constructor(public payload?: EventLogsFilterForm) {}
}

export class ChangeEventLogsPageSizeAction implements Action {
  readonly type = EventLogsActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageEventLogsToAction implements Action {
  readonly type = EventLogsActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderEventLogsByAction implements Action {
  readonly type = EventLogsActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadEventLogsAction implements Action {
  readonly type = EventLogsActionTypes.LOAD;
  constructor() {}
}

export class LoadEventLogsSuccessAction implements Action {
  readonly type = EventLogsActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<ExtendedEventLog>) {}
}

export class LoadEventLogsFailAction implements Action {
  readonly type = EventLogsActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

export class ClearEventLogsAction implements Action {
  readonly type = EventLogsActionTypes.CLEAR;
  constructor() {}
}
