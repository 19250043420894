import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {clientConfigurationActionTypes, LoadClientConfigurationFailAction, LoadClientConfigurationSuccessAction} from '../actions/client-configuration';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {Office} from '../models/culture';
import {SendBackResult} from '../models/send-back-result';
import * as fromShared from '../reducers';
import * as fromRoot from '../reducers';
import {ClientConfigurationService} from '../services/client-configuration.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class ClientConfigurationEffect extends BaseEffect {
  private client$ = this.store.pipe(select(fromRoot.selectors.getCurrentClient)).pipe(filter(client => !_.isEmpty(client)));

  @Effect()
  load$ = this.actions$.pipe(ofType(clientConfigurationActionTypes.LOAD),
                             delayWhen(() => this.client$),
                             debug('Load counters action received.'),
                             withLatestFrom(this.client$),
                             // @ts-ignore
                             switchMap(([action, client]) => this.clientConfigurationService.getList(client.uuid)
                                                               .pipe(map((payload: SendBackResult<ArrayResponse<Office>>) =>
                                                                           new LoadClientConfigurationSuccessAction(payload.data.array)),
                                                                     catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(clientConfigurationActionTypes.LOAD_FAIL),
                                 debug('Load clientConfiguration fail action received.'),
                                 map(() => error(this.translation.translate('OFFICES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private translation: L10nTranslationService,
              private clientConfigurationService: ClientConfigurationService,
              router: Router,
              private store: Store<fromShared.AppState>) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadClientConfigurationFailAction(response.status); }
}
