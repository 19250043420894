import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {combineLatest} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {AppSettingsService, SetLanguageAction} from './shared';
import * as fromRoot from './shared/reducers';

@Component({ selector: 'storever-root', template: '<router-outlet></router-outlet>' })
export class AppComponent implements OnInit {
  constructor(private viewContainerRef: ViewContainerRef,
              private router: Router,
              private translation: L10nTranslationService,
              private activatedRoute: ActivatedRoute,
              private title: Title,
              private appSettings: AppSettingsService,
              private store: Store<fromRoot.AppState>) {
    // this.translation.translationChanged.subscribe(lang => this.store.dispatch(new SetLanguageAction(lang)));
    // this.toast.setRootViewContainerRef(this.viewContainerRef);
    // this.locale.addConfiguration().addLanguages(['en', 'fr', 'de' /*, 'nl'*/]).useLocalStorage().defineLanguage('en');
    // this.locale.init();

    // this.translation.addConfiguration().addProvider('./assets/locales/locale-');
    // this.translation.init();

    this.appSettings.init();
  }

  ngOnInit(): void {
    // TODO: check if possible using the router state
    const pageTitle$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd),
                                               map(() => this.activatedRoute),
                                               map(route => {
                                                 while (route.firstChild) {
                                                   route = route.firstChild;
                                                 }
                                                 return route;
                                               }),
                                               filter(route => route.outlet === 'primary'),
                                               switchMap(route => route.data),
                                               map((data: Data) => data['title'] || 'DEFAULT_TITLE'));
    combineLatest(this.store.select(fromRoot.selectors.getLanguage), pageTitle$, (lang, title) => this.translation.translate(title))
      .subscribe(translatedTitle => this.title.setTitle(translatedTitle));
  }
}
