import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {switchMap} from 'rxjs/internal/operators';
import {catchError, map} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadProductionLineFailAction, LoadProductionLineSuccessAction, productionLineActionTypes} from '../actions/production-line';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {ProductionLineService} from '../services/production-line.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class ProductionLineEffect extends BaseEffect {

  load$ = createEffect(() => { return this.actions$.pipe(
                         ofType(productionLineActionTypes.LOAD),
                         debug('Load production line action received;'),
                         switchMap(() => this.productionLineService.getList().pipe(
                                     // tslint:disable-next-line:no-any
                                     map((payload: SendBackResult<ArrayResponse<any>>) => new LoadProductionLineSuccessAction(payload.data.array)),
                                     catchError(res => this.catchResponseError(res)),
                                     )),
                         ) });

  loadFail$ = createEffect(
    () => { return this.actions$.pipe(ofType(productionLineActionTypes.LOAD_FAIL),
                                      debug('Load production line fail action received.'),
                                      map((err: LoadProductionLineFailAction) => error(this.translation.translate('PRODUCTION_LINE_LOAD_ERROR') + err.payload,
                                                                                       this.translation.translate('TOAST_ERROR_TITLE')))) });

  constructor(private actions$: Actions, private translation: L10nTranslationService, private productionLineService: ProductionLineService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadProductionLineFailAction(response['error'].message); }

  protected handleGoneError(response: Response): Action { return new LoadProductionLineFailAction(response['error'].message); }
}
