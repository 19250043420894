import {Action} from '@ngrx/store';

import {ImpersonableUserVM} from '../models/impersonable-user.vm';
import {type} from '../utilities/type';

export const ImpersonableUsersActionTypes = {
  LOAD: type('[Impersonable Users] Load'),
  RELOAD: type('[Impersonable Users] Reload'),
  LOAD_SUCCESS: type('[Impersonable Users] Load Success'),
  LOAD_FAIL: type('[Impersonable Users] Load Fail')
};

export class LoadImpersonableUsersAction implements Action {
  readonly type = ImpersonableUsersActionTypes.LOAD;

  constructor() {}
}

export class ReloadImpersonableUsersAction implements Action {
  readonly type = ImpersonableUsersActionTypes.RELOAD;

  constructor() {}
}

export class LoadImpersonableUsersSuccessAction implements Action {
  readonly type = ImpersonableUsersActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ImpersonableUserVM[]) {}
}

export class LoadImpersonableUsersFailAction implements Action {
  readonly type = ImpersonableUsersActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
