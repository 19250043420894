import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {VideoMedia} from '../models/video-media';
import {VideoPlaylistsFilterForm} from '../models/video-playlists-filter-form';

export const VideoPlaylistsActionTypes = {
  TOGGLE_SEARCH: type('[Video Playlist] Toggle Search Form'),
  SEARCH: type('[Video Playlist] Search'),
  CHANGE_PAGE_SIZE: type('[Video Playlist] Change Page Size'),
  PAGE_TO: type('[Video Playlist] Page To'),
  ORDER_BY: type('[Video Playlist] Order By'),
  LOAD: type('[Video Playlist] Load'),
  LOAD_SUCCESS: type('[Video Playlist] Load Success'),
  LOAD_FAIL: type('[Video Playlist] Load Fail'),
  CLEAR: type('[Video Playlist] Clear Data')
};

export class ToggleSearchVideoPlaylistsAction implements Action {
  readonly type = VideoPlaylistsActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchVideoPlaylistsAction implements Action {
  readonly type = VideoPlaylistsActionTypes.SEARCH;

  constructor(public payload?: VideoPlaylistsFilterForm) {}
}

export class ChangeVideoPlaylistsPageSizeAction implements Action {
  readonly type = VideoPlaylistsActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageVideoPlaylistsToAction implements Action {
  readonly type = VideoPlaylistsActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderVideoPlaylistsByAction implements Action {
  readonly type = VideoPlaylistsActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadVideoPlaylistsAction implements Action {
  readonly type = VideoPlaylistsActionTypes.LOAD;

  constructor(public payload?: boolean) {}
}

export class LoadVideoPlaylistsSuccessAction implements Action {
  readonly type = VideoPlaylistsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<VideoMedia>) {}
}

export class LoadVideoPlaylistsFailAction implements Action {
  readonly type = VideoPlaylistsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ClearVideoPlaylistsAction implements Action {
  readonly type = VideoPlaylistsActionTypes.CLEAR;

  constructor() {}
}
