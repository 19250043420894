import {Component, Inject, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import {Subscription} from 'rxjs';

import {debug} from '../../../../rxjs-operators';
import {SendBackResultCode} from '../../../shared';
import {LostPasswordForm} from '../../models/lost-password-form';
import {AnonymousUserService} from '../../services/anonymous-user.service';

@Component({ selector: 'storever-lost-password', templateUrl: './lost-password.component.html', styleUrls: ['./lost-password.component.scss'] })
export class LostPasswordComponent implements OnDestroy {
  lostPasswordForm: FormGroup;
  submitting = false;
  resultCode: SendBackResultCode;
  private sub: Subscription;
  email: string;

  constructor(ars: ActivatedRoute, private userService: AnonymousUserService, fb: FormBuilder, @Inject(L10N_LOCALE) public locale: L10nLocale) {
    if (ars.snapshot.params['email']) {
      this.email = ars.snapshot.params['email'];
    }
    this.lostPasswordForm = fb.group({ email: [this.email ? this.email : undefined, Validators.compose([Validators.required, Validators.email])] });
  }

  ngOnDestroy(): void {
    // super.cancelParamSubscriptions();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  submitForm(form: LostPasswordForm): void {
    this.submitting = true;
    this.sub = this.userService.lostPassword(form.email)
                 .pipe(debug('Lost password result'))
                 .subscribe(
                   res => {
                     this.submitting = false;
                     this.resultCode = res.statusCode;
                   },
                   () => {
                     this.submitting = false;
                     this.resultCode = SendBackResultCode.Error;
                   });
  }

  tryAgain($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.submitting = false;
    this.resultCode = undefined;
  }
}
