import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes, UrlSegment, UrlSegmentGroup} from '@angular/router';

import {environment} from '../environments/environment';

import {CluffComponent} from './auth/pages/cluff/cluff.component';
import {RedirectComponent} from './redirect.component';
import {AppLayoutComponent, MustBeAuthenticatedGuard} from './shared';

export function clientId(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route) {
  const parts = [/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i];
  const posParams: { [key: string]: UrlSegment } = {};
  const consumed: UrlSegment[] = [];

  let currentIndex = 0;
  for (let i = 0; i < parts.length; ++i) {
    if (currentIndex >= segments.length) {
      return null;
    }
    const current = segments[currentIndex];

    const part = parts[i];
    if (!part.test(current.path)) {
      return null;
    }

    posParams['clientId'] = current;
    consumed.push(current);
    currentIndex++;
  }

  if (route.pathMatch === 'full' && (segmentGroup.hasChildren() || currentIndex < segments.length)) {
    return null;
  }

  return { consumed, posParams };
}

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        children: [
          { path: 'my-profile', loadChildren: () => import('app/my-profile/my-profile.module').then(m => m.MyProfileModule) },
          { path: 'admin', loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule) },
          { path: 'daryl', loadChildren: () => import('app/daryl/daryl.module').then(m => m.DarylModule) },
          { path: 'dashboard', component: RedirectComponent, data: { redirectTo: 'dashboard' } },
          { path: 'players', component: RedirectComponent, data: { redirectTo: 'players' } },
          { path: 'users', component: RedirectComponent, data: { redirectTo: 'users' } },
          { path: 'tickets', component: RedirectComponent, data: { redirectTo: 'tickets' } },
          { path: 'music-preview', component: RedirectComponent, data: { redirectTo: 'music-preview' } },

        ]
      },
      {
        matcher: clientId,
        children: [
          { path: 'dashboard', loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule) },
          { path: 'players', loadChildren: () => import('app/players/players.module').then(m => m.PlayersModule) },
          { path: 'users', loadChildren: () => import('app/users/users.module').then(m => m.UsersModule) },
          { path: 'tickets', loadChildren: () => import('app/tickets/tickets.module').then(m => m.TicketsModule) },
          { path: 'music-preview', loadChildren: () => import('app/music-preview/music-preview.module').then(m => m.MusicPreviewModule) },

        ]
      }
    ],
    canActivate: [MustBeAuthenticatedGuard]
  }
];

@NgModule({ imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })], exports: [RouterModule], providers: [] })
// @NgModule({ imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production })], exports: [RouterModule], providers: [] })
export class AppRoutingModule {
}
