import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  AppSettingsService,
  ArrayResponse,
  BaseRestService,
  Product,
  SendBackResult,
  serializeQueryString,
  SimpleValue,
} from '../../shared';
import {CreateUser} from '../../users/models/create-user';
import {PlayerIssue} from '../models/player-issue';
import {PlayersIssueFilter} from '../models/players-issue-filter';

@Injectable({ providedIn: 'root' })
export class PlayersIssueService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'PlayersService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(filter: PlayersIssueFilter): Observable<SendBackResult<ArrayResponse<PlayerIssue>>> {
    return this.get<ArrayResponse<PlayerIssue>>(`/PlayerIssues${serializeQueryString(filter)}`);
  }
  getListIssueTypes(clientId: string): Observable<SendBackResult<ArrayResponse<Product>>> {
    return this.get<ArrayResponse<Product>>(`/PlayerIssues/issue-types`);
  }

  create(postpone: any): Observable<SendBackResult<SimpleValue<string>>> {
    return this.post<SimpleValue<string>>(`/PlayerIssues/${postpone.playerIssueId}/Postpone`, postpone);
  }
}
