import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
// tslint:disable-next-line:import-blacklist
import {Observable, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {debug} from '../../../rxjs-operators';
import {AppSettings} from '../models/app-settings';

import {BaseService} from './base.service';

@Injectable({ providedIn: 'root' })
export class AppSettingsService extends BaseService {
  readonly settings$: Observable<AppSettings>;
  private readonly settingsSource = new ReplaySubject<AppSettings>(1);
  private settingsSnapshot: AppSettings;
  currentApplicationVersion: string;

  constructor(private http: HttpClient) {
    super();
    this.settings$ = this.settingsSource.asObservable().pipe(tap(settings => (this.settingsSnapshot = settings)));
    this.currentApplicationVersion = environment.appVersion;
  }

  init(): void {
    const brand = location.host.replace(':', '');
    this.load(brand);
  }

  get<TResult>(path: string|string[], defaultValue?: TResult): TResult {
    if (_.isEmpty(this.settingsSnapshot)) {
      throw new Error('App settings is not available now.');
    }

    return _.get(this.settingsSnapshot, path, defaultValue);
  }

  private load(brand: string): void {
    this.log('Loading settings for domain.', brand);
    this.http.get<AppSettings>(`/assets/settings/${brand}.json?version=${this.currentApplicationVersion}`)
      .pipe(debug('AppSettings'))
      .subscribe(settings => this.settingsSource.next(settings), err => this.settingsSource.error(err));
  }
}
