import {Action} from '@ngrx/store';

import {ArrayResponse, ConnectivityInfo, type} from '../index';

export const CreateTicketSFActionTypes = {
  CREATE: type('[Ticket SF] create'),
  CREATE_SUCCESS: type('[Ticket SF] create Success'),
  CREATE_FAIL: type('[Ticket SF] create Fail'),
  CREATE_MULTI: type('[Ticket SF] create Multi'),
  CREATE_SUCCESS_MULTI: type('[Ticket SF] create Success Multi'),
  CREATE_FAIL_MULTI: type('[Ticket SF] create Fail Multi')
};

export class LoadCreateTicketSFAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE;

  constructor(public payload?: any) {}
}

export class LoadCreateTicketSFSuccessAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE_SUCCESS;

  constructor(public payload?: any) {}
}

export class LoadCreateTicketSFErrorAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE_FAIL;

  constructor(public payload?: number) {}
}

export class LoadCreateMultiTicketSFAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE_MULTI;

  constructor(public payload?: any) {}
}

export class LoadCreateMultiTicketSFSuccessAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE_SUCCESS_MULTI;

  constructor(public payload?: any) {}
}

export class LoadCreateMultiTicketSFErrorAction implements Action {
  readonly type = CreateTicketSFActionTypes.CREATE_FAIL_MULTI;

  constructor(public payload?: number) {}
}
