import {CanDeactivate} from '@angular/router';

export interface EditableComponent {
  hasPendingChanges(): boolean;
  getConfirmMessage(): string;
}

export abstract class BaseCanDeactivateGuard<T extends EditableComponent> implements CanDeactivate<T> {
  canDeactivate(target: T): boolean {
    if (target.hasPendingChanges()) {
      return window.confirm(target.getConfirmMessage());
    }

    return true;
  }
}
