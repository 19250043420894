import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {ConnectivityInfo, UserContextActionTypes} from '../../shared';
import {ConnectivityStatsActionTypes, LoadConnectivityStatsSuccessAction} from '../actions/connectivity-stats';

export interface ConnectivityStatsState {
  array: ConnectivityInfo[];
  days: number;
}

const initialState: ConnectivityStatsState = {
  array: [],
  days: 0
};

export function connectivityStatsReducer(state = initialState, action: Action): ConnectivityStatsState {
  switch (action.type) {
    case ConnectivityStatsActionTypes.LOAD:
      return handleLoadAction();
    case ConnectivityStatsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ConnectivityStatsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): ConnectivityStatsState {
  return initialState;
}

function handleLoadSuccessAction(state: ConnectivityStatsState, action: LoadConnectivityStatsSuccessAction): ConnectivityStatsState {
  const newState = _.clone(state);
  newState.array = action.payload.array;
  newState.days = action.payload.count;
  return newState;
}

function handleLoadFailAction(state: ConnectivityStatsState): ConnectivityStatsState {
  const newState = _.clone(state);
  newState.array = [];
  newState.days = 0;
  return newState;
}

function handleChangeClientAction(): ConnectivityStatsState {
  return { array: [], days: 0 };
}

export const connectivityStatsSelectors = {
  array: (state: ConnectivityStatsState) => _.get(state, 'array', []),
  days: (state: ConnectivityStatsState) => _.get(state, 'days', 0)
};
