<form class="change-avatar-form" novalidate>
  <div class="form-group">
    <div class="fileinput fileinput-new" data-provides="fileinput">
      <div class="fileinput-new thumbnail" style="width: 200px; height: 150px;">
        <img [src]="src" [alt]="'CHANGE_AVATAR_YOURS' | translate:locale.language" />
      </div>
      <div class="fileinput-preview fileinput-exists thumbnail" style="max-width: 200px; max-height: 150px;"> </div>
      <div>
            <span class="btn default btn-file">
              <span class="fileinput-new" [style.display]="isDefaultImage ? 'inline-block' : 'none'" translate>CHANGE_AVATAR_SELECT</span>
              <span class="fileinput-exists" [style.display]="isDefaultImage ? 'none' : 'inline-block'" translate>CHANGE_AVATAR_CHANGE</span>
              <input type="file" imageUpload
                     (imageSelected)="imageSelected($event)"
                     [imageToDataUrl]="resizeOptions">
            </span>
        <button type="button" class="btn default fileinput-exists"
                [style.display]="isDefaultImage ? 'none' : 'inline-block'"
                (click)="removeImage()" translate>CHANGE_AVATAR_REMOVE</button>
      </div>
    </div>
  </div>
  <div class="margin-top-10">
    <button type="button" class="btn brand"
            [disabled]="isDefaultImage || submitting"
            (click)="submitForm()">
      <span *ngIf="!submitting" translate>CHANGE_AVATAR_SAVE</span>
      <span *ngIf="submitting" translate>CHANGE_AVATAR_SAVING</span>
    </button>
    <button type="button" class="btn default" [disabled]="submitting" (click)="removeImage()" translate>CANCEL</button>
  </div>
</form>
