import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as _ from 'lodash';
import {delayWhen, filter, map, take, withLatestFrom} from 'rxjs/operators';

import {CUSTOM_CLAIMS_DEFAULT_CLIENT_ID} from './shared';
import * as fromRoot from './shared/reducers';

@Component({ selector: 'storever-redirect', template: `Redirecting...`, styles: [] })
export class RedirectComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private store: Store<fromRoot.AppState>) {}

  ngOnInit(): void {
    const userInfo$ = this.store.pipe(select(fromRoot.selectors.getUserInfo), filter(userInfo => !_.isEmpty(userInfo)));
    this.activatedRoute.data.pipe(map(data => data.redirectTo), delayWhen(() => userInfo$), withLatestFrom(userInfo$), take(1))
      .subscribe(([redirectTo, userInfo]) => {
        const clientId = userInfo[CUSTOM_CLAIMS_DEFAULT_CLIENT_ID];
        this.router.navigate(['/', clientId, redirectTo]);
      });
  }
}
