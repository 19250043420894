import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {ExternalLinksActionTypes, LoadExternalLinksSuccessAction} from '../actions/external-links';
import {UserContextActionTypes} from '../actions/user-context';
import {ExternalLink} from '../models/external-link';

export interface ExternalLinksState {
  array: ExternalLink[];
}

const initialState: ExternalLinksState = {
  array: []
};

export function externalLinksReducer(state = initialState, action: Action): ExternalLinksState {
  switch (action.type) {
    case ExternalLinksActionTypes.LOAD:
      return handleLoadAction();
    case ExternalLinksActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ExternalLinksActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): ExternalLinksState {
  return initialState;
}

function handleLoadSuccessAction(state: ExternalLinksState, action: LoadExternalLinksSuccessAction): ExternalLinksState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: ExternalLinksState): ExternalLinksState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): ExternalLinksState {
  return { array: [] };
}

export const externalLinksSelectors = {
  array: (state: ExternalLinksState) => _.get(state, 'array', [])
};
