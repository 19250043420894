import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {L10nTranslationModule} from 'angular-l10n';

import {SharedModule} from '../shared/shared.module';

import {ErrorRoutingModule} from './error-routing.module';
import {AccessDeniedComponent} from './pages/access-denied/access-denied.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

@NgModule({ imports: [CommonModule, ErrorRoutingModule, SharedModule, L10nTranslationModule], declarations: [AccessDeniedComponent, NotFoundComponent] })
export class ErrorModule {
}
