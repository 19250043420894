import {Action} from '@ngrx/store';

import {ArrayResponse, ConnectivityInfo, type} from '../../shared';

export const ConnectivityStatsActionTypes = {
  LOAD: type('[Player] Load Connectivity Stats'),
  LOAD_SUCCESS: type('[Player] Load Connectivity Stats Success'),
  LOAD_FAIL: type('[Player] Load Connectivity Stats Fail')
};

export class LoadConnectivityStatsAction implements Action {
  readonly type = ConnectivityStatsActionTypes.LOAD;

  constructor() { console.log('LoadConnectivityStatsAction'); }
}

export class LoadConnectivityStatsSuccessAction implements Action {
  readonly type = ConnectivityStatsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<ConnectivityInfo>) {}
}

export class LoadConnectivityStatsServerErrorAction implements Action {
  readonly type = ConnectivityStatsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
