import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {GeocodeAddressActionTypes, GeocodeAddressSuccessAction} from '../actions/geocode-address';
import {LoadPlayerDetailsSuccessAction, PlayerDetailsActionType} from '../actions/player-details';
import {DownloadRestrictions} from '../models/download-restrictions';
import {GeocodedAddress} from '../models/geocoded-address';
import {Player} from '../models/player';

export interface PlayerDetailsState {
  value: Player;
  geocodedAddress: GeocodedAddress;
}

const initialState: PlayerDetailsState = {
  value: undefined,
  geocodedAddress: undefined
};

export function playerDetailsReducer(state = initialState, action: Action): PlayerDetailsState {
  switch (action.type) {
    case PlayerDetailsActionType.LOAD:
      return handleLoadAction();
    case PlayerDetailsActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case PlayerDetailsActionType.LOAD_FAIL:
      return handleLoadFailAction(state);
    case GeocodeAddressActionTypes.GEOCODE_SUCCESS:
      return handleGeocodeSuccessAction(state, action);
    case GeocodeAddressActionTypes.GEOCODE_HTTP_ERROR:
    case GeocodeAddressActionTypes.GEOCODE_GENERIC_ERROR:
    case GeocodeAddressActionTypes.GEOCODE_OVER_QUERY_LIMIT:
    case GeocodeAddressActionTypes.GEOCODE_ZERO_RESULTS:
      return handleGeocodeErrorActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): PlayerDetailsState {
  return initialState;
}

function handleLoadSuccessAction(state: PlayerDetailsState, action: LoadPlayerDetailsSuccessAction): PlayerDetailsState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleLoadFailAction(state: PlayerDetailsState): PlayerDetailsState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}

function handleGeocodeSuccessAction(state: PlayerDetailsState, action: GeocodeAddressSuccessAction): PlayerDetailsState {
  const newState: PlayerDetailsState = { value: state.value, geocodedAddress: action.payload };
  return newState;
}

function handleGeocodeErrorActions(state: PlayerDetailsState): PlayerDetailsState {
  const newState: PlayerDetailsState = { value: state.value, geocodedAddress: undefined };
  return newState;
}

function handleChangeClientAction(): PlayerDetailsState {
  return { value: undefined, geocodedAddress: undefined };
}

export const playerDetailsSelectors = {
  value: (state: PlayerDetailsState) => _.get(state, 'value'),
  // downloadRestrictions: (state: PlayerDetailsState) => _.get(state.value, 'downloadRestrictions'),
  geocodedAddress: (state: PlayerDetailsState) => _.get(state, 'geocodedAddress')
};
