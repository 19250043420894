import {Action} from '@ngrx/store';
import * as auth0 from 'auth0-js';

import {Auth0UserInfo} from '../models/auth0-user-info';
import {type} from '../utilities/type';

export const UserInfoActionTypes = {
  LOAD: type('[User Info] Load'), // Only for loading profile at application start !!!
  LOAD_SUCCESS: type('[User Info] Load Success'),
  LOAD_FAIL: type('[User Info] Load Fail'),
  LOGOUT: type('[User Info] Logout'),
  RELOAD: type('[User Info] Reload')
};

export class LoadUserInfoAction implements Action {
  readonly type = UserInfoActionTypes.LOAD;

  constructor() {}
}

export class LoadUserInfoSuccessAction implements Action {
  readonly type = UserInfoActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Auth0UserInfo) {}
}

export class LoadUserInfoFailAction implements Action {
  readonly type = UserInfoActionTypes.LOAD_FAIL;

  constructor(public payload?: auth0.Auth0Error) {}
}

export class LogoutAction implements Action {
  readonly type = UserInfoActionTypes.LOGOUT;

  /* payload is returnTo url */
  constructor(public payload?: string) {}
}

export class ReloadUserInfoAction implements Action {
  readonly type = UserInfoActionTypes.RELOAD;

  constructor() {}
}
