import {ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import * as _ from 'lodash';

import * as fromRoot from '../../shared/reducers';

import {audioPlanningsReducer, audioPlanningsSelectors, AudioPlanningsState} from './audio-plannings';
import {audioPlaylistsReducer, audioPlaylistsSelectors, AudioPlaylistsState} from './audio-playlists';
import {audioProgramsReducer, audioProgramsSelectors, AudioProgramsState} from './audio-programs';
import {audioScheduledMessagesReducer, audioScheduledMessagesSelectors, AudioScheduledMessagesState} from './audio-scheduled-messages';
import {commandsReducer, commandsSelectors, CommandsState} from './commands';
import {connectivityStatsReducer, connectivityStatsSelectors, ConnectivityStatsState} from './connectivity-stats';
import {countriesReducer, countriesSelectors, CountriesState} from './countries';
import {eventLogsReducer, eventLogsSelectors, EventLogsState} from './event-logs';
import {inventoryReducer, inventorySelectors, InventoryState} from './inventory';
import {playLogsReducer, playLogsSelectors, PlayLogsState} from './play-logs';
import {playerDetailsReducer, playerDetailsSelectors, PlayerDetailsState} from './player-details';
import {playersListReducer, playersListSelectors, PlayersListState} from './players-list';
import {PlayerReportOfflineState, playersReportOfflineReducer, playersReportOfflineSelectors} from './players-report-offline';
import {productsReducer, productsSelectors, ProductsState} from './products';
import {screenShotReducer, screenShotSelectors, ScreenShotState} from './screen-shot';
import {shippingInfoReducer, shippingInfoSelectors, ShippingInfoState} from './shipping-info';
import {videoPlaylistsReducer, videoPlaylistsSelectors, VideoPlaylistsState} from './video-playlists';

export interface PlayersState {
  countries: CountriesState;
  products: ProductsState;
  players: PlayersListState;
  playersReportOffline: PlayerReportOfflineState;
  player: PlayerDetailsState;
  shipping: ShippingInfoState;
  connectivity: ConnectivityStatsState;
  audioPrograms: AudioProgramsState;
  audioPlannings: AudioPlanningsState;
  audioPlaylists: AudioPlaylistsState;
  playLogs: PlayLogsState;
  eventLogs: EventLogsState;
  audioScheduledMessages: AudioScheduledMessagesState;
  videoPlaylists: VideoPlaylistsState;
  commands: CommandsState;
  inventory: InventoryState;
  screenShot: ScreenShotState;
}

export interface AppState extends fromRoot.AppState {
  players: PlayersState;
}

export const reducers: ActionReducerMap<PlayersState> = {
  countries: countriesReducer,
  products: productsReducer,
  players: playersListReducer,
  playersReportOffline: playersReportOfflineReducer,
  player: playerDetailsReducer,
  shipping: shippingInfoReducer,
  connectivity: connectivityStatsReducer,
  audioPrograms: audioProgramsReducer,
  audioPlannings: audioPlanningsReducer,
  audioPlaylists: audioPlaylistsReducer,
  playLogs: playLogsReducer,
  eventLogs: eventLogsReducer,
  audioScheduledMessages: audioScheduledMessagesReducer,
  videoPlaylists: videoPlaylistsReducer,
  commands: commandsReducer,
  inventory: inventoryReducer,
  screenShot: screenShotReducer
};

const getPlayersState = createFeatureSelector<PlayersState>('players');
const rootSelectors = {
  countries: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'countries')),
  products: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'products')),
  players: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'players')),
  playersReportOffline: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'playersReportOffline')),
  player: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'player')),
  shipping: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'shipping')),
  connectivity: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'connectivity')),
  audioPrograms: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'audioPrograms')),
  audioPlannings: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'audioPlannings')),
  audioPlaylists: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'audioPlaylists')),
  playLogs: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'playLogs')),
  eventLogs: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'eventLogs')),
  audioScheduledMessages: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'audioScheduledMessages')),
  videoPlaylists: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'videoPlaylists')),
  commands: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'commands')),
  inventory: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'inventory')),
  screenShot: createSelector(getPlayersState, (state: PlayersState) => _.get(state, 'screenShot'))
};

export interface Selectors {
  getCountries: MemoizedSelector<AppState, any>;
  getProducts: MemoizedSelector<AppState, any>;
  getShowFilter: MemoizedSelector<AppState, any>;
  getPlayers: MemoizedSelector<AppState, any>;
  getPlayersReportOfflineClient: MemoizedSelector<AppState, any>;
  getPlayersReportOfflineItems: MemoizedSelector<AppState, any>;
  getPlayersCount: MemoizedSelector<AppState, any>;
  getDownloading: MemoizedSelector<AppState, any>;
  getPlayerDetails: MemoizedSelector<AppState, any>;
  // getPlayerDownloadRestrictions: MemoizedSelector<AppState, DownloadRestrictions[]>;
  getGeocodedAddress: MemoizedSelector<AppState, any>;
  getShippingInfo: MemoizedSelector<AppState, any>;
  getConnectivityData: MemoizedSelector<AppState, any>;
  getConnectivityDays: MemoizedSelector<AppState, any>;
  getAudioPrograms: MemoizedSelector<AppState, any>;
  getAudioPlanningsShowFilter: MemoizedSelector<AppState, any>;
  getAudioPlannings: MemoizedSelector<AppState, any>;
  getAudioPlanningsCount: MemoizedSelector<AppState, any>;
  getAudioPlanningsPlaying: MemoizedSelector<AppState, any>;
  getAudioPlaylistsShowFilter: MemoizedSelector<AppState, any>;
  getAudioPlaylists: MemoizedSelector<AppState, any>;
  getAudioPlaylistsCount: MemoizedSelector<AppState, any>;
  getAudioPlaylistsStats: MemoizedSelector<AppState, any>;
  getAudioPlaylistsPlaying: MemoizedSelector<AppState, any>;
  getPlayLogsShowFilter: MemoizedSelector<AppState, any>;
  getPlayLogs: MemoizedSelector<AppState, any>;
  getPlayLogsCount: MemoizedSelector<AppState, any>;
  getPlayLogsPlaying: MemoizedSelector<AppState, any>;
  getEventLogsShowFilter: MemoizedSelector<AppState, any>;
  getEventLogs: MemoizedSelector<AppState, any>;
  getEventLogsCount: MemoizedSelector<AppState, any>;
  getEventLogsMetadataColumns: MemoizedSelector<AppState, any>;
  getAudioScheduledMessagesShowFilter: MemoizedSelector<AppState, any>;
  getAudioScheduledMessages: MemoizedSelector<AppState, any>;
  getAudioScheduledMessagesCount: MemoizedSelector<AppState, any>;
  getAudioScheduledMessagesPlaying: MemoizedSelector<AppState, any>;
  getVideoPlaylistsShowFilter: MemoizedSelector<AppState, any>;
  getVideoPlaylists: MemoizedSelector<AppState, any>;
  getVideoPlaylistsCount: MemoizedSelector<AppState, any>;
  getCommandsShowFilter: MemoizedSelector<AppState, any>;
  getCommands: MemoizedSelector<AppState, any>;
  getCommandsCount: MemoizedSelector<AppState, any>;
  getInventoryShowFilter: MemoizedSelector<AppState, any>;
  getInventory: MemoizedSelector<AppState, any>;
  getInventoryCount: MemoizedSelector<AppState, any>;
  getScreenShot: MemoizedSelector<AppState, any[]>;
}

export const selectors: Selectors = {
  getCountries: createSelector(rootSelectors.countries, countriesSelectors.array),
  getProducts: createSelector(rootSelectors.products, productsSelectors.array),
  getShowFilter: createSelector(rootSelectors.players, playersListSelectors.showFilter),
  getPlayers: createSelector(rootSelectors.players, playersListSelectors.array),
  getPlayersReportOfflineClient: createSelector(rootSelectors.playersReportOffline, playersReportOfflineSelectors.client),
  getPlayersReportOfflineItems: createSelector(rootSelectors.playersReportOffline, playersReportOfflineSelectors.items),
  getPlayersCount: createSelector(rootSelectors.players, playersListSelectors.count),
  getDownloading: createSelector(rootSelectors.players, playersListSelectors.downloading),
  getPlayerDetails: createSelector(rootSelectors.player, playerDetailsSelectors.value),
  // getPlayerDownloadRestrictions : createSelector(rootSelectors.player, playerDetailsSelectors.downloadRestrictions),
  getGeocodedAddress: createSelector(rootSelectors.player, playerDetailsSelectors.geocodedAddress),
  getShippingInfo: createSelector(rootSelectors.shipping, shippingInfoSelectors.array),
  getConnectivityData: createSelector(rootSelectors.connectivity, connectivityStatsSelectors.array),
  getConnectivityDays: createSelector(rootSelectors.connectivity, connectivityStatsSelectors.days),
  getAudioPrograms: createSelector(rootSelectors.audioPrograms, audioProgramsSelectors.array),
  getAudioPlanningsShowFilter: createSelector(rootSelectors.audioPlannings, audioPlanningsSelectors.showFilter),
  getAudioPlannings: createSelector(rootSelectors.audioPlannings, audioPlanningsSelectors.array),
  getAudioPlanningsCount: createSelector(rootSelectors.audioPlannings, audioPlanningsSelectors.count),
  getAudioPlanningsPlaying: createSelector(rootSelectors.audioPlannings, audioPlanningsSelectors.playing),
  getAudioPlaylistsShowFilter: createSelector(rootSelectors.audioPlaylists, audioPlaylistsSelectors.showFilter),
  getAudioPlaylists: createSelector(rootSelectors.audioPlaylists, audioPlaylistsSelectors.array),
  getAudioPlaylistsCount: createSelector(rootSelectors.audioPlaylists, audioPlaylistsSelectors.count),
  getAudioPlaylistsStats: createSelector(rootSelectors.audioPlaylists, audioPlaylistsSelectors.stats),
  getAudioPlaylistsPlaying: createSelector(rootSelectors.audioPlaylists, audioPlaylistsSelectors.playing),
  getPlayLogsShowFilter: createSelector(rootSelectors.playLogs, playLogsSelectors.showFilter),
  getPlayLogs: createSelector(rootSelectors.playLogs, playLogsSelectors.array),
  getPlayLogsCount: createSelector(rootSelectors.playLogs, playLogsSelectors.count),
  getPlayLogsPlaying: createSelector(rootSelectors.playLogs, playLogsSelectors.playing),
  getEventLogsShowFilter: createSelector(rootSelectors.eventLogs, eventLogsSelectors.showFilter),
  getEventLogs: createSelector(rootSelectors.eventLogs, eventLogsSelectors.array),
  getEventLogsCount: createSelector(rootSelectors.eventLogs, eventLogsSelectors.count),
  getEventLogsMetadataColumns: createSelector(rootSelectors.eventLogs, eventLogsSelectors.metadataColumns),
  getAudioScheduledMessagesShowFilter: createSelector(rootSelectors.audioScheduledMessages, audioScheduledMessagesSelectors.showFilter),
  getAudioScheduledMessages: createSelector(rootSelectors.audioScheduledMessages, audioScheduledMessagesSelectors.array),
  getAudioScheduledMessagesCount: createSelector(rootSelectors.audioScheduledMessages, audioScheduledMessagesSelectors.count),
  getAudioScheduledMessagesPlaying: createSelector(rootSelectors.audioScheduledMessages, audioScheduledMessagesSelectors.playing),
  getVideoPlaylistsShowFilter: createSelector(rootSelectors.videoPlaylists, videoPlaylistsSelectors.showFilter),
  getVideoPlaylists: createSelector(rootSelectors.videoPlaylists, videoPlaylistsSelectors.array),
  getVideoPlaylistsCount: createSelector(rootSelectors.videoPlaylists, videoPlaylistsSelectors.count),
  getCommandsShowFilter: createSelector(rootSelectors.commands, commandsSelectors.showFilter),
  getCommands: createSelector(rootSelectors.commands, commandsSelectors.array),
  getCommandsCount: createSelector(rootSelectors.commands, commandsSelectors.count),
  getInventoryShowFilter: createSelector(rootSelectors.inventory, inventorySelectors.showFilter),
  getInventory: createSelector(rootSelectors.inventory, inventorySelectors.array),
  getInventoryCount: createSelector(rootSelectors.inventory, inventorySelectors.count),
  getScreenShot: createSelector(rootSelectors.screenShot, screenShotSelectors.array)
};
