import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import * as m from 'moment';

// clang-format off
type Granularity = 'year' | 'years' | 'y' | 'month' | 'months' | 'M' | 'week' | 'weeks' | 'w' | 'day' | 'days' | 'd' | 'hour' | 'hours' | 'h' | 'minute' |
  'minutes' | 'm' | 'second' | 'seconds' | 's' | 'millisecond' | 'milliseconds' | 'ms' | 'quarter' | 'quarters' | 'Q' | 'isoWeek' | 'isoWeeks' | 'W' | 'date' |
  'dates' | 'D';

type Inclusivity = '()' | '[)' | '(]' | '[]';
// clang-format on

export const isBetween = (format: string, from: m.MomentInput, to: m.MomentInput, granularity?: Granularity, inclusivity?: Inclusivity): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (Validators.required(control) != undefined) {
      return undefined;
    }

    const v = m(control.value, format, true);
    return v.isBetween(from, to, granularity, inclusivity) ? undefined : { isBetween: true };
  };
};
