import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {L10nTranslationService} from 'angular-l10n';

@Component({ selector: 'storever-consent-form', templateUrl: './consent-form.component.html', styleUrls: ['./consent-form.component.scss'] })
export class ConsentFormComponent implements OnInit {
  concentForm: any;
  param: any;
  lang: string;
  redirection: string;
  state: string;
  constructor(private translation: L10nTranslationService, private _router: Router) {}

  ngOnInit(): void {
    this.param = window.location.search;
    this.param = this.param.split('&');
    let j;
    for (j = 0; j < this.param.length; j++) {
      if (this.param[j].toLowerCase().indexOf('lang') !== -1) {
        this.lang = this.deCode(j);
      }
      if (this.param[j].toLowerCase().indexOf('state') !== -1) {
        this.state = this.deCode(j);
      }
      // tslint:disable-next-line:no-any
      // console.log(this.lang, this.param);
    }
    if (!this.state) {
      this._router.navigateByUrl('/login');
    }
    this.setCurrentLanguage(this.lang);
    this.redirection = 'https://watchdog.eu.auth0.com/continue?state=' + this.state;
  }
  // tslint:disable-next-line:no-any
  deCode(q: any): any { return unescape(this.param[q].split('=')[1].replace(/\s/g, '')); }
  setCurrentLanguage(lang: string): void {
    // this.locale.setCurrentLanguage(lang);
    switch (lang) {
      case 'fr':
        this.concentForm = {
          'text1': 'Nous avons mis à jour notre',
          'text2': 'et nos',
          'text3': 'afin de supporter les changements apportés à la loi sur la protection des données.',
          'text4': 'Veuillez confirmer avoir pris connaissance de cette mise à jour.',
          'privacy_policy': 'Politique de confidentialité ',
          'terms_of_use': 'Conditions d’utilisation ',
          'button': 'Confirmer',
        };
        break;
      case 'de':
        this.concentForm = {
          'text1': 'Wir haben unsere',
          'text2': 'und',
          'text3': 'aktualisiert und sind bereit, die Anforderungen der DSGVO zu erfüllen.',
          'text4': 'Bitte bestätigen Sie, dass Sie dieses Update zur Kenntnis genommen haben.',
          'privacy_policy': 'Datenschutzerklärung ',
          'terms_of_use': 'Nutzungsbedingungen',
          'button': 'Bestätigt',
        };
        break;
      case 'nl':
        this.concentForm = {
          'text1': 'We hebben ons',
          'text2': 'en de',
          'text3': 'bijgewerkt en zijn klaar om aan de vereisten van de AVG te voldoen.',
          'text4': 'Bevestig dat u deze update hebt gelezen.',
          'privacy_policy': 'Privacybeleid',
          'terms_of_use': 'Gebruiksvoorwaarden',
          'button': 'Bevestig',
        };
        break;
      case 'es':
        this.concentForm = {
          'text1': 'Hemos actualizado nuestra ',
          'text2': 'y',
          'text3': 'y estamos listos para cumplir con los requisitos del reglamento RGPD.',
          'text4': 'Por favor, confirme que ha leído esta actualización.',
          'privacy_policy': 'Política de privacidad',
          'terms_of_use': 'Condiciones de utilización',
          'button': 'Confirmar',
        };
        break;
      default:
        this.concentForm = {
          'text1': 'We have updated our',
          'text2': 'and',
          'text3': 'to be ready to meet the requirements of the GDPR.',
          'text4': 'Please confirm that you have read this update.',
          'privacy_policy': 'Privacy policy',
          'terms_of_use': 'Terms of use',
          'button': 'Confirm',
        }
    }
  }
  redirectionForm(): void {
    // this.locale.setCurrentLanguage(this.lang);
    window.location.href = this.redirection;
  }
}
