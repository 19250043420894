import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {GroupRolesTemplatesActionTypes, LoadGroupRolesTemplatesSuccessAction} from '../../shared/actions/group-roles-templates';
import {error} from '../../shared/actions/toasts';
import {BaseEffect} from '../../shared/effects/base.effect';
import {ArrayResponse} from '../../shared/models/array-response';
import {GroupRole} from '../../shared/models/group-role';
import {SendBackResult} from '../../shared/models/send-back-result';
import * as fromRoot from '../../shared/reducers';
import * as fromShared from '../../shared/reducers/index';
import {GroupRolesTemplatesService} from '../../shared/services/group-roles-templates.service';
import {
  CreateGroupRoleTemplatesAction,
  CreateGroupRoleTemplatesFailAction,
  CreateGroupRoleTemplatesSuccessAction,
  GroupRoleTemplatesActionTypes
} from '../actions/group-role-ex-templates';
import {GroupRoleEx} from '../models/group-role-ex';
import {GroupRolesExService} from '../services/group-roles-ex.service';

@Injectable()
export class GroupRolesTemplatesEffect extends BaseEffect {
  private client$ = this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => !_.isEmpty(client)));

  @Effect()
  load$ = this.actions$.pipe(ofType(GroupRolesTemplatesActionTypes.LOAD),
                             delayWhen(() => this.client$),
                             debug('Group roles load action received.'),
                             withLatestFrom(this.client$),
                             switchMap(([action, client]) => this.groupRolesTemplatesService.getListTemplates(client.uuid)
                                                               .pipe(map((payload: SendBackResult<ArrayResponse<GroupRole>>) =>
                                                                           new LoadGroupRolesTemplatesSuccessAction(payload.data.array)),
                                                                     catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ =
    this.actions$.pipe(ofType(GroupRolesTemplatesActionTypes.LOAD_FAIL),
                       debug('A server error occurred while retrieving group roles list Templates.'),
                       map(() => error(this.translation.translate('GROUP_ROLES_TEMPLATES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  @Effect()
  loadTemplate$ = this.actions$.pipe(
    ofType(GroupRoleTemplatesActionTypes.CREATE),
    delayWhen(() => this.client$),
    debug('createTemplate group role template action received.'),
    map((action: CreateGroupRoleTemplatesAction) => action.payload),
    withLatestFrom(this.client$),
    switchMap(([payload, client]) => this.groupRolesExService.createTemplate(client.uuid, payload)
                                       .pipe(map((result: SendBackResult<GroupRoleEx>) => new CreateGroupRoleTemplatesSuccessAction(result.data)),
                                             catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFailTemplate$ =
    this.actions$.pipe(ofType(GroupRoleTemplatesActionTypes.CREATE_FAIL),
                       debug('A server error occurred while retrieving group role template details.'),
                       map(() => error(this.translation.translate('GROUP_ROLE_EX_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService,
              private groupRolesTemplatesService: GroupRolesTemplatesService,
              private groupRolesExService: GroupRolesExService,
              router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new CreateGroupRoleTemplatesFailAction(response.status); }
}
