import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Player} from '../models/player';
import {PlayersFilterForm} from '../models/players-filter-form';

export const PlayersListActionTypes = {
  TOGGLE_SEARCH: type('[Players] Toggle Search Form'),
  QUICK_SEARCH: type('[Players] Quick Search'),
  SEARCH: type('[Players] Search'),
  CHANGE_PAGE_SIZE: type('[Players] Change Page Size'),
  PAGE_TO: type('[Players] Page To'),
  ORDER_BY: type('[Players] Order By'),
  LOAD: type('[Players] Load'),
  LOAD_SUCCESS: type('[Players] Load Success'),
  LOAD_VALIDATION_FAIL: type('[Players] Load Validation Fail'),
  LOAD_FAIL: type('[Players] Load Fail'),
  CLEAR: type('[Players] Clear Data')
};

export class ToggleSearchPlayersAction implements Action {
  readonly type = PlayersListActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class QuickSearchPlayersAction implements Action {
  readonly type = PlayersListActionTypes.QUICK_SEARCH;
  constructor(public payload?: string) {}
}

export class SearchPlayersAction implements Action {
  readonly type = PlayersListActionTypes.SEARCH;
  constructor(public payload?: PlayersFilterForm) {}
}

export class ChangePlayersPageSizeAction implements Action {
  readonly type = PlayersListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PagePlayersToAction implements Action {
  readonly type = PlayersListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderPlayersByAction implements Action {
  readonly type = PlayersListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadPlayersAction implements Action {
  readonly type = PlayersListActionTypes.LOAD;
  constructor() {}
}

export class LoadPlayersSuccessAction implements Action {
  readonly type = PlayersListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Player>) {}
}

export class LoadPlayersValidationFailAction implements Action {
  readonly type = PlayersListActionTypes.LOAD_VALIDATION_FAIL;
  constructor() {}
}

export class LoadPlayersFailAction implements Action {
  readonly type = PlayersListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ClearPlayersAction implements Action {
  readonly type = PlayersListActionTypes.CLEAR;
  constructor() {}
}
