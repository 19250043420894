import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';

import {BlockUIEvent} from '../models/block-ui-event';
import {BlockUIEventType} from '../models/block-ui-event-type.enum';

@Injectable({ providedIn: 'root' })
export class BlockUIService {
  public events$: Observable<BlockUIEvent>;
  private eventsSource = new BehaviorSubject<BlockUIEvent>(new BlockUIEvent(BlockUIEventType.Hide));
  private xhrCount: { [key: string]: boolean } = {};

  constructor() {
    this.events$ = this.eventsSource.asObservable();
    console.log(this.eventsSource);
  }

  show(): void {
    this.eventsSource.next(new BlockUIEvent(BlockUIEventType.Show));
    console.log(this.eventsSource);
  }

  hide(): void {
    this.eventsSource.next(new BlockUIEvent(BlockUIEventType.Hide));
    console.log(this.eventsSource);
  }

  startXHR(uuid: string): void {
    // console.log('this.progress.start' , this.xhrCount);
    this.show();
    this.timer();
    // const prev = _.keys(this.xhrCount).length;
    // this.xhrCount[uuid] = true;
    // if (_.keys(this.xhrCount).length > 0 && prev === 0) {
    //   this.show();
    //   this.timer();
    // }
  }

  endXHR(uuid: string): void {
    this.hide();
    // console.log('this.progress.done')
    // if (_.has(this.xhrCount, uuid)) {
    //   delete this.xhrCount[uuid];
    //   if (_.keys(this.xhrCount).length <= 0) {
    //     this.hide();
    //   }
    // } else {
    //   if (_.keys(this.xhrCount).length <= 0) {
    //     this.hide();
    //   }
    // }
  }
  timer(): void {
    setTimeout(() => {
      //  console.log('+++++++++++++++++++++++++++++++++++++++++++')
      // if (_.keys(this.xhrCount).length > 0) {
      this.hide();
      // }
    }, 30000);
  }
}
