import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse} from '../models/array-response';
import {QuickSearchFilter} from '../models/quick-search-filter';
import {QuickSearchResult} from '../models/quick-search-result';
import {SendBackResult} from '../models/send-back-result';
import {serializeQueryString} from '../utilities/serialize-query-string';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class QuickSearchService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'QuickSearchService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(filter: QuickSearchFilter): Observable<SendBackResult<ArrayResponse<QuickSearchResult>>> {
    return this.get<ArrayResponse<QuickSearchResult>>(`/players/search${serializeQueryString(filter)}`);
  }
}
