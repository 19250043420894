import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadOfficesFailAction, LoadOfficesSuccessAction, officesActionTypes} from '../actions/offices';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {Office} from '../models/culture';
import {SendBackResult} from '../models/send-back-result';
import {OfficesService} from '../services/offices.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class OfficesEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(ofType(officesActionTypes.LOAD),
                             debug('Load offices action received;'),
                             switchMap(() => this.officesService.getList().pipe(
                                         map((payload: SendBackResult<ArrayResponse<Office>>) => new LoadOfficesSuccessAction(payload.data.array)),
                                         catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(officesActionTypes.LOAD_FAIL),
                                 debug('Load offices fail action received.'),
                                 map(() => error(this.translation.translate('OFFICES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private officesService: OfficesService, router: Router) { super(router); }

  protected handleUnhandledError(response: Response): Action { return new LoadOfficesFailAction(response.status); }
}
