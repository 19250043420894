<!-- BEGIN TOP NAVIGATION MENU -->
<div class="top-menu">
  <ul class="nav navbar-nav pull-right">
    <!-- BEGIN USER LOGIN DROPDOWN -->
    <li class="dropdown dropdown-user">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
        <img [alt]="profileName" class="img-circle" [src]="profilePicture" />
        <span class="username username-hide-on-mobile">
          <span *ngIf="impersonating; then impersonatedUserTpl else normalUserTpl"></span>
          <ng-template #normalUserTpl>{{profileName}}</ng-template>
          <ng-template #impersonatedUserTpl>{{profileName}} {{ 'RUN_AS' | translate:currentLanguage }} {{impersonatedUser.name}}</ng-template>
        </span>
        <i class="fa fa-angle-down"></i>
      </a>
      <ul class="dropdown-menu dropdown-menu-default">
        <li *ngIf="canImpersonate || impersonating">
          <div *ngIf="impersonating; then leaveImpersonate else enterImpersonate"></div>
          <ng-template #enterImpersonate>
            <a href="#" (click)="impersonateUser($event)">
              <i class="fa fa-eye" aria-hidden="true"></i>
              {{ 'IMPERSONATE_USER' | translate:currentLanguage }}
            </a>
          </ng-template>
          <ng-template #leaveImpersonate>
            <a href="#" (click)="leaveImpersonation($event)">
              <i class="fa fa-eye-slash" aria-hidden="true"></i>
              {{ 'LEAVE_IMPERSONATION' | translate:currentLanguage }}
            </a>
          </ng-template>
        </li>
        <li>
          <a [routerLink]="['/my-profile']">
            <i class="icon-user" aria-hidden="true"></i>
            {{ 'MY_PROFILE' | translate:currentLanguage }}
          </a>
        </li>
        <li class="divider"> </li>
        <li>
          <a href="#" (click)="doLogout($event)">
            <i class="icon-key" aria-hidden="true"></i>
            {{ 'LOGOUT' | translate:currentLanguage }}
          </a>
        </li>
      </ul>
    </li>
    <!-- END USER LOGIN DROPDOWN -->
    <!-- BEGIN LANGUAGE BAR -->
    <!-- DOC: Apply "dropdown-dark" class after below "dropdown-extended" to change the dropdown styte -->
    <li class="dropdown dropdown-language">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-auto-scroll="true">
        <span class="flag-icon flag-icon-{{getFlag(currentLanguage)}} flag-icon-large"></span>&nbsp;
        <span class="langname">{{ 'LANG_' + currentLanguage?.toUpperCase() | translate:currentLanguage }}</span>
        <i class="fa fa-angle-down"></i>
      </a>
      <ul class="dropdown-menu">
        <li *ngFor="let language of availableLanguages">
          <a href="#" (click)="changeLanguage($event, language.locale)">
            <span class="flag-icon flag-icon-{{getFlag(language.locale.language)}}"></span>&nbsp;
            <span>{{ 'LANG_' + language.locale.language.toUpperCase() | translate:currentLanguage }}</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- END LANGUAGE BAR -->
  </ul>
</div>
<!-- END TOP NAVIGATION MENU -->
