import {Action} from '@ngrx/store';

import {GroupRoleTemplate, type} from '../../shared/index';
import {GroupRoleEx} from '../models/group-role-ex';

export const GroupRoleTemplatesActionTypes = {
  CREATE: type('[Group Role Ex Templates] CREATE'),
  CREATE_SUCCESS: type('[Group Role Ex Templates] CREATE Success'),
  CREATE_FAIL: type('[Group Role Ex Templates ] CREATE Server Error')
};

export class CreateGroupRoleTemplatesAction implements Action {
  readonly type = GroupRoleTemplatesActionTypes.CREATE;
  constructor(public payload?: GroupRoleTemplate) {}
}

export class CreateGroupRoleTemplatesSuccessAction implements Action {
  readonly type = GroupRoleTemplatesActionTypes.CREATE_SUCCESS;
  constructor(public payload?: GroupRoleEx) {}
}

export class CreateGroupRoleTemplatesFailAction implements Action {
  readonly type = GroupRoleTemplatesActionTypes.CREATE_FAIL;
  constructor(public payload?: number) {}
}
