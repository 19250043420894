import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CloseWarningMessageAction, LayoutActionTypes, OpenWarningMessageAction, SetLanguageAction} from '../actions/layout';
import {LoadPreferencesSuccessAction, preferencesActionTypes} from '../actions/preferences';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {Preference} from '../models/preferences';
import {WarningMessage} from '../models/warning-message';

export interface LayoutState {
  sidebarClosed: boolean;
  language: string;
  warningMessageClosed: WarningMessage;
  preferences: Preference[];
}

const initialState: LayoutState = {
  sidebarClosed: false,
  language: undefined,
  warningMessageClosed: { playerId: '', warningPopUp: [{ id: 1, closed: false }, { id: 2, closed: false }] },
  preferences: null
};

export function layoutReducer(state = initialState, action: Action): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.TOGGLE_SIDEBAR:
      return {
        sidebarClosed: !state.sidebarClosed,
        language: state.language,
        warningMessageClosed: state.warningMessageClosed,
        preferences: state.preferences
      };
    case LayoutActionTypes.SET_LANGUAGE:
      return handleSetLanguageAction(state, action);
    case LayoutActionTypes.CLOSE_WARN_MESSAGE:
      return handleSetWarningMessageAction(state, action);
    case LayoutActionTypes.OPEN_WARN_MESSAGE:
      return handleOpenWarningMessageAction(state, action);
    case preferencesActionTypes.LOAD_SUCCESS:
    case preferencesActionTypes.SET_SUCCESS:
      return handlePreferencesAction(state, action);
    default:
      return state;
  }
}

function handleSetLanguageAction(state: LayoutState, action: SetLanguageAction): LayoutState {
  return { sidebarClosed: state.sidebarClosed, language: action.payload, warningMessageClosed: state.warningMessageClosed, preferences: state.preferences };
}

function handleSetWarningMessageAction(state: LayoutState, action: CloseWarningMessageAction): LayoutState {
  const resp = state.warningMessageClosed;
  resp.warningPopUp[action.payload.id - 1].closed = action.payload.closed;
  return { sidebarClosed: state.sidebarClosed, language: state.language, warningMessageClosed: resp, preferences: state.preferences };
}
function handlePreferencesAction(state: LayoutState, action: LoadPreferencesSuccessAction): LayoutState {
  return { sidebarClosed: state.sidebarClosed, language: state.language, warningMessageClosed: state.warningMessageClosed, preferences: action.payload };
}
function handleOpenWarningMessageAction(state: LayoutState, action: any): LayoutState {
  const isSamePlayer = state.warningMessageClosed.playerId === action.payload ? true : false;
  return {
    sidebarClosed: state.sidebarClosed,
    language: state.language,
    preferences: state.preferences,
    warningMessageClosed: {
      playerId: action.payload,
      warningPopUp: [
        { id: 1, closed: isSamePlayer ? state.warningMessageClosed.warningPopUp[0].closed : false },
        { id: 2, closed: isSamePlayer ? state.warningMessageClosed.warningPopUp[1].closed : false },
      ]
    }
  };
}

export const layoutSelectors = {
  sidebarClosed: (state: LayoutState) => _.get(state, 'sidebarClosed', false),
  language: (state: LayoutState) => _.get(state, 'language'),
  warningMessageClosed: (state: LayoutState) => _.get(state, 'warningMessageClosed'),
  preferences: (state: LayoutState) => _.get(state, 'preferences'),
  preferencesByName: (state: LayoutState, props: string) => {
    return state.preferences ? +state.preferences.filter(pref => pref.name === props).length ? +state.preferences.filter(pref => pref.name === props)[0].value
                                                                                             : DEFAULT_PAGE_SIZE
                             : undefined
  }
};
