import {Action} from '@ngrx/store';
import {type} from '../utilities/type';

export const productionLineActionTypes = {
  LOAD: type('[ProductionLine] Load'),
  LOAD_SUCCESS: type('[ProductionLine] Load Success'),
  LOAD_FAIL: type('[ProductionLine] Load Fail')
};

export class LoadProductionLineAction implements Action {
  readonly type = productionLineActionTypes.LOAD;
  constructor() {}
}

export class LoadProductionLineSuccessAction implements Action {
  readonly type = productionLineActionTypes.LOAD_SUCCESS;
  // tslint:disable-next-line:no-any
  constructor(public payload?: any[]) {}
}

export class LoadProductionLineFailAction implements Action {
  readonly type = productionLineActionTypes.LOAD_FAIL;
  constructor(public payload?: string) {}
}
