import {Action} from '@ngrx/store';

import {WarningMessage, WarningPopUp} from '../models/warning-message';
import {type} from '../utilities/type';

export const LayoutActionTypes = {
  TOGGLE_SIDEBAR: type('[Layout] Toggle Sidebar'),
  SET_LANGUAGE: type('[Layout] Set Language'),
  CLOSE_WARN_MESSAGE: type('[Layout] Close Warning Message'),
  OPEN_WARN_MESSAGE: type('[Layout] Open Warning Messages')
};

export class ToggleSidebarAction implements Action {
  readonly type = LayoutActionTypes.TOGGLE_SIDEBAR;
  constructor() {}
}

export class SetLanguageAction implements Action {
  readonly type = LayoutActionTypes.SET_LANGUAGE;
  constructor(public payload?: string) {}
}

export class CloseWarningMessageAction implements Action {
  readonly type = LayoutActionTypes.CLOSE_WARN_MESSAGE;
  constructor(public payload?: WarningPopUp) {}
}

export class OpenWarningMessageAction implements Action {
  readonly type = LayoutActionTypes.OPEN_WARN_MESSAGE;
  constructor(public payload: string) {}
}
