import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {UsersListActionTypes} from '../../users/actions/users-list';
import {PlayerIssue} from '../models/player-issue';
import {PlayersIssueFilter} from '../models/players-issue-filter';

export const PlayersIssueListActionTypes = {
  TOGGLE_SEARCH: type('[Players Issue] Toggle Search Form'),
  QUICK_SEARCH: type('[Players Issue] Quick Search'),
  SEARCH: type('[Players Issue] Search'),
  CHANGE_PAGE_SIZE: type('[Players Issue] Change Page Size'),
  PAGE_TO: type('[Players Issue] Page To'),
  ORDER_BY: type('[Players Issue] Order By'),
  SELECT_PAGE: type('[Players Issue] Select Page'),
  UNSELECT_PAGE: type('[Players Issue] Unselect Page'),
  TOGGLE_SELECTION: type('[Players Issue] Toggle Selection'),
  LOAD: type('[Players Issue] Load'),
  LOAD_SUCCESS: type('[Players Issue] Load Success'),
  LOAD_FAIL: type('[Players Issue] Load Fail'),
  CLEAR: type('[Players Issue] Clear Data'),
  PRODUCTION_LINE: type('[orders] Production Line'),
};
export class SelectPlayersIssuePageAction implements Action {
  readonly type = PlayersIssueListActionTypes.SELECT_PAGE;

  constructor() {}
}
export class TogglePlayersIssueSelectionAction implements Action {
  readonly type = PlayersIssueListActionTypes.TOGGLE_SELECTION;

  /* payload is the user id */
  constructor(public payload?: number) {}
}
export class UnselectPlayersIssuePageAction implements Action {
  readonly type = PlayersIssueListActionTypes.UNSELECT_PAGE;

  constructor() {}
}
export class ToggleSearchPlayersIssueAction implements Action {
  readonly type = PlayersIssueListActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}
export class ProductionLineAction implements Action {
  readonly type = PlayersIssueListActionTypes.PRODUCTION_LINE;
  constructor(public payload?: string) {}
}

export class QuickSearchPlayersIssueAction implements Action {
  readonly type = PlayersIssueListActionTypes.QUICK_SEARCH;
  constructor(public payload?: string) {}
}

export class SearchPlayersIssueAction implements Action {
  readonly type = PlayersIssueListActionTypes.SEARCH;
  constructor(public payload?: any) {}
}

export class ChangePlayersIssuePageSizeAction implements Action {
  readonly type = PlayersIssueListActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PagePlayersIssueToAction implements Action {
  readonly type = PlayersIssueListActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderPlayersIssueByAction implements Action {
  readonly type = PlayersIssueListActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadPlayersIssueAction implements Action {
  readonly type = PlayersIssueListActionTypes.LOAD;
  constructor() {}
}

export class LoadPlayersIssueSuccessAction implements Action {
  readonly type = PlayersIssueListActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<PlayerIssue>) {}
}

export class LoadPlayersIssueFailAction implements Action {
  readonly type = PlayersIssueListActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ClearPlayersIssueAction implements Action {
  readonly type = PlayersIssueListActionTypes.CLEAR;
  constructor() {}
}
