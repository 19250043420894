import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {PlayerReportActionTypes} from '../actions/player-report';

export interface PlayerReportOfflineState {
  client: { id: number, name: string };
  created: string;
  items: any[];
  uuid: string;
}

const initialState: PlayerReportOfflineState = {
  client: { id: 0, name: '' },
  created: '',
  items: [],
  uuid: '',
};

export function playersReportOfflineReducer(state = initialState, action: Action): PlayerReportOfflineState {
  switch (action.type) {
    case PlayerReportActionTypes.LOAD_SUCCESS:
      return handleLoadAction(state, action);
    default:
      return state;
  };
};

function handleLoadAction(state: PlayerReportOfflineState, action: any): PlayerReportOfflineState {
  const resp = {...state, ...action.payload };
  // console.log(resp);
  return resp;
}

export const playersReportOfflineSelectors = {
  client: (state: PlayerReportOfflineState) => _.get(state, 'client'),
  items: (state: PlayerReportOfflineState) => _.get(state, 'items'),
};
