import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as auth0 from 'auth0-js';
import * as _ from 'lodash';
import {empty, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {debug} from '../../../rxjs-operators';
import {DEFAULT_PAGE_SIZE, EMPTY_GUID, MANAGEMENT_API_TOKEN} from '../consts';
import {Auth0Identity} from '../models/auth0-identity';
import {Auth0Logs} from '../models/auth0-logs';
import {Auth0PatchUserProfile} from '../models/auth0-patch-user-profile';
import {Auth0UserProfile} from '../models/auth0-user-profile';
import {PaginatedListRequest} from '../models/paginated-list-request';
import {tokenNotExpired} from '../utilities/token-not-expired';

import {AppSettingsService} from './app-settings.service';
import {BaseService} from './base.service';

@Injectable({ providedIn: 'root' })
export class Auth0ManagementService extends BaseService {
  private managementApiToken: string;
  private cpt = 1;

  constructor(private http: HttpClient, private appSettingsService: AppSettingsService) {
    super();
    this.managementApiToken = localStorage.getItem(MANAGEMENT_API_TOKEN);
    if (!tokenNotExpired(undefined, this.managementApiToken)) {
      this.managementApiToken = undefined;
      localStorage.removeItem(MANAGEMENT_API_TOKEN);
    }
    this.crossOriginAuthenticationCallback();
  }

  crossOriginAuthenticationCallback(): void {
    // tslint:disable-next-line:no-console
    console.log(`${location.protocol}//${location.host}`);
    this.appSettingsService.settings$.pipe(map(settings => {
      new auth0
        .WebAuth({
          clientID: settings.auth0.clientId,
          domain: settings.auth0.domain,
          redirectUri: `${location.protocol}//${location.host}`,
        })
        .crossOriginAuthenticationCallback(); // TODO: why not auth0.crossOriginVerification(); ?
    }));
  }

  getProfile(userId: string): Observable<Auth0UserProfile> {
    return this.getManagementApiToken().pipe(map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
                                             withLatestFrom(this.appSettingsService.settings$),
                                             switchMap(([headers, appSettings]) => this.http.get<Auth0UserProfile>(
                                                         `https://${appSettings.auth0.domain}/api/v2/users/${userId}`, { headers: headers })));
  }

  updateProfile(userId: string, profile: Auth0PatchUserProfile): Observable<Auth0UserProfile> {
    if (_.isEmpty(userId)) {
      return empty();
    }

    return this.getManagementApiToken().pipe(map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
                                             withLatestFrom(this.appSettingsService.settings$),
                                             switchMap(([headers, appSettings]) => this.http.patch<Auth0UserProfile>(
                                                         `https://${appSettings.auth0.domain}/api/v2/users/${userId}`, profile, { headers: headers })))
  }

  linkIdentity(idToken: string, userId: string, externalIdToken: string): Observable<Auth0Identity[]> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
    return this.appSettingsService.settings$.pipe(
      switchMap(appSettings => this.http.post<Auth0Identity[]>(
                  `https://${appSettings.auth0.domain}/api/v2/users/${userId}/identities`, { link_with: externalIdToken }, { headers: headers })))
  }

  unlinkIdentity(idToken: string, userId: string, identity: Auth0Identity): Observable<Auth0Identity[]> {
    const headers = new HttpHeaders({ Authorization: `Bearer ${idToken}` });
    return this.appSettingsService.settings$.pipe(
      switchMap(appSettings => this.http.delete<Auth0Identity[]>(
                  `https://${appSettings.auth0.domain}/api/v2/users/${userId}/identities/${identity.provider}/${identity.user_id}`, { headers: headers })),
      switchMap(identities => this.deleteIdentity(identity, identities)));
  }

  loadUserLogs(email: string, filter: PaginatedListRequest, clientId: string): Observable<Auth0Logs> {
    const page = +(_.get(filter, '$page', 1)) - 1;
    const perPage = _.get(filter, '$length', DEFAULT_PAGE_SIZE);
    const sort = _.get(filter, '$orderBy');
    let query = `page=${page}&per_page=${+ perPage === -1 ? 0 : perPage}`;
    if (sort) {
      const sortSTR = '' + sort
      if (/\sdescending$/.test(sortSTR)) {
        query += `&sort=${sortSTR.replace(/\sdescending$/, '')}:-1`;
      }
      else {
        query += `&sort=${sort}:1`;
      }
    }

    return this.getUserId(email).pipe(
      map(userId => userId || `auth0|${EMPTY_GUID}`),
      switchMap(userId =>
                  this.getManagementApiToken()
                    .pipe(map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
                          debug(`Get logs by clientId: ${clientId}`),
                          withLatestFrom(this.appSettingsService.settings$.pipe(map(appSettings => appSettings.auth0.domain))))
                    .pipe(switchMap(([headers, domain]) =>
                                      this.http.get<Auth0Logs>(`https://${domain}/api/v2/logs?include_totals=true&q=user_id:${userId}&${query}`, { headers })
                                        .pipe(debug(`Search for logs by user_id ${userId}`))))),
    )
  }

  updateBlockedState(email: string, blocked: boolean): Observable<Auth0UserProfile> {
    return this.getUserId(email).pipe(debug(`user_id of ${email}`), switchMap(userId => this.updateProfile(userId, { blocked })));
  }

  private getUserId(email: string): Observable<string> {
    return this.getManagementApiToken().pipe(
      map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
      withLatestFrom(this.appSettingsService.settings$),
      switchMap(
        ([headers, appSettings]) =>
          this.http
            .get<Auth0UserProfile>(`https://${appSettings.auth0.domain}/api/v2/users?include_totals=true&fields=user_id&q=email:"${email}"&search_engine=v2`,
                                   { headers: headers })
            .pipe(debug(`Search for user by email ${email}`), map(data => _.get(data, ['users', 0, 'user_id'])))),
    );
  }

  // tslint:disable-next-line:no-any
  getUserGeoip(email: string): Observable<any> {
    return this.getManagementApiToken().pipe(
      map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
      withLatestFrom(this.appSettingsService.settings$),
      switchMap(([headers, appSettings]) =>
                  this.http
                    .get(`https://${appSettings.auth0.domain}/api/v2/users?include_totals=true&include_fields=true&q=email:"${email}"&search_engine=v2`,
                         { headers: headers })
                    .pipe(debug(`Search for user geoip by email ${email}`), map(data => _.get(data, ['users', 0, 'user_metadata', 'geoip'])))));
  }

  private deleteIdentity(identity: Auth0Identity, identities: Auth0Identity[]): Observable<Auth0Identity[]> {
    return this.getManagementApiToken().pipe(
      map(token => new HttpHeaders({ Authorization: `Bearer ${token}` })),
      withLatestFrom(this.appSettingsService.settings$),
      switchMap(([headers, appSettings]) =>
                  this.http.delete(`https://${appSettings.auth0.domain}/api/v2/users/${identity.provider}|${identity.user_id}`, { headers: headers })),
      map(res => identities),
      catchError(err => {
        this.warn('Unlinked identity failed to be deleted.', err);
        return of(identities);
      }));
  }

  private getManagementApiToken(): Observable<string> {
    if (this.managementApiToken && tokenNotExpired(undefined, this.managementApiToken)) {
      return of(this.managementApiToken);
    } else {
      return this.appSettingsService.settings$.pipe(switchMap(appSettings => {
                                                      const request = {
                                                        client_id: 'sg0v7NJIJgUmzhRfbmTqA6DN1n1gL2aL',
                                                        client_secret: '5PDL1dNnjSoBT0KqZtKgDpgYFpCB62P5VPkAXTgiUpUzM7vzzToylJllHD9pnIS4',
                                                        audience: `https://${appSettings.auth0.domain}/api/v2/`,
                                                        grant_type: 'client_credentials'
                                                      };
                                                      return this.http.post(`https://${appSettings.auth0.domain}/oauth/token`, request);
                                                    }),
                                                    map(json => { // console.log(json['access_token']);
                                                                  return json['access_token'] }),
                                                    // tslint:disable-next-line:variable-name
                                                    tap(access_token => (this.managementApiToken = access_token)),
                                                    // tslint:disable-next-line:variable-name
                                                    tap(access_token => { // console.log(localStorage.getItem(MANAGEMENT_API_TOKEN));
                                                                          localStorage.setItem(MANAGEMENT_API_TOKEN, access_token) }),
                                                    debug(`Get Management Api Token from localStorage ${localStorage.getItem(MANAGEMENT_API_TOKEN)}`));
    }
  }
}
