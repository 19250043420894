import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as auth0 from 'auth0-js';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {error} from '../actions/toasts';
import {ChangeClientAction} from '../actions/user-context';
import {LoadUserInfoFailAction, LoadUserInfoSuccessAction, LogoutAction, UserInfoActionTypes} from '../actions/user-info';
import {CUSTOM_CLAIMS_DEFAULT_CLIENT_ID} from '../consts';
import {Auth0UserInfo} from '../models/auth0-user-info';
import {RouterStateUrl} from '../models/router-state-url';
import * as fromRoot from '../reducers';
import {Auth0AuthenticationService} from '../services/auth0-authentication.service';

@Injectable()
export class UserInfoEffect {
  @Effect()
  load$ =
    this.actions$.pipe(ofType(UserInfoActionTypes.LOAD),
                       debug('Load user info action received'),
                       switchMap(() => this.authService.getUserInfo().pipe(
                                   withLatestFrom(this.store.pipe(select(fromRoot.selectors.getRouterState))),
                                   switchMap(([userInfo, routerState]) =>
                                               of<Action>(new LoadUserInfoSuccessAction(userInfo),
                                                          new ChangeClientAction({ clientId: this.getStoredClient(userInfo, routerState), redirect: false }))),
                                   catchError(res => this.handleAuth0Error(res)))));

  @Effect()
  reload$ = this.actions$.pipe(ofType(UserInfoActionTypes.RELOAD),
                               debug('Reload user info action received.'),
                               switchMap(() => this.authService.getUserInfo(true).pipe(map((payload: Auth0UserInfo) => new LoadUserInfoSuccessAction(payload)),
                                                                                       catchError(() => of(new LoadUserInfoFailAction())))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(UserInfoActionTypes.LOAD_FAIL),
                                 debug('Auth0 /userinfo error (in payload):'),
                                 map((action: LoadUserInfoFailAction) => error(this.translation.translate('AUTH_GET_USER_INFO_ERROR', action.payload),
                                                                               this.translation.translate('TOAST_ERROR_TITLE'))));

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(ofType(UserInfoActionTypes.LOGOUT),
                               debug('Logout action received.'),
                               tap((action: LogoutAction) => this.authService.logout(action.payload)));

  constructor(private actions$: Actions,
              private translation: L10nTranslationService,
              private authService: Auth0AuthenticationService,
              private store: Store<fromRoot.AppState>) {}

  private handleAuth0Error(err: auth0.Auth0Error): Observable<Action> { return of(new LoadUserInfoFailAction(err)); }

  private getStoredClient(userInfo: Auth0UserInfo, routerState: RouterStateUrl): string {
    if (!_.isEmpty(routerState) && _.has(routerState, ['params', 'clientId'])) {
      return _.get(routerState, ['params', 'clientId']);
    }

    return userInfo[CUSTOM_CLAIMS_DEFAULT_CLIENT_ID];
  }
}
