import {Action} from '@ngrx/store';
import {type} from '../utilities/type';

export const clientConfigurationActionTypes = {
  LOAD: type('[ClientConfiguration] Load'),
  LOAD_SUCCESS: type('[ClientConfiguration] Load Success'),
  LOAD_FAIL: type('[ClientConfiguration] Load Fail')
};

export class LoadClientConfigurationAction implements Action {
  readonly type = clientConfigurationActionTypes.LOAD;

  constructor() {}
}

export class LoadClientConfigurationSuccessAction implements Action {
  readonly type = clientConfigurationActionTypes.LOAD_SUCCESS;

  constructor(public payload?: any[]) {}
}

export class LoadClientConfigurationFailAction implements Action {
  readonly type = clientConfigurationActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
