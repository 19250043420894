import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';

import {DateTimeFormatService, DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {
  ChangePlayLogsPageSizeAction,
  LoadPlayLogsAction,
  LoadPlayLogsSuccessAction,
  OrderPlayLogsByAction,
  PagePlayLogsToAction,
  PlayLogsActionTypes,
  SearchPlayLogsAction,
  TogglePlayLogsPlayAction,
  ToggleSearchPlayLogsAction
} from '../actions/play-logs';
import {PlayLog} from '../models/play-log';
import {PlayLogsFilterForm} from '../models/play-logs-filter-form';

export interface PlayLogsState {
  showFilter: boolean;
  array: PlayLog[];
  count: number;
  playing: string;
}

const initialState: PlayLogsState = {
  showFilter: false,
  array: [],
  count: 0,
  playing: undefined
};

export function playLogsReducer(state = initialState, action: Action): PlayLogsState {
  switch (action.type) {
    case PlayLogsActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case PlayLogsActionTypes.CLEAR:
      return handleClearAction(state);
    case PlayLogsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case PlayLogsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case PlayLogsActionTypes.TOGGLE_PLAY:
      return handleTogglePlayAction(state, action);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: PlayLogsState, action: ToggleSearchPlayLogsAction): PlayLogsState {
  const newState: PlayLogsState = { showFilter: action.payload, array: state.array, count: state.count, playing: state.playing };
  return newState;
}

function handleClearAction(state: PlayLogsState): PlayLogsState {
  const newState: PlayLogsState = { showFilter: state.showFilter, array: [], count: 0, playing: undefined };
  return newState;
}

function handleLoadSuccessAction(state: PlayLogsState, action: LoadPlayLogsSuccessAction): PlayLogsState {
  const newState: PlayLogsState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count, playing: undefined };
  return newState;
}

function handleLoadFailAction(state: PlayLogsState): PlayLogsState {
  const newState: PlayLogsState = { showFilter: state.showFilter, array: [], count: 0, playing: undefined };
  return newState;
}

function handleTogglePlayAction(state: PlayLogsState, action: TogglePlayLogsPlayAction): PlayLogsState {
  if (state.playing === action.payload) {
    return {...state, playing: undefined };
  } else {
    return {...state, playing: action.payload };
  }
}

function handleChangeClientAction(): PlayLogsState {
  return { showFilter: false, array: [], count: 0, playing: undefined };
}

export const playLogsSelectors = {
  showFilter: (state: PlayLogsState) => _.get(state, 'showFilter', false),
  array: (state: PlayLogsState) => _.get(state, 'array', []),
  count: (state: PlayLogsState) => _.get(state, 'count', 0),
  playing: (state: PlayLogsState) => _.get(state, 'playing')
};
