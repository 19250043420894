import * as _ from 'lodash';
import * as moment from 'moment';

// tslint:disable-next-line:no-any
export function serializeQueryString(obj: any, isParam?: boolean): string {
  const qs: string[] = [];
  _.forOwn(obj, (value, key) => {
    if (value) {
      if (value instanceof Date) {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(moment(value).format('YYYY-MM-DDTHH:mm:ssZZ'))}`);
      } else if (value instanceof moment) {
        // @ts-ignore
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.format('YYYY-MM-DDTHH:mm:ssZZ'))}`);
      } else {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  });
  if (qs.length > 0) {
    if (isParam) {
      return '&' + qs.join('&');
    } else {
      return '?' + qs.join('&');
    }
  }
  return '';
}
