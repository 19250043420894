import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap, take} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {
  LoadPreferencesFailAction,
  LoadPreferencesSuccessAction,
  preferencesActionTypes,
  SetPreferencesAction,
  SetPreferencesSuccessAction
} from '../actions/preferences';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {Preference} from '../models/preferences';
import {SendBackResult} from '../models/send-back-result';
import {PreferencesService} from '../services/preferences.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class PreferencesEffect extends BaseEffect {

  load$ = createEffect(
    () => { return this.actions$.pipe(ofType(preferencesActionTypes.LOAD),
                                      debug('Load preferences action received.'),
                                      switchMap(() => this.preferencesService.getList().pipe(map((response: SendBackResult<ArrayResponse<Preference>>) =>
                                                                                                   new LoadPreferencesSuccessAction(response.data.array)),
                                                                                             catchError(res => this.catchResponseError(res)))),
                                      take(1)) });

  set$ = createEffect(() => { return this.actions$.pipe(
                        ofType(preferencesActionTypes.SET),
                        debug('Set preference action received.'),
                        map((action: SetPreferencesAction) => action.payload),
                        switchMap((payload: Preference) => this.preferencesService.set(payload).pipe(
                                    map((response: SendBackResult<ArrayResponse<Preference>>) => new SetPreferencesSuccessAction(response.data.array)),
                                    catchError(res => this.catchResponseError(res))))) });

  loadFail$ = createEffect(() => { return this.actions$.pipe(
                             ofType(preferencesActionTypes.LOAD_FAIL),
                             debug('Preferences fail action received.'),
                             map((action: LoadPreferencesFailAction) => error(this.translation.translate('PREFERENCES_LOAD_ERROR') + '  ' + action.payload,
                                                                              this.translation.translate('TOAST_ERROR_TITLE')))) });

  constructor(private actions$: Actions, private translation: L10nTranslationService, private preferencesService: PreferencesService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadPreferencesFailAction(response['error'].message); }

  protected handleGoneError(response: Response): Action { return new LoadPreferencesFailAction(response['error'].message); }
}
