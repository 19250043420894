import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {PlaylistStats} from '../models/playlist-stats';

export const AudioPlaylistStatsActionTypes = {
  LOAD: type('[Audio Playlist Stats] Load'),
  LOAD_SUCCESS: type('[Audio Playlist Stats] Load Success'),
  LOAD_FAIL: type('[Audio Playlist Stats] Load Fail')
};

export class LoadAudioPlaylistStatsAction implements Action {
  readonly type = AudioPlaylistStatsActionTypes.LOAD;

  constructor() {}
}

export class LoadAudioPlaylistStatsSuccessAction implements Action {
  readonly type = AudioPlaylistStatsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: PlaylistStats) {}
}

export class LoadAudioPlaylistStatsFailAction implements Action {
  readonly type = AudioPlaylistStatsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
