import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Inventory} from '../models/inventory';
import {InventoryFilterForm} from '../models/inventory-filter-form';

export const InventoryActionTypes = {
  TOGGLE_SEARCH: type('[Inventory] Toggle Search Form'),
  SEARCH: type('[Inventory] Search'),
  CHANGE_PAGE_SIZE: type('[Inventory] Change Page Size'),
  PAGE_TO: type('[Inventory] Page To'),
  ORDER_BY: type('[Inventory] Order By'),
  LOAD: type('[Inventory] Load'),
  LOAD_SUCCESS: type('[Inventory] Load Success'),
  LOAD_FAIL: type('[Inventory] Load Fail'),
  CLEAR: type('[Inventory] Clear Data')
};

export class ToggleSearchInventoryAction implements Action {
  readonly type = InventoryActionTypes.TOGGLE_SEARCH;
  constructor(public payload?: boolean) {}
}

export class SearchInventoryAction implements Action {
  readonly type = InventoryActionTypes.SEARCH;
  constructor(public payload?: InventoryFilterForm) {}
}

export class ChangeInventoryPageSizeAction implements Action {
  readonly type = InventoryActionTypes.CHANGE_PAGE_SIZE;
  constructor(public payload?: number) {}
}

export class PageInventoryToAction implements Action {
  readonly type = InventoryActionTypes.PAGE_TO;
  constructor(public payload?: number) {}
}

export class OrderInventoryByAction implements Action {
  readonly type = InventoryActionTypes.ORDER_BY;
  constructor(public payload?: string) {}
}

export class LoadInventoryAction implements Action {
  readonly type = InventoryActionTypes.LOAD;
  constructor() {}
}

export class LoadInventorySuccessAction implements Action {
  readonly type = InventoryActionTypes.LOAD_SUCCESS;
  constructor(public payload?: ArrayResponse<Inventory>) {}
}

export class LoadInventoryFailAction implements Action {
  readonly type = InventoryActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}

export class ClearInventoryAction implements Action {
  readonly type = InventoryActionTypes.CLEAR;
  constructor() {}
}
