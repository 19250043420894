import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {ExternalLinksActionTypes, LoadExternalLinksAction, LoadExternalLinksFailAction, LoadExternalLinksSuccessAction} from '../actions/external-links';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {ExternalLink} from '../models/external-link';
import {SendBackResult} from '../models/send-back-result';
import {ExternalLinksService} from '../services/external-links.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class ExternalLinksEffect extends BaseEffect {
  @Effect()
  load$ =
    this.actions$.pipe(ofType(ExternalLinksActionTypes.LOAD),
                       debug('External Links load action received.'),
                       switchMap((action: LoadExternalLinksAction) => this.externalLinksService.getList(action.payload)
                                                                        .pipe(map((result: SendBackResult<ArrayResponse<ExternalLink>>) =>
                                                                                    _.uniqBy(_.filter(result.data.array, x => x.url != undefined), 'url')),
                                                                              map((links: ExternalLink[]) => new LoadExternalLinksSuccessAction(links)),
                                                                              catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(ExternalLinksActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving group roles list.'),
                                 map(() => error(this.translation.translate('GROUP_ROLES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private externalLinksService: ExternalLinksService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadExternalLinksFailAction(response.status); }
}
