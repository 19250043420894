import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {ShippingInfo, UserContextActionTypes} from '../../shared';
import {LoadShippingInfoSuccessAction, ShippingInfoActionTypes} from '../actions/shipping-info';

export interface ShippingInfoState {
  array: ShippingInfo[];
}

const initialState: ShippingInfoState = {
  array: []
};

export function shippingInfoReducer(state = initialState, action: Action): ShippingInfoState {
  switch (action.type) {
    case ShippingInfoActionTypes.LOAD:
      return handleLoadAction();
    case ShippingInfoActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ShippingInfoActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): ShippingInfoState {
  return initialState;
}

function handleLoadSuccessAction(state: ShippingInfoState, action: LoadShippingInfoSuccessAction): ShippingInfoState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: ShippingInfoState): ShippingInfoState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): ShippingInfoState {
  return { array: [] };
}

export const shippingInfoSelectors = {
  array: (state: ShippingInfoState) => _.get(state, 'array', [])
};
