import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import * as moment from 'moment';

import {DateTimeFormatService, DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {
  ChangeVideoPlaylistsPageSizeAction,
  LoadVideoPlaylistsAction,
  LoadVideoPlaylistsSuccessAction,
  OrderVideoPlaylistsByAction,
  PageVideoPlaylistsToAction,
  SearchVideoPlaylistsAction,
  ToggleSearchVideoPlaylistsAction,
  VideoPlaylistsActionTypes
} from '../actions/video-playlists';
import {VideoMedia} from '../models/video-media';
import {VideoPlaylistsFilterForm} from '../models/video-playlists-filter-form';

export interface VideoPlaylistsState {
  showFilter: boolean;
  array: VideoMedia[];
  count: number;
}

const initialState: VideoPlaylistsState = {
  showFilter: false,
  array: [],
  count: 0
};

export function videoPlaylistsReducer(state = initialState, action: Action): VideoPlaylistsState {
  switch (action.type) {
    case VideoPlaylistsActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case VideoPlaylistsActionTypes.CLEAR:
      return handleClearAction(state);
    case VideoPlaylistsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case VideoPlaylistsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: VideoPlaylistsState, action: ToggleSearchVideoPlaylistsAction): VideoPlaylistsState {
  const newState: VideoPlaylistsState = { showFilter: action.payload, array: state.array, count: state.count };
  return newState;
}

function handleClearAction(state: VideoPlaylistsState): VideoPlaylistsState {
  const newState: VideoPlaylistsState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleLoadSuccessAction(state: VideoPlaylistsState, action: LoadVideoPlaylistsSuccessAction): VideoPlaylistsState {
  const newState: VideoPlaylistsState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count };
  return newState;
}

function handleLoadFailAction(state: VideoPlaylistsState): VideoPlaylistsState {
  const newState: VideoPlaylistsState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleChangeClientAction(): VideoPlaylistsState {
  return { showFilter: false, array: [], count: 0 };
}

export const videoPlaylistsSelectors = {
  showFilter: (state: VideoPlaylistsState) => _.get(state, 'showFilter', false),
  array: (state: VideoPlaylistsState) => _.get(state, 'array', []),
  count: (state: VideoPlaylistsState) => _.get(state, 'count', 0)
};
