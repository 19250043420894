import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as moment from 'moment';

import {CUSTOM_CLAIMS_NAME} from '../../consts';
import {Auth0UserInfo} from '../../models/auth0-user-info';
import {ImpersonateUser} from '../../models/impersonate-user';

@Component({
  selector: 'storever-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent {
  @Input() userInfo: Auth0UserInfo;
  @Input() canImpersonate: boolean;
  @Input() impersonatedUser: ImpersonateUser;
  @Input() availableLanguages: string[] = [];
  @Input() currentLanguage = 'en';
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() impersonate: EventEmitter<'Enter'|'Leave'> = new EventEmitter<'Enter'|'Leave'>();
  @Output() selectLanguage: EventEmitter<string> = new EventEmitter<string>();

  get profilePicture(): string {
    if (this.userInfo && this.userInfo.picture) {
      const updateDate = moment.parseZone(this.userInfo.updated_at);
      return `${this.userInfo.picture}?_=${updateDate.format('YYYYMMDDHHmmssSSS')}`;
    }

    return 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&f=y';
  }

  get profileName(): string {
    if (this.userInfo) {
      return this.userInfo[CUSTOM_CLAIMS_NAME] || this.userInfo.name;
    }

    return 'Anonymous';
  }

  get impersonating(): boolean { return this.impersonatedUser != undefined; }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, cdr: ChangeDetectorRef) {}

  getFlag(language: string): string {
    const flagsPerLanguage = { de: 'de', en: 'gb', es: 'es', fr: 'fr', it: 'it', nl: 'nl' };
    return flagsPerLanguage[language];
  }

  changeLanguage($event: MouseEvent, language: string): void {
    console.log(language)
    $event.preventDefault();
    this.selectLanguage.emit(language);
  }

  doLogout($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.logout.emit();
  }

  impersonateUser($event: MouseEvent) {
    $event.preventDefault();
    this.impersonate.emit('Enter');
  }

  leaveImpersonation($event: MouseEvent) {
    $event.preventDefault();
    this.impersonate.emit('Leave');
  }
}
