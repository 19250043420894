import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArrayResponse} from '../models/array-response';
import {GroupRole} from '../models/group-role';
import {SendBackResult} from '../models/send-back-result';
import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class GroupRolesService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'GroupRolesService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(clientId: string): Observable<SendBackResult<ArrayResponse<GroupRole>>> {
    return this.get<ArrayResponse<GroupRole>>(`/clients/${clientId}/groups-roles`);
  }
}
