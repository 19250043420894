import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {DownloadPlayersActionTypes} from '../actions/download-players';
import {LoadPlayersAction, LoadPlayersSuccessAction, PlayersListActionTypes, ToggleSearchPlayersAction} from '../actions/players-list';
import {Player} from '../models/player';

export interface PlayersListState {
  showFilter: boolean;
  array: Player[];
  count: number;
  downloading: boolean;
}

const initialState: PlayersListState = {
  showFilter: false,
  array: [],
  count: 0,
  downloading: false
};

// tslint:disable-next-line:typedef
export function playersListReducer(state = initialState, action: Action): PlayersListState {
  switch (action.type) {
    case PlayersListActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case PlayersListActionTypes.CLEAR:
      return handleClearAction(state);
    case PlayersListActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case PlayersListActionTypes.LOAD_VALIDATION_FAIL:
    case PlayersListActionTypes.LOAD_FAIL:
      return handleLoadErrorActions(state);
    case DownloadPlayersActionTypes.DOWNLOAD_START:
      return handleStartDownloadAction(state);
    case DownloadPlayersActionTypes.DOWNLOAD_SUCCESS:
    case DownloadPlayersActionTypes.DOWNLOAD_VALIDATION_FAIL:
    case DownloadPlayersActionTypes.DOWNLOAD_FAIL:
      return handleEndDownloadActions(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: PlayersListState, action: ToggleSearchPlayersAction): PlayersListState {
  const newState: PlayersListState = { showFilter: action.payload, array: state.array, count: state.count, downloading: state.downloading };
  return newState;
}

function handleClearAction(state: PlayersListState): PlayersListState {
  const newState: PlayersListState = { showFilter: state.showFilter, array: [], count: 0, downloading: state.downloading };
  return newState;
}

function handleLoadSuccessAction(state: PlayersListState, action: LoadPlayersSuccessAction): PlayersListState {
  const newState: PlayersListState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count, downloading: state.downloading };
  return newState;
}

function handleLoadErrorActions(state: PlayersListState): PlayersListState {
  const newState: PlayersListState = { showFilter: state.showFilter, array: [], count: 0, downloading: state.downloading };
  return newState;
}

function handleStartDownloadAction(state: PlayersListState): PlayersListState {
  const newState: PlayersListState = { showFilter: state.showFilter, array: state.array, count: state.count, downloading: true };
  return newState;
}

function handleEndDownloadActions(state: PlayersListState): PlayersListState {
  const newState: PlayersListState = { showFilter: state.showFilter, array: state.array, count: state.count, downloading: false };
  return newState;
}

function handleChangeClientAction(): PlayersListState {
  return { showFilter: false, array: [], count: 0, downloading: false };
}

export const playersListSelectors = {
  showFilter: (state: PlayersListState) => _.get(state, 'showFilter', false),
  array: (state: PlayersListState) => _.get(state, 'array', []),
  count: (state: PlayersListState) => _.get(state, 'count', 0),
  downloading: (state: PlayersListState) => _.get(state, 'downloading', false)
};
