import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadPlayerDetailsAction} from '../../players/actions/player-details';
import {BaseEffect, error, SendBackResult, SimpleValue, success} from '../../shared';
import * as fromRoot from '../../shared/reducers';
import {CreatePostponeAction, CreatePostponeActionTypes, CreatePostponeFailAction, CreatePostponeSuccessAction} from '../actions/create-postpone';
import {LoadPlayersIssueAction} from '../actions/players-issue-list';
import * as fromPostpones from '../reducers';
import {PlayersIssueService} from '../services/players-issue.service';

@Injectable()
export class CreatePostponeEffect extends BaseEffect {
  @Effect()
  create$ = this.actions$.pipe(ofType(CreatePostponeActionTypes.CREATE),
                               debug('Create postpone action received.'),
                               map((action: CreatePostponeAction) => action.payload),
                               withLatestFrom(this.store.pipe(select(fromRoot.selectors.getCurrentClient))),
                               switchMap(([payload]) => this.playersIssueService.create(payload).pipe(
                                           switchMap((result: SendBackResult<SimpleValue<string>>) =>
                                                       of(new CreatePostponeSuccessAction(result.data.value), new LoadPlayersIssueAction())),
                                           catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  createSuccess$ = this.actions$.pipe(
    ofType(CreatePostponeActionTypes.CREATE_SUCCESS),
    debug('Create postpone success action received.'),
    withLatestFrom(this.store.pipe(select(fromRoot.selectors.getMyRoles)), this.store.pipe(select(fromRoot.selectors.getCurrentClient))),
    map(() => success(this.translation.translate('PLAYERS_ISSUE_POSTPONED_CREATE_SUCCESS'), this.translation.translate('TOAST_SUCCESS_TITLE'))));

  @Effect()
  createFail$ =
    this.actions$.pipe(ofType(CreatePostponeActionTypes.CREATE_FAIL),
                       debug('A server error occurred while creating a new postpone.'),
                       map(() => error(this.translation.translate('PLAYERS_ISSUE_POSTPONED_CREATE_FAILED'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private store: Store<fromPostpones.AppState>,
              private translation: L10nTranslationService,
              private playersIssueService: PlayersIssueService,
              router: Router) {
    super(router);
  }
  // @ts-ignore
  protected handleBadRequest(response: Response): Action { return new CreatePostponeFailAction(); }

  protected handleUnhandledError(response: Response): Action { return new CreatePostponeFailAction(response.status); }
}
