/**
 * An interface defining the configuration options for the `saveAs` function.
 */
export interface SaveOptions {
  /**
   * If set to `true`, the content is forwarded to `proxyURL` even if the browser supports file saving locally.
   * The default value is `false`.
   */
  forceProxy?: boolean;

  /**
   * The URL of the server side proxy which streams the file to the end user.
   * When the browser is not capable of saving files locally, a proxy is used.
   * Such browsers are Internet Explorer version 9 and earlier, and Safari.
   * It is the developer who is responsible for the implementation of the server-side proxy.
   *
   * The proxy receives a `POST` request with the following parameters in the request body:
   * * `contentType`&mdash;The MIME type of the file.
   * * `base64`&mdash;The base-64 encoded file content.
   * * `fileName`&mdashh;The file name as requested by the caller.
   * * Additional fields set through the optional `proxyData`.
   *
   * The proxy returns the decoded file with a set `"Content-Disposition"` header.
   */
  proxyURL?: string;

  /**
   * A name or keyword indicating where to display the document returned by the proxy. The default value is `_self`.
   * To display the document in a new window or iframe, the proxy has to set the `"Content-Disposition"` header to `inline; filename="<fileName.ext>"`.
   */
  proxyTarget?: string;

  /**
   * A key/value dictionary of form values to send to the proxy.
   * Can be used to submit Anti-Forgery tokens and other metadata.
   */
  proxyData?: { [key: string]: string };
}

const anchor = () => document.createElement('a');
const canDownload = () => 'download' in anchor();

/**
 * Prompts the user to save the base-64 encoded data as a file with the specified name.
 *
 * @param {string} data &mdash; The file contents encoded as a [Data URI](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URIs).
 * Only base64-encoded Data URIs are supported.
 * @param {string} fileName &mdash; The desired file name.
 * @param {SaveOptions} options &mdash; An optional proxy configuration to use during the file-saving operation.
 *
 */
export function saveAs(data: string, fileName: string, options?: SaveOptions): void {
  if (!options) {
    options = {};
  }

  let save: Function = postToProxy;

  if (options.forceProxy && !options.proxyURL) {
    throw new Error('No proxyURL is set, but forceProxy is true.');
  }

  if (!options.forceProxy) {
    if (canDownload()) {
      save = saveAsDataURI;
    }

    if (navigator.msSaveBlob) {
      save = saveAsBlob;
    }
  }

  save(data, fileName, options);
}

function saveAsBlob(data: Blob|string, fileName: string): void {
  let blob = data;

  if (!(data instanceof Blob)) {
    const parts = data.split(';base64,');
    const contentType = parts[0];
    const base64 = atob(parts[1]);
    const array = new Uint8Array(base64.length);

    for (let idx = 0; idx < base64.length; idx++) {
      array[idx] = base64.charCodeAt(idx);
    }

    blob = new Blob([array.buffer], { type: contentType });
  }

  navigator.msSaveBlob(blob, fileName);
}

function saveAsDataURI(data: Blob|string, fileName: string): void {
  let dataURI: string;
  if (window.Blob && data instanceof Blob) {
    dataURI = URL.createObjectURL(data);
  } else {
    dataURI = <string>data;
  }

  const fileSaver = anchor();
  fileSaver.download = fileName;
  fileSaver.href = dataURI;

  const e = document.createEvent('MouseEvent');
  e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, undefined);

  fileSaver.dispatchEvent(e);
  setTimeout(() => URL.revokeObjectURL(dataURI));
}

function postToProxy(dataURI: string, fileName: string, options: SaveOptions): void {
  if (!options.proxyURL) {
    return;
  }

  const form = document.createElement('form');
  form.setAttribute('action', options.proxyURL);
  form.setAttribute('method', 'POST');
  form.setAttribute('target', options.proxyTarget || '_self');

  const formData: any = options.proxyData || {};
  formData.fileName = fileName;

  const parts = dataURI.split(';base64,');
  formData.contentType = parts[0];
  formData.base64 = parts[1];

  for (const name in formData) {
    if (formData.hasOwnProperty(name)) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', name);
      input.setAttribute('value', formData[name]);

      form.appendChild(input);
    }
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
