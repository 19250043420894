import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {PlayLog} from '../models/play-log';
import {PlayLogsFilterForm} from '../models/play-logs-filter-form';

export const PlayLogsActionTypes = {
  TOGGLE_SEARCH: type('[Play Logs] Toggle Search Form'),
  SEARCH: type('[Play Logs] Search'),
  CHANGE_PAGE_SIZE: type('[Play Logs] Change Page Size'),
  PAGE_TO: type('[Play Logs] Page To'),
  ORDER_BY: type('[Play Logs] Order By'),
  LOAD: type('[Play Logs] Load'),
  LOAD_SUCCESS: type('[Play Logs] Load Success'),
  LOAD_FAIL: type('[Play Logs] Load Fail'),
  TOGGLE_PLAY: type('[Play Logs] Toggle Play Audio'),
  CLEAR: type('[Play Logs] Clear Data')
};

export class ToggleSearchPlayLogsAction implements Action {
  readonly type = PlayLogsActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchPlayLogsAction implements Action {
  readonly type = PlayLogsActionTypes.SEARCH;

  constructor(public payload?: PlayLogsFilterForm) {}
}

export class ChangePlayLogsPageSizeAction implements Action {
  readonly type = PlayLogsActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PagePlayLogsToAction implements Action {
  readonly type = PlayLogsActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderPlayLogsByAction implements Action {
  readonly type = PlayLogsActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadPlayLogsAction implements Action {
  readonly type = PlayLogsActionTypes.LOAD;

  constructor(public payload?: boolean) {}
}

export class LoadPlayLogsSuccessAction implements Action {
  readonly type = PlayLogsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<PlayLog>) {}
}

export class LoadPlayLogsFailAction implements Action {
  readonly type = PlayLogsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class TogglePlayLogsPlayAction implements Action {
  readonly type = PlayLogsActionTypes.TOGGLE_PLAY;

  constructor(public payload?: string) {}
}

export class ClearPlayLogsAction implements Action {
  readonly type = PlayLogsActionTypes.CLEAR;

  constructor() {}
}
