import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BodyService {
  addClass(className: string): void { document.body.classList.add(className); }

  setClass(className: string): void { document.body.className = className; }

  toggleClass(className: string): void { document.body.classList.toggle(className); }

  setContentMinHeight(): void {
    let height = this.calculateViewportHeight();
    if (document.body.classList.contains('page-footer-fixed') === false) {
      height = height - (<HTMLElement>document.querySelector('.page-footer')).offsetHeight;
    }

    (<HTMLElement>document.querySelector('.page-content')).style.minHeight = `${height}px`;
  }

  private getViewPort(): { width: number; height: number } {
    // tslint:disable-next-line:no-any
    let obj: any = window, prefix = 'inner';
    if (!('innerWidth' in window)) {
      prefix = 'client';
      obj = document.documentElement || document.body;
    }

    return { width: obj[prefix + 'Width'], height: obj[prefix + 'Height'] };
  }

  private calculateViewportHeight(): number {
    let height = this.getViewPort().height - (<HTMLElement>document.querySelector('.page-header')).offsetHeight;
    if (document.body.classList.contains('page-footer-fixed')) {
      height = height - (<HTMLElement>document.querySelector('.page-footer')).offsetHeight;
    }

    return height;
  }
}
