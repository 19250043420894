import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Player} from '../../players/models/player';
import {
  AppSettingsService,
  ArrayResponse,
  BaseRestService,
  SendBackResult,
} from '../index';

@Injectable({ providedIn: 'root' })
export class TicketService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'TicketService'; }

  protected get isAnonymous(): boolean { return false; }

  createTicket(player: any, description: any): Observable<SendBackResult<ArrayResponse<any>>> {
    if (!player) {
      player = { deviceID: description.deviceID };
    }
    return this.post<ArrayResponse<any>>(`/Player/${player.deviceID}/CreateSalesforceTicket`, description);
  }
  createTicketMulti(form: any): Observable<SendBackResult<ArrayResponse<any>>> {
    return this.post<ArrayResponse<any>>(`/Player/BulkCreateSalesforceTicket`, form);
  }
}
