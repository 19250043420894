import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {Country} from '../models/country';

export const CountriesActionTypes = {
  LOAD: type('[Countries] Load'),
  LOAD_SUCCESS: type('[Countries] Load Success'),
  LOAD_FAIL: type('[Countries] Load Fail')
};

export class LoadCountriesAction implements Action {
  readonly type = CountriesActionTypes.LOAD;

  constructor() {}
}

export class LoadCountriesSuccessAction implements Action {
  readonly type = CountriesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Country[]) {}
}

export class LoadCountriesFailAction implements Action {
  readonly type = CountriesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
