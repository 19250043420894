import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {clientConfigurationActionTypes, LoadClientConfigurationSuccessAction} from '..';

export interface ClientConfigurationState {
  array: any[];
}

const initialState: ClientConfigurationState = {
  array: []
};

export function clientConfigurationReducer(state: ClientConfigurationState = initialState, action: Action): ClientConfigurationState {
  switch (action.type) {
    case clientConfigurationActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: ClientConfigurationState, action: LoadClientConfigurationSuccessAction): ClientConfigurationState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

export const clientConfigurationSelectors = {
  array: (state: ClientConfigurationState) => _.get(state, 'array', [])
};
