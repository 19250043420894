import {Action} from '@ngrx/store';

import {Product, type} from '../../shared';

export const ProductsActionTypes = {
  LOAD: type('[Player Products] Load'),
  LOAD_SUCCESS: type('[Player Products] Load Success'),
  LOAD_FAIL: type('[Player Products] Load Fail')
};

export class LoadProductsAction implements Action {
  readonly type = ProductsActionTypes.LOAD;

  constructor() {}
}

export class LoadProductsSuccessAction implements Action {
  readonly type = ProductsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Product[]) {}
}

export class LoadProductsFailAction implements Action {
  readonly type = ProductsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
