import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadScreenShotSuccessAction, ScreenShotActionTypes} from '../actions/screen-shot';

export interface ScreenShotState {
  array: any[];
}

const initialState: ScreenShotState = {
  array: []
};

export function screenShotReducer(state = initialState, action: Action): ScreenShotState {
  switch (action.type) {
    case ScreenShotActionTypes.LOAD:
      return handleLoadAction();
    case ScreenShotActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ScreenShotActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): ScreenShotState { return initialState; }

function handleLoadSuccessAction(state: ScreenShotState, action: LoadScreenShotSuccessAction): ScreenShotState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: ScreenShotState): ScreenShotState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const screenShotSelectors = {
  array: (state: ScreenShotState) => _.get(state, 'array', [])
};
