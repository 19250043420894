import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {AudioScheduledMessage} from '../models/audio-scheduled-message';
import {AudioScheduledMessagesFilterForm} from '../models/audio-scheduled-messages-filter-form';

export const AudioScheduledMessagesActionTypes = {
  TOGGLE_SEARCH: type('[Audio Scheduled Messages] Toggle Search Form'),
  SEARCH: type('[Audio Scheduled Messages] Search'),
  CHANGE_PAGE_SIZE: type('[Audio Scheduled Messages] Change Page Size'),
  PAGE_TO: type('[Audio Scheduled Messages] Page To'),
  ORDER_BY: type('[Audio Scheduled Messages] Order By'),
  LOAD: type('[Audio Scheduled Messages] Load'),
  LOAD_SUCCESS: type('[Audio Scheduled Messages] Load Success'),
  LOAD_FAIL: type('[Audio Scheduled Messages] Load Fail'),
  TOGGLE_PLAY: type('[Audio Scheduled Messages] Toggle Play'),
  CLEAR: type('[Audio Scheduled Messages] Clear Data')
};

export class ToggleSearchAudioScheduledMessagesAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchAudioScheduledMessagesAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.SEARCH;

  constructor(public payload?: AudioScheduledMessagesFilterForm) {}
}

export class ChangeAudioScheduledMessagesPageSizeAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageAudioScheduledMessagesToAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderAudioScheduledMessagesByAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadAudioScheduledMessagesAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.LOAD;

  constructor() {}
}

export class LoadAudioScheduledMessagesSuccessAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<AudioScheduledMessage>) {}
}

export class LoadAudioScheduledMessagesFailAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ToggleAudioScheduledMessagesPlayAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.TOGGLE_PLAY;

  constructor(public payload?: string) {}
}

export class ClearAudioScheduledMessagesAction implements Action {
  readonly type = AudioScheduledMessagesActionTypes.CLEAR;

  constructor() {}
}
