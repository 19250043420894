import {L10nConfig, L10nLoader} from 'angular-l10n';
import {i18nAsset} from '../assets/locales/local';

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  providers: [{name: 'app', asset: i18nAsset}],
  cache: true,
  keySeparator: '.',
  defaultLocale: {
    language: 'en',
  },
  schema: [
    {locale: {language: 'en'}, dir: 'ltr', text: 'English'},
    {locale: {language: 'de'}, dir: 'ltr', text: 'German'},
    {
      locale: {
        language: 'fr',
      },
      dir: 'ltr',
      text: 'French'
    },
  ]
};

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}
