import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {empty, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {
  GotoPlayerAction,
  LoadQuickSearchAction,
  LoadQuickSearchFailAction,
  LoadQuickSearchSuccessAction,
  LoadQuickSearchValidationFailAction,
  QuickSearchActionType
} from '../actions/quick-search';
import {go} from '../actions/router';
import {error, warning} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {QuickSearchResult} from '../models/quick-search-result';
import {SendBackResult} from '../models/send-back-result';
import * as fromShared from '../reducers';
import {QuickSearchService} from '../services/quick-search.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class QuickSearchEffect extends BaseEffect {
  @Effect()
  applyActions$ = this.actions$.pipe(
    ofType(QuickSearchActionType.SEARCH, QuickSearchActionType.PAGE_TO, QuickSearchActionType.CHANGE_PAGE_SIZE, QuickSearchActionType.ORDER_BY),
    debug('Quick search action received.'),
    map(() => new LoadQuickSearchAction(true)));

  @Effect()
  load$ = this.actions$.pipe(ofType(QuickSearchActionType.LOAD),
                             debug('Perform quick search.'),
                             withLatestFrom(this.store.select(fromShared.selectors.getQuickSearchFilter)),
                             switchMap(([action, filter]) => this.quickSearchService.getList(filter).pipe(
                                         map((payload: SendBackResult<ArrayResponse<QuickSearchResult>>) => new LoadQuickSearchSuccessAction(payload.data)),
                                         catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadSuccess$ =
    this.actions$.pipe(ofType(QuickSearchActionType.LOAD_SUCCESS), debug('Quick search result.'), switchMap((action: LoadQuickSearchSuccessAction) => {
                         switch (action.payload.count) {
                           case 0:
                             return of(warning(this.translation.translate('QUICK_SEARCH_NO_RESULT'), this.translation.translate('TOAST_WARNING_TITLE')));
                           case 1:
                             return of(new GotoPlayerAction(action.payload.array[0]));
                           default:
                             return empty();
                         }
                       }));

  @Effect()
  loadFailValidation$ =
    this.actions$.pipe(ofType(QuickSearchActionType.LOAD_VALIDATION_FAIL),
                       debug('User made a typo in quick search.'),
                       map(() => error(this.translation.translate('QUICK_SEARCH_BAD_REQUEST_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(QuickSearchActionType.LOAD_FAIL),
                                 debug('A server error occurred while quick searching.'),
                                 map(() => error(this.translation.translate('QUICK_SEARCH_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  @Effect()
  gotoPlayer$ = this.actions$.pipe(ofType(QuickSearchActionType.GOTO_PLAYER),
                                   debug('Go to player details action received.'),
                                   map((action: GotoPlayerAction) => go(['/', action.payload.clientUuid, 'players', action.payload.deviceID])));

  constructor(private actions$: Actions,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService,
              private quickSearchService: QuickSearchService,
              router: Router) {
    super(router);
  }

  protected handleBadRequest(response: Response): Action { return new LoadQuickSearchValidationFailAction(); }

  protected handleUnhandledError(response: Response): Action { return new LoadQuickSearchFailAction(response.status); }
}
