import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {CulturesActionTypes, LoadCulturesFailAction, LoadCulturesSuccessAction} from '../actions/cultures';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {Culture} from '../models/culture';
import {SendBackResult} from '../models/send-back-result';
import {CulturesService} from '../services/cultures.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class CulturesEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(ofType(CulturesActionTypes.LOAD),
                             debug('Load cultures action received;'),
                             switchMap(() => this.culturesService.getList().pipe(map((payload: SendBackResult<ArrayResponse<Culture>>) =>
                                                                                       new LoadCulturesSuccessAction(_.orderBy(payload.data.array, ['code']))),
                                                                                 catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(CulturesActionTypes.LOAD_FAIL),
                                 debug('Load cultures fail action received.'),
                                 map(() => error(this.translation.translate('CULTURES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private culturesService: CulturesService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadCulturesFailAction(response.status); }
}
