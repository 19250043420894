import {Action} from '@ngrx/store';

import {type} from '../../shared';

export const ScreenShotActionTypes = {
  LOAD: type('[Screen Shot] Load'),
  LOAD_SUCCESS: type('[Screen Shot] Load Success'),
  LOAD_FAIL: type('[Screen Shot] Load Fail')
};

export class LoadScreenShotAction implements Action {
  readonly type = ScreenShotActionTypes.LOAD;

  constructor(public payload?: any) {}
}

export class LoadScreenShotSuccessAction implements Action {
  readonly type = ScreenShotActionTypes.LOAD_SUCCESS;

  constructor(public payload?: any[]) {}
}

export class LoadScreenShotFailAction implements Action {
  readonly type = ScreenShotActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
