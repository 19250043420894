import {isDevMode} from '@angular/core';
import * as moment from 'moment';

export abstract class BaseService {
  // tslint:disable-next-line:no-any
  protected log(message?: any, ...optionalParams: any[]): void {
    if (isDevMode()) {
      // tslint:disable-next-line:no-console
      console.log(`[${this.getTimestamp()}] ${message}`, optionalParams);
    }
  }
  // tslint:disable-next-line:no-any
  protected warn(message?: any, ...optionalParams: any[]): void { console.warn(`[${this.getTimestamp()}] ${message}`, optionalParams); }
  // tslint:disable-next-line:no-any
  protected error(message?: any, ...optionalParams: any[]): void { console.error(`[${this.getTimestamp()}] ${message}`, optionalParams); }

  private getTimestamp(): string {
    const now = moment();
    return now.format('DD/MM/YYYY HH:mm:ss.SSS');
  }
}
