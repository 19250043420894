import {Action} from '@ngrx/store';

import {AlertPlayersDeconnect} from '../../players/models/alert-player-deconnect';
import {type} from '../utilities/type';

export const AlertPlayerDeconnectActionTypes = {
  LOAD: type('[Alerts Players disconnect] Load'),
  LOAD_SUCCESS: type('[Alerts Players disconnect] Load Success'),

  EDIT: type('[Alerts Players disconnect] EDIT'),
  EDIT_SUCCESS: type('[Alerts Players disconnect] EDIT Success'),

  ADD: type('[Alerts Players disconnect] ADD'),

  SAVE: type('[Alerts Players disconnect] SAVE'),
  SAVE_SUCCESS: type('[Alerts Players disconnect] SAVE Success'),

  DELETE: type('[Alerts Players disconnect] DELETE'),
  DELETE_SUCCESS: type('[Alerts Players disconnect] DELETE Success'),

  DELETE_ADDED_SUCCESS: type('[Alerts Players disconnect] DELETE Added Success'),

  TEST: type('[Alerts Players disconnect] TEST'),
  TEST_SUCCESS: type('[Alerts Players disconnect] TEST Success'),

  FAIL: type('[Alerts Players disconnect]  Fail'),
};

export class LoadAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.LOAD;
  constructor() {}
}
export class LoadAlertPlayerDeconnectSuccessAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.LOAD_SUCCESS;
  constructor(public payload: AlertPlayersDeconnect[]) {}
}

export class SaveAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.SAVE;
  constructor() {}
}
export class SaveAlertPlayerDeconnectSuccessAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.SAVE_SUCCESS;
  constructor(public payload: AlertPlayersDeconnect[]) {}
}

export class EditAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.EDIT;
  constructor(public payload: AlertPlayersDeconnect) {}
}
export class EditAlertPlayerDeconnectSuccessAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.EDIT_SUCCESS;
  constructor(public payload: AlertPlayersDeconnect[]) {}
}

export class AddAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.ADD;
  constructor() {}
}

export class DeleteAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.DELETE;
  constructor(public payload: number) {}
}
export class DeleteAlertPlayerDeconnectSuccessAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.DELETE_SUCCESS;
  constructor(public payload: any) {}
}

export class TestAlertPlayerDeconnectAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.TEST;
  constructor(public payload: number) {}
}
export class TestAlertPlayerDeconnectSuccessAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.TEST_SUCCESS;
  constructor(public payload: any) {}
}

export class AlertPlayerDeconnectFailAction implements Action {
  readonly type = AlertPlayerDeconnectActionTypes.FAIL;
  constructor(public payload?: number) {}
}
