import {Directive, ElementRef, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SetOptions} from 'eonasdan-bootstrap-datetimepicker';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';

@Directive({
  // tslint:disable:directive-selector
  selector: '[dateTimePicker]',
  // tslint:enable:directive-selector
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimePickerDirective), multi: true }]
})
export class DateTimePickerDirective implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() dateTimePicker: SetOptions;
  @Input() minDate: moment.Moment|Date|string|false;
  @Input() maxDate: moment.Moment|Date|string|false;
  @Input() disabledDates: (moment.Moment|Date|string)[];
  date: moment.Moment|Date|string|null = null;
  // tslint:disable-next-line:no-any
  private dpElement: any;
  // tslint:disable-next-line:no-any
  private onChange: any;
  // tslint:disable-next-line:no-any
  private onTouched: any;

  constructor(private el: ElementRef) {
    const $parent = $(el.nativeElement.parentElement);
    this.dpElement = $parent.hasClass('input-group') ? $parent : $(el.nativeElement);
  }

  ngOnInit(): void {
    this.dpElement.datetimepicker(this.dateTimePicker);
    this.dpElement.data('DateTimePicker').date(this.date);
    this.dpElement.on('dp.change', e => {
      if (e.date !== this.date) {
        this.date = e.date;
        if (this.onChange) {
          this.onChange(e.date);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dpe = this.dpElement.data('DateTimePicker');
    if (dpe) {
      if (_.has(changes, 'dateTimePicker')) {
        const options = _.get(changes, 'dateTimePicker.currentValue');
        _.forOwn(options, (value, key) => { dpe[key](value); });
      }

      if (_.has(changes, 'minDate')) {
        const minDate = _.get(changes, 'minDate.currentValue');
        dpe.minDate(minDate);
      }

      if (_.has(changes, 'maxDate')) {
        const maxDate = _.get(changes, 'maxDate.currentValue');
        dpe.maxDate(maxDate);
      }

      if (_.has(changes, 'disabledDates')) {
        const disabledDates = _.get(changes, 'disabledDates.currentValue');
        dpe.disabledDates(disabledDates);
      }
    }
  }

  ngOnDestroy(): void {
    const dpe = this.dpElement.data('DateTimePicker');
    if (dpe) {
      dpe.destroy();
    }
  }

  // tslint:disable-next-line:no-any
  writeValue(value: any): void {
    const dpe = this.dpElement.data('DateTimePicker');
    if (dpe) {
      dpe.date(value);
    }
  }

  // tslint:disable-next-line:no-any
  registerOnChange(fn: any): void { this.onChange = fn; }

  // tslint:disable-next-line:no-any
  registerOnTouched(fn: any): void { this.onTouched = fn; }

  @HostListener('blur')
  onBlur(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
