import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {EventLogsActionTypes, LoadEventLogsSuccessAction, ToggleSearchEventLogsAction} from '../actions/event-logs';
import {ExtendedEventLog} from '../models/extended-event-log';

export interface EventLogsState {
  showFilter: boolean;
  array: ExtendedEventLog[];
  count: number;
  metadataColumns: string[];
}

const initialState: EventLogsState = {
  showFilter: false,
  array: [],
  count: 0,
  metadataColumns: []
};

export function eventLogsReducer(state = initialState, action: Action): EventLogsState {
  switch (action.type) {
    case EventLogsActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case EventLogsActionTypes.CLEAR:
      return handleClearAction(state);
    case EventLogsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case EventLogsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: EventLogsState, action: ToggleSearchEventLogsAction): EventLogsState {
  const newState: EventLogsState = { showFilter: action.payload, array: state.array, count: state.count, metadataColumns: state.metadataColumns };
  return newState;
}

function handleClearAction(state: EventLogsState): EventLogsState {
  const newState: EventLogsState = { showFilter: state.showFilter, array: [], count: 0, metadataColumns: [] };
  return newState;
}

function handleLoadSuccessAction(state: EventLogsState, action: LoadEventLogsSuccessAction): EventLogsState {
  // clang-format off
  const newState: EventLogsState = {
    showFilter: state.showFilter,
    array: action.payload.array,
    count: action.payload.count,
    metadataColumns: getMetadataColumns(action.payload.array)
  };
  // clang-format on
  return newState;
}

function getMetadataColumns(array: ExtendedEventLog[]): string[] {
  if (_.isEmpty(array)) {
    return [];
  }
  let columns;

  const fixedColumns = ['_name', 'time', 'type', 'source'];
  // const heartbeat = _.filter(array, item => item._name === 'Heartbeat');
  // console.log(heartbeat)
  /* if (heartbeat.length > 0) {
     // columns = _.keys(heartbeat[0]);
     columns = ['Metadata']
   }
   else {*/
  const firstRow = _.first(array);
  columns = _.keys(firstRow);
  columns.push('Metadata');
  // }

  return _.difference(columns, fixedColumns);
}

function handleLoadFailAction(state: EventLogsState): EventLogsState {
  const newState: EventLogsState = { showFilter: state.showFilter, array: [], count: 0, metadataColumns: [] };
  return newState;
}

function handleChangeClientAction(): EventLogsState {
  return { showFilter: false, array: [], count: 0, metadataColumns: [] };
}

export const eventLogsSelectors = {
  showFilter: (state: EventLogsState) => _.get(state, 'showFilter', false),
  array: (state: EventLogsState) => _.get(state, 'array', []),
  count: (state: EventLogsState) => _.get(state, 'count', 0),
  metadataColumns: (state: EventLogsState) => _.get(state, 'metadataColumns', [])
};
