import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {error} from '../actions/toasts';
import {LoadRolesAction, LoadRolesServerErrorAction, LoadRolesSuccessAction, UserContextActionTypes} from '../actions/user-context';
import {ArrayResponse} from '../models/array-response';
import {Role} from '../models/role';
import {SendBackResult} from '../models/send-back-result';
import {UserContextService} from '../services/user-context.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class UserContextRolesEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(UserContextActionTypes.LOAD_ROLES),
    debug('Load roles action received'),
    switchMap((action: LoadRolesAction) => this.userContextService.loadRoles(action.payload)
                                             .pipe(map((payload: SendBackResult<ArrayResponse<Role>>) => new LoadRolesSuccessAction(payload.data.array)),
                                                   catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(UserContextActionTypes.LOAD_ROLES_FAIL),
                                 debug('A server error occurred while retrieving roles.'),
                                 map(() => error(this.translation.translate('USER_CONTEXT_GET_ROLES_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private userContextService: UserContextService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadRolesServerErrorAction(response.status); }
}
