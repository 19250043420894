import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {DefaultRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {L10nIntlModule, L10nLoader, L10nTranslationModule} from 'angular-l10n';
import * as _ from 'lodash';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {environment} from '../environments/environment';

import {GroupRolesExService} from './admin/services/group-roles-ex.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {ErrorModule} from './error/error.module';
import {initL10n, l10nConfig} from './i10n-config';
import {RedirectComponent} from './redirect.component';
import {RouterStateUrl, SharedModule} from './shared';
import * as sharedEffects from './shared/effects';
import {SfTicketDetailEffect} from './shared/effects';
import {CreteTicketSFEffect} from './shared/effects/create-ticket-sf.effect';
import {reducers} from './shared/reducers';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    return {
      url: routerState.url,
      params: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data: mergeRouteData(routerState.root)
    };
  }
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
  if (!route) {
    return {};
  }

  const currentParams = getter(route);
  const primaryChild = _.find(route.children, c => c.outlet === 'primary') || route.firstChild;
  return Object.assign({}, currentParams, mergeRouteParams(primaryChild, getter));
}

function mergeRouteData(route: ActivatedRouteSnapshot): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = _.find(route.children, c => c.outlet === 'primary') || route.firstChild;
  return Object.assign({}, currentData, mergeRouteData(primaryChild));
}
// const l10nConfig: L10nConfig = {
//   locale: {
//     languages: [{ code: 'en', dir: 'ltr' }, { code: 'fr', dir: 'ltr' }, { code: 'de', dir: 'ltr' }, { code: 'nl', dir: 'ltr' }],
//     defaultLocale: { languageCode: 'en', countryCode: 'US' },
//     currency: 'USD'
//   },
//   // translation: { providers: [{ type: ProviderType.Static, prefix: './assets/locales/locale-' }], caching: true, missingValue: 'No key' }
//   // TODO: decomment in prod
//   translation: { providers: [{ type: ProviderType.Static, prefix: './assets/locales/locale-' }], caching: false, version: environment.appVersion }
// };

@NgModule({
  declarations: [AppComponent, RedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    EffectsModule.forRoot([
      sharedEffects.AlertsEffect,
      sharedEffects.CulturesEffect,
      sharedEffects.CurrentClientEffect,
      sharedEffects.ExternalLinksEffect,
      sharedEffects.GroupRolesEffect,
      sharedEffects.GroupRolesTemplatesEffect,
      sharedEffects.ImpersonableUsersEffect,
      sharedEffects.MyClientsEffect,
      sharedEffects.QuickSearchEffect,
      sharedEffects.RouterEffects,
      sharedEffects.TimezonesEffect,
      sharedEffects.ToastsEffect,
      sharedEffects.UserContextClientEffect,
      sharedEffects.UserContextImpersonationEffect,
      sharedEffects.UserContextRolesEffect,
      sharedEffects.UserInfoEffect,
      sharedEffects.AlertsPlayerConnectEffect,
      sharedEffects.OfficesEffect,
      sharedEffects.ClientConfigurationEffect,
      sharedEffects.PreferencesEffect,
      sharedEffects.ProductionLineEffect,
      sharedEffects.CreatePostponeEffect,
      sharedEffects.CreteTicketSFEffect,
      sharedEffects.SfTicketDetailEffect,
    ]),
    ErrorModule,
    HttpClientModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    SharedModule.forRoot(),
    L10nTranslationModule.forRoot(l10nConfig),
    L10nIntlModule,
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    ToastModule,
    TypeaheadModule.forRoot()

    // ToastrModule.forRoot({
    //   timeOut: 10000,
    //   positionClass: 'toast-top-right',
    //   preventDuplicates: true,
    // }),
  ],
  providers: [
    GroupRolesExService,
    Title,
    MessageService,
    ConfirmationService,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: APP_INITIALIZER, useFactory: initL10n, deps: [L10nLoader], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(public l10nLoader: L10nLoader) { this.l10nLoader.load(); }
  constructor() {}
}
