import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {ACCESS_TOKEN, RETURN_URL} from '../consts';
import {tokenNotExpired} from '../utilities/token-not-expired';

@Injectable()
export class MustBeAuthenticatedGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (tokenNotExpired(ACCESS_TOKEN)) {
      return true;
    }
    if (state.url !== '/login' && state.url !== '/dashboard') {
      localStorage.setItem(RETURN_URL, state.url);
    }
    this.router.navigate(['/login']);
    return false;
  }
}
