import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {SharedModule} from '../shared.module';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: SharedModule })
export class ProductionLineService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'ProductionLineService'; }

  protected get isAnonymous(): boolean { return false; }

  // tslint:disable-next-line:no-any
  getList(): Observable<SendBackResult<ArrayResponse<any>>> { return this.get<ArrayResponse<any>>(`/ProductionLines`); }
}
