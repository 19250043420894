import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {concat, empty, of} from 'rxjs';
import {catchError, delay, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadMyClientsAction} from '../actions/my-clients';
import {go} from '../actions/router';
import {error, success} from '../actions/toasts';
import {
  ClearImpersonationAction,
  LoadRolesAction,
  StartImpersonationAction,
  StartImpersonationServerErrorAction,
  StartImpersonationSuccessAction,
  StopImpersonationAction,
  UserContextActionTypes
} from '../actions/user-context';
import {IMPERSONATION_DURATION, IMPERSONATION_TOKEN} from '../consts';
import {ImpersonateUser} from '../models/impersonate-user';
import * as fromShared from '../reducers';
import {UserContextService} from '../services/user-context.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class UserContextImpersonationEffect extends BaseEffect {
  @Effect()
  start$ = this.actions$.pipe(ofType(UserContextActionTypes.START_IMPERSONATION),
                              debug('Start impersonation action received'),
                              map((action: StartImpersonationAction) => action.payload),
                              withLatestFrom(this.store.select(fromShared.selectors.getCurrentClient)),
                              switchMap(([payload, client]) => this.userContextService.createImpersonationToken(client.uuid, payload, IMPERSONATION_DURATION)
                                                                 .pipe(map((result: ImpersonateUser) => new StartImpersonationSuccessAction(result)),
                                                                       catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  startSuccess$ = this.actions$.pipe(
    ofType(UserContextActionTypes.START_IMPERSONATION_SUCCESS),
    debug('Start impersonation success action received'),
    map((action: StartImpersonationSuccessAction) => action.payload),
    tap(payload => sessionStorage.setItem(IMPERSONATION_TOKEN, payload.token)),
    withLatestFrom(this.store.select(fromShared.selectors.getCurrentClient)),
    switchMap(([payload, client]) => concat(
                of(new LoadRolesAction(client.uuid),
                   new LoadMyClientsAction(),
                   go(['/', client.uuid, 'dashboard']),
                   success(this.translation.translate('USER_CONTEXT_IMPERSONATE_SUCCESS', { username: payload.name, duration: IMPERSONATION_DURATION / 60 }),
                           this.translation.translate('TOAST_SUCCESS_TITLE'))),
                of(new StopImpersonationAction()).pipe(delay(IMPERSONATION_DURATION * 1000)))));

  @Effect()
  startFail$ =
    this.actions$.pipe(ofType(UserContextActionTypes.START_IMPERSONATION_FAIL),
                       debug('A server error occurred while impersonating another user.'),
                       map(() => error(this.translation.translate('USER_CONTEXT_IMPERSONATE_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  @Effect()
  stop$ = this.actions$.pipe(ofType(UserContextActionTypes.STOP_IMPERSONATION),
                             debug('Stop impersonation action received'),
                             withLatestFrom(this.store.select(fromShared.selectors.getImpersonateUser)),
                             switchMap(([action, impersonateUser]) => (impersonateUser == undefined ? empty() : of(new ClearImpersonationAction()))));

  @Effect()
  clear$ =
    this.actions$.pipe(ofType(UserContextActionTypes.CLEAR_IMPERSONATION),
                       debug('Clear impersonation action received'),
                       tap(() => sessionStorage.removeItem(IMPERSONATION_TOKEN)),
                       withLatestFrom(this.store.select(fromShared.selectors.getCurrentClient)),
                       switchMap(([action, client]) => of(new LoadRolesAction(client.uuid), new LoadMyClientsAction(), go(['/', client.uuid, 'dashboard']))));

  constructor(private actions$: Actions,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService,
              private userContextService: UserContextService,
              router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new StartImpersonationServerErrorAction(response.status); }
}
