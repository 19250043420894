import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {PlayersFilterForm} from '../models/players-filter-form';

export const DownloadPlayersActionTypes = {
  DOWNLOAD: type('[Players] Download'),
  DOWNLOAD_START: type('[Players] Download Start'),
  DOWNLOAD_SUCCESS: type('[Players] Download Success'),
  DOWNLOAD_VALIDATION_FAIL: type('[Players] Download Validation Fail'),
  DOWNLOAD_FAIL: type('[Players] Download Fail'),

  SEND_BY_EMAIL: type('[Players] Send by email'),
  SEND_BY_EMAIL_SUCCESS: type('[Players] Send by email Success')
};

export class DownloadPlayersAction implements Action {
  readonly type = DownloadPlayersActionTypes.DOWNLOAD;
  constructor(public payload?: PlayersFilterForm) {}
}

export class StartDownloadPlayersAction implements Action {
  readonly type = DownloadPlayersActionTypes.DOWNLOAD_START;
  constructor(public payload: any) {}
}

export class DownloadPlayersSuccessAction implements Action {
  readonly type = DownloadPlayersActionTypes.DOWNLOAD_SUCCESS;
  constructor(public payload?: string) {}
}

export class DownloadPlayersValidationFailAction implements Action {
  readonly type = DownloadPlayersActionTypes.DOWNLOAD_VALIDATION_FAIL;
  constructor() {}
}

export class DownloadPlayersFailAction implements Action {
  readonly type = DownloadPlayersActionTypes.DOWNLOAD_FAIL;
  constructor(public payload?: number) {}
}

export class SendPlayersAction implements Action {
  readonly type = DownloadPlayersActionTypes.SEND_BY_EMAIL;
  constructor(public payload?: PlayersFilterForm) {}
}

export class SendPlayersSuccessAction implements Action {
  readonly type = DownloadPlayersActionTypes.SEND_BY_EMAIL_SUCCESS;
  constructor(public payload: any) {}
}
