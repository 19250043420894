import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {SendBackResult} from '../../shared/models/send-back-result';
import {SendBackResultCode} from '../../shared/models/send-back-result-code.enum';
import {AnonymousUserService} from '../services/anonymous-user.service';

@Injectable()
export class OtpGuard implements CanActivate {
  constructor(private router: Router, private userService: AnonymousUserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isOtpAuthorized(next.queryParams['email'], next.queryParams['code'])
      .pipe(debug('OTP validation result'),
            map((result: SendBackResult<{}>) => result.statusCode === SendBackResultCode.Success),
            catchError(error => of(false)),
            tap(success => {
              if (!success) {
                this.router.navigate(['/lost-password', next.queryParams['email']], { replaceUrl: true });
              }
            }))
  }
}
