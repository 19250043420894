import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ArrayResponse, SendBackResult} from '..';
import {Preference} from '../models/preferences';
import {SharedModule} from '../shared.module';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: SharedModule })
export class PreferencesService extends BaseRestService {

  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'PreferencesService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(): Observable<SendBackResult<ArrayResponse<Preference>>> {
    // return this.get<any>(`/users/me/settings`).pipe(map(elem =>  elem.data.array.filter(data =>  data.name === name )) );
    return this.get<ArrayResponse<Preference>>(`/users/me/settings`);
  }

  set(pref: Preference): Observable<SendBackResult<ArrayResponse<Preference>>> { return this.post<ArrayResponse<Preference>>(`/users/me/settings`, pref); }
}
