import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {AudioPlanning} from '../models/audio-planning';
import {AudioPlanningFilterForm} from '../models/audio-planning-filter-form';

export const AudioPlanningsActionTypes = {
  TOGGLE_SEARCH: type('[Audio Planning] Toggle Search Form'),
  SEARCH: type('[Audio Planning] Search'),
  CHANGE_PAGE_SIZE: type('[Audio Planning] Change Page Size'),
  PAGE_TO: type('[Audio Planning] Page To'),
  ORDER_BY: type('[Audio Planning] Order By'),
  LOAD: type('[Audio Planning] Load'),
  LOAD_SUCCESS: type('[Audio Planning] Load Success'),
  LOAD_FAIL: type('[Audio Planning] Load Fail'),
  TOGGLE_PLAY: type('[Audio Planning] Toggle Play'),
  CLEAR: type('[Audio Planning] Clear Data')
};

export class ToggleSearchAudioPlanningsAction implements Action {
  readonly type = AudioPlanningsActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchAudioPlanningsAction implements Action {
  readonly type = AudioPlanningsActionTypes.SEARCH;

  constructor(public payload?: AudioPlanningFilterForm) {}
}

export class ChangeAudioPlanningsPageSizeAction implements Action {
  readonly type = AudioPlanningsActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageAudioPlanningsToAction implements Action {
  readonly type = AudioPlanningsActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderAudioPlanningsByAction implements Action {
  readonly type = AudioPlanningsActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadAudioPlanningsAction implements Action {
  readonly type = AudioPlanningsActionTypes.LOAD;

  constructor() {}
}

export class LoadAudioPlanningsSuccessAction implements Action {
  readonly type = AudioPlanningsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<AudioPlanning>) {}
}

export class LoadAudioPlanningsFailAction implements Action {
  readonly type = AudioPlanningsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ToggleAudioPlanningsPlayAction implements Action {
  readonly type = AudioPlanningsActionTypes.TOGGLE_PLAY;

  constructor(public payload?: string) {}
}

export class ClearAudioPlanningsAction implements Action {
  readonly type = AudioPlanningsActionTypes.CLEAR;

  constructor() {}
}
