import {localDe} from './locale-de';
import {localEn} from './locale-en';
import {localEs} from './locale-es';
import {localFr} from './locale-fr';
import {localNl} from './locale-nl';

export const i18nAsset = {
  'en': localEn,
  'de': localDe,
  'es': localEs,
  'fr': localFr,
  'nl': localNl,

}
