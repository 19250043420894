<p-toast position="top-right"></p-toast>
<ng-progress ></ng-progress>
<!-- BEGIN HEADER -->
<div class="page-header navbar navbar-fixed-top">
  <!-- BEGIN HEADER INNER -->
  <div class="page-header-inner ">
    <!-- BEGIN LOGO -->
    <div class="page-logo">
      <a *ngIf="brand$ | async as brand" [routerLink]="dashboardLink$ | async">
        <img width="80"
             [src]="'/assets/images/brands/' + brand.id + '/logo.png'"
             [alt]="brand.name"
             class="logo-default">
<!--        <img width="80"-->
<!--             [src]="'/assets/images/brands/notice/logo.png'"-->
<!--             [alt]="brand.name"-->
<!--             class="logo-default">-->
      </a>
      <div class="menu-toggler sidebar-toggler" (click)="toggleSidebar()">
        <!-- DOC: Remove the above "hide" to enable the sidebar toggler button on header -->
      </div>
    </div>
    <!-- END LOGO -->
    <!-- BEGIN RESPONSIVE MENU TOGGLER -->
    <span class="menu-toggler responsive-toggler" data-toggle="collapse" data-target=".navbar-collapse"> </span>
    <!-- END RESPONSIVE MENU TOGGLER -->
    <!-- BEGIN PAGE TOP -->
    <div class="page-top">
      <div class="top-menu pull-left client-name">
        <h3>{{ 'SITE_TITLE' | translate:locale.language }}</h3>
      </div>
      <!-- BEGIN HEADER CLIENT SELECTION BOX -->
      <div class="client-selector" *ngIf="!isDaryl">
        <div dropdown class="btn-group" #clientDropdown [autoClose]="false" [isDisabled]="!(canChangeClient$ | async)" (onShown)="onShown()">
          <button #clientDropdownToggle dropdownToggle type="button" class="btn btn-client dropdown-toggle" >
            <span>{{ clientName$ | async }}</span> <span *ngIf="canChangeClient$ | async" class="caret"></span>

          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu" >
            <div class="client-filter" *ngIf="isShown">
              <li role="menuitem" >
                <input #clientDropdownFilter type="text" class="form-control" autocomplete="off"
                       [placeholder]="'CLIENT_SELECTOR_PLACEHOLDER' | translate:locale.language"
                       (keyup.enter)="addInputEnter()"
                       [(ngModel)]="currentClientId" autofocus>
              </li>
            </div>
            <div class="client-list">
              <li *ngFor="let client of myClients$ | async | clientsFilter:currentClientId" role="menuitem">
                <a #clientDropdownFiltered [routerLink]="['/', client.uuid, 'dashboard']" class="dropdown-item">{{client.name}}</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <!-- END HEADER CLIENT SELECTION BOX -->
      <storever-top-menu [userInfo]="userInfo$ | async"
                         [canImpersonate]="isInRole$('RUNAS') | async"
                         [impersonatedUser]="impersonatedUser$ | async"
                         [availableLanguages]="availableLanguages"
                         [currentLanguage]="locale.language"
                         (impersonate)="impersonate($event, impersonateUserDialog)"
                         (logout)="logout()"
                         (selectLanguage)="selectLanguage($event)"></storever-top-menu>
      <!-- BEGIN HEADER PLAYER SEARCH BOX -->
      <form class="search-form search-form-expanded pull-right" [class.open]="playerQuickSearchOpen">
        <div class="input-group" >
          <input type="text" name="query" class="form-control" autocomplete="off"
                 [placeholder]="'PLAYER_QUICK_SEARCH_PLACEHOLDER' | translate:locale.language"
                 (focus)="playerQuickSearchFocus()" (blur)="playerQuickSearchBlur()"
                 (keyup)="playerQuickSearchKeyUp($event)" [(ngModel)]="playerQuickSearch">
          <span class="input-group-btn">
            <button type="button" class="btn submit"
                    (click)="quickSearchPlayer($event)"
                    [disabled]="!playerQuickSearch">
              <i class="icon-magnifier"></i>
            </button>
          </span>
        </div>
      </form>
      <!-- END HEADER PLAYER SEARCH BOX -->
    </div>
    <!-- END PAGE TOP -->
  </div>
  <!-- END HEADER INNER -->
</div>
<!-- END HEADER -->
<!-- BEGIN HEADER & CONTENT DIVIDER -->
<div class="clearfix"></div>
<!-- END HEADER & CONTENT DIVIDER -->
<!-- BEGIN CONTAINER -->
<div class="page-container">
  <!-- BEGIN SIDEBAR -->
  <div class="page-sidebar-wrapper">
    <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
    <!-- DOC: Change data-auto-speed="200" to adjust the sub menu slide up/down speed -->
    <div class="page-sidebar navbar-collapse collapse" style="z-index: 1000;" *ngIf="!isDaryl">
      <!-- BEGIN SIDEBAR MENU -->
      <!-- DOC: Apply "page-sidebar-menu-light" class right after "page-sidebar-menu" to enable light sidebar menu style(without borders) -->
      <!-- DOC: Apply "page-sidebar-menu-hover-submenu" class right after "page-sidebar-menu" to enable hoverable(hover vs accordion) sub menu mode -->
      <!-- DOC: Apply "page-sidebar-menu-closed" class right after "page-sidebar-menu" to collapse("page-sidebar-closed" class must be applied to the body element) the sidebar sub menu mode -->
      <!-- DOC: Set data-auto-scroll="false" to disable the sidebar from auto scrolling/focusing -->
      <!-- DOC: Set data-keep-expand="true" to keep the submenues expanded -->
      <!-- DOC: Set data-auto-speed="200" to adjust the sub menu slide up/down speed -->
      <ul class="page-sidebar-menu page-header-fixed page-sidebar-menu-hover-submenu"
          [class.page-sidebar-menu-closed]="sidebarClosed$ | async"
          data-keep-expanded="false"
          data-auto-scroll="true"
          data-slide-speed="200">
        <li class="nav-item start" [routerLinkActive]="['active', 'open']">
          <a [routerLink]="dashboardLink$ | async" class="nav-link nav-toggle">
            <i class="icon-home"></i>
            <span class="title" translate>DASHBOARD</span>
            <span [class.arrow]="!isRouteActive(['/', clientId, 'dashboard'])" [class.selected]="isRouteActive(['/', clientId, 'dashboard'])"></span>
          </a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active', 'open']">
          <a [routerLink]="playersLink$ | async" class="nav-link nav-toggle">
            <i class="icon-screen-desktop"></i>
            <span class="title" translate>PLAYERS</span>
            <span [class.arrow]="!isRouteActive(['/', clientId, 'players'])" [class.selected]="isRouteActive(['/', clientId, 'players'])"></span>
          </a>
        </li>
        <li *ngIf="isInRole$('PORTAL_USERMGR') | async" class="nav-item" [routerLinkActive]="['active', 'open']">
          <a [routerLink]="usersLink$ | async"  class="nav-link nav-toggle">
            <i class="icon-people"></i>
            <span class="title" translate>USERS</span>
            <span [class.arrow]="!isRouteActive(['/', clientId, 'users'])" [class.selected]="isRouteActive(['/', clientId, 'users'])"></span>
          </a>
        </li>
        <li *ngIf="isTicketClientConfiguration && isInRole$('PORTAL_TICKET') | async" class="nav-item" [routerLinkActive]="['active', 'open']">
          <a [routerLink]="ticketsLink$ | async"  class="nav-link nav-toggle">
            <i class="fa fa-ticket"></i>
            <span class="title" translate>TICKETS</span>
            <span [class.arrow]="!isRouteActive(['/', clientId, 'tickets'])"
                  [class.selected]="isRouteActive(['/', clientId, 'tickets'])"></span>
          </a>
        </li>

        <li *ngIf="isInRole$('PORTAL_MUSICPREVIEW') | async" class="nav-item" [routerLinkActive]="['active', 'open']">
          <a [routerLink]="musicPreview$ | async" class="nav-link nav-toggle">
            <i class="icon-music-tone-alt"></i>
            <span class="title" translate>MUSIC_PREVIEW_TITLE</span>
            <span [class.arrow]="!isRouteActive(['/', clientId, 'music-preview'])"
                  [class.selected]="isRouteActive(['/', clientId, 'music-preview'])"></span>

          </a>
        </li>
        <li *ngIf="isInRole$('PORTAL_STOREVER') | async" class="nav-item" [routerLinkActive]="['active', 'open']">
          <a href="javascript:;" class="nav-link nav-toggle">
            <i class="icon-settings"></i>
            <span class="title" translate>ADMIN</span>
            <span [class.arrow]="!isRouteActive(['/admin'])" [class.selected]="isRouteActive(['/admin'])"></span>
          </a>
          <ul class="sub-menu">
            <li class="nav-item" [routerLinkActive]="['active', 'open']">
              <a [routerLink]="['/admin', 'clients']" class="nav-link">
                <span class="title" translate>ADMIN_CLIENTS</span>
                <span *ngIf="isRouteActive(['/admin', 'clients'])" class="selected"></span>
              </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['active', 'open']">
              <a [routerLink]="['/admin', 'alerts']" class="nav-link">
                <span class="title" translate>ADMIN_ALERTS</span>
                <span *ngIf="isRouteActive(['/admin', 'alerts'])" class="selected"></span>
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="hasExternalLinks$ | async" class="nav-item">
          <a href="javascript:;" class="nav-link nav-toggle">
            <i class="icon-options-vertical"></i>
            <span class="title" translate>EXTERNAL_APPS</span>
            <span class="arrow"></span>
          </a>
          <ul class="sub-menu">
            <li *ngFor="let link of externalLinks$ | async" class="nav-item">
              <a [href]="link.url" target="_blank" rel="noreferrer noopener" class="nav-link">
                <span class="title">{{link.name}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- END SIDEBAR MENU -->
    </div>
  </div>
  <!-- END SIDEBAR -->
  <!-- BEGIN CONTENT -->
  <div class="page-content-wrapper">
    <!-- BEGIN CONTENT BODY -->
    <div class="page-content">
      <div class="alert-messages-wrapper">
        <div *ngFor="let msg of alerts$ | async | notDiscarded" class="alert alert-warning alert-dismissable">
          <button type="button" class="close" aria-label="Close" (click)="dismiss(msg.id)">
            <span aria-hidden="true">&times;</span>
          </button>
          <span [innerHTML]="msg.message | message:locale.language | nl2br"></span>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <!-- END CONTENT BODY -->
  </div>
  <!-- END CONTENT -->
</div>
<!-- END CONTAINER -->
<!-- BEGIN FOOTER -->
<div class="page-footer">
  <div *ngIf="brand$ | async as brand" class="page-footer-inner">
    {{copyrightYear}} &copy; <a href="http://mcubedigital.com" target="_blank" rel="noreferrer noopener">Mcube</a>
    <storever-scroll-to-top [isVisible]="scrollToTopVisible"></storever-scroll-to-top>
  </div>
  <div class="page-footer-inner version">
    <span>v{{currentApplicationVersion}}</span>

  </div>
  <div id="container">
    <span><a routerLink="/privacy-policy" fragment="privacy-policy" title="" rel="license">{{'PRIVACY_POLICY' | translate:locale.language}}</a></span>
    <span><a routerLink="/terms-of-use" fragment="terms-of-use" title="" rel="license">{{'TERMS_OF_USE' | translate:locale.language}}</a></span>
  </div>
</div>
<!-- END FOOTER -->
<storever-block-ui></storever-block-ui>
<storever-impersonate-user #impersonateUserDialog
                           [users]="impersonableUsers$ | async"
                           (impersonate)="impersonateUser($event)"
                           (reload)="reloadImpersonableUsers()"></storever-impersonate-user>
<storever-quick-search-results [results]="quickSearchResults$ | async"
                               [count]="quickSearchResultsCount$ | async"
                               [currentPage]="quickSearchResultsPage$ | async"
                               [itemsPerPage]="quickSearchResultsPageSize$ | async"
                               [orderBy]="quickSearchResultsOrderBy$ | async"
                               [showModal]="showQuickSearchResults$ | async"
                               (pageChanged)="quickSearchPageChanged($event)"
                               (pageSizeChanged)="quickSearchPageSizeChanged($event)"
                               (orderByChanged)="quickSearchOrderByChanged($event)"
                               (close)="closeQuickSearchResults()"
                               (gotoPlayer)="gotoPlayer($event)"></storever-quick-search-results>
