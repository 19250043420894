import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {of} from 'rxjs';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {AlertPlayersDeconnect} from '../../players/models/alert-player-deconnect';
import {
  AlertPlayerDeconnectActionTypes,
  AlertPlayerDeconnectFailAction,
  DeleteAlertPlayerDeconnectSuccessAction,
  LoadAlertPlayerDeconnectSuccessAction,
  SaveAlertPlayerDeconnectSuccessAction,
  TestAlertPlayerDeconnectSuccessAction
} from '../actions/alerts-player-deconnect';
import {error} from '../actions/toasts';
import {info} from '../index';
import {SendBackResult} from '../models/send-back-result';
import * as fromShared from '../reducers';
import {AlertsPlayerDeconnectService} from '../services/alerts-player-deconnect.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class AlertsPlayerConnectEffect extends BaseEffect {
  private client$ = this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => !_.isEmpty(client)));
  private alertPlayerDeconnect$ = this.store.select(fromShared.selectors.getAlertsPlayersDeconnect);

  @Effect()
  load$ = this.actions$.pipe(
    ofType(AlertPlayerDeconnectActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Load alerts player disconnect action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.alertsPlayerDeconnectService.getList(client.uuid)
                                      .pipe(map((result: SendBackResult<AlertPlayersDeconnect[]>) => new LoadAlertPlayerDeconnectSuccessAction(result.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  save$ =
    this.actions$.pipe(ofType(AlertPlayerDeconnectActionTypes.SAVE),
                       delayWhen(() => this.client$),
                       debug('Save alerts player disconnect action received.'),
                       withLatestFrom(this.client$, this.alertPlayerDeconnect$),
                       switchMap(([action, client, alerts]) =>
                                   this.alertsPlayerDeconnectService.saveList(client.uuid, alerts)
                                     .pipe(map((result: SendBackResult<AlertPlayersDeconnect[]>) => new SaveAlertPlayerDeconnectSuccessAction(result.data)),
                                           catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  delete$ = this.actions$.pipe(ofType(AlertPlayerDeconnectActionTypes.DELETE),
                               delayWhen(() => this.client$),
                               debug('Delete alerts player disconnect delete action received.'),
                               withLatestFrom(this.client$),
                               switchMap(([action, client]) => this.alertsPlayerDeconnectService.deleteElem(client.uuid, action)
                                                                 .pipe(map(() => new DeleteAlertPlayerDeconnectSuccessAction(action)),
                                                                       catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  test$ = this.actions$.pipe(
    ofType(AlertPlayerDeconnectActionTypes.TEST),
    delayWhen(() => this.client$),
    debug('Test alerts player disconnect delete action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.alertsPlayerDeconnectService.test(client.uuid, action)
                                      .pipe(map((result: SendBackResult<AlertPlayersDeconnect[]>) => new TestAlertPlayerDeconnectSuccessAction(result.data)),
                                            catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  print$ = this.actions$.pipe(
    ofType(AlertPlayerDeconnectActionTypes.TEST_SUCCESS),
    debug('TEST SUCCESS action received.'),
    switchMap(() => of(info(this.translation.translate('ALERT_PLAYERS_TEST_MESSAGE_SEND'), this.translation.translate('TOAST_INFO_TITLE')))));

  @Effect()
  fail$ = this.actions$.pipe(ofType(AlertPlayerDeconnectActionTypes.FAIL),
                             debug('A server error occurred while action alerts player disconnected list.'),
                             map(() => error(this.translation.translate('MY_CLIENTS_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService,
              private alertsPlayerDeconnectService: AlertsPlayerDeconnectService,
              router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new AlertPlayerDeconnectFailAction(response.status); }
}
