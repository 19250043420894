<div bsModal #quickSearchResultsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     [attr.aria-label]="'QUICK_SEARCH_DIALOG' | translate:locale.language" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close.emit()" [attr.aria-label]="'CLOSE' | translate:locale.language">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" translate>QUICK_SEARCH_DIALOG_TITLE</h4>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <storever-datatable [striped]="true"
                              [hover]="true"
                              [data]="results"
                              [count]="count"
                              [currentPage]="currentPage"
                              [itemsPerPage]="itemsPerPage"
                              [orderBy]="orderBy"
                              (pageSizeChange)="pageSizeChanged.emit($event)"
                              (pageChange)="pageChanged.emit($event)"
                              (orderByChange)="orderByChanged.emit($event)">
            <storever-datatable-column [name]="''"
                                       [prop]="'deviceID'">
              <ng-template let-row="row" storeverDatatableColumnCell>
                <button type="button" class="btn brand" (click)="gotoPlayer.emit(row)">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                  {{ 'GOTO_PLAYER' | translate:locale.language }}
                </button>
              </ng-template>
            </storever-datatable-column>
            <storever-datatable-column [name]="'PLAYER_ID' | translate:locale.language"
                                       [prop]="'name'"
                                       [sortable]="true"></storever-datatable-column>
            <storever-datatable-column [name]="'PLAYER_SITE' | translate:locale.language"
                                       [prop]="'subscriptionName'"
                                       [sortable]="true"></storever-datatable-column>
            <storever-datatable-column [name]="'PLAYER_CLIENT' | translate:locale.language"
                                       [prop]="'clientName'"
                                       [sortable]="true"></storever-datatable-column>
          </storever-datatable>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="close.emit()" translate>CANCEL</button>
      </div>
    </div>
  </div>
</div>
