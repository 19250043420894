import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadPlayersIssueAction} from '../../daryl/actions/players-issue-list';
import * as fromPlayers from '../../players/reducers';
import {
  CreateTicketSFActionTypes,
  LoadCreateMultiTicketSFAction,
  LoadCreateMultiTicketSFSuccessAction,
  LoadCreateTicketSFAction,
  LoadCreateTicketSFErrorAction,
  LoadCreateTicketSFSuccessAction
} from '../actions/create-ticket-sf';
import {BaseEffect, error, success} from '../index';
import {TicketService} from '../services/tickets.service';

@Injectable()
export class CreteTicketSFEffect extends BaseEffect {

  @Effect()
  create$ = this.actions$.pipe(
    ofType(CreateTicketSFActionTypes.CREATE),
    debug('Create Salesforce ticket action received.'),
    map((action: LoadCreateTicketSFAction) => action.payload),
    withLatestFrom(this.store.select(fromPlayers.selectors.getPlayerDetails)),
    switchMap(([payload, player]) => this.ticketService
                                       .createTicket(player, payload)
                                       // tslint:disable-next-line:max-line-length
                                       .pipe(switchMap(() => of<Action>(new LoadCreateTicketSFSuccessAction(), new LoadPlayersIssueAction())),
                                             catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  createSuccess$ =
    this.actions$.pipe(ofType(CreateTicketSFActionTypes.CREATE_SUCCESS),
                       debug('Success Creating Salesforce ticket.'),
                       map(() => success(this.translation.translate('SF_TICKET_CREATION_SUCCESS'), this.translation.translate('TOAST_SUCCESS_TITLE'))));

  @Effect()
  createFail$ = this.actions$.pipe(ofType(CreateTicketSFActionTypes.CREATE_FAIL),
                                   debug('A server error occurred while Creating Salesforce ticket.'),
                                   map(() => error(this.translation.translate('SF_TICKET_CREATION_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));
  @Effect()
  createMulti$ = this.actions$.pipe(
    ofType(CreateTicketSFActionTypes.CREATE_MULTI),
    debug('Create Salesforce ticket action received.'),
    map((action: LoadCreateMultiTicketSFAction) => action.payload),
    withLatestFrom(this.store.select(fromPlayers.selectors.getPlayerDetails)),
    switchMap(([payload, player]) => this.ticketService
                                       .createTicketMulti(payload)
                                       // tslint:disable-next-line:max-line-length
                                       .pipe(switchMap((payloadR) => of<Action>(new LoadCreateMultiTicketSFSuccessAction(payloadR.data.array))),
                                             catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  createSuccessMulti$ = this.actions$.pipe(ofType(CreateTicketSFActionTypes.CREATE_SUCCESS_MULTI),
                                           debug('Success Creating Salesforce ticket.'),
                                           map((action: LoadCreateMultiTicketSFSuccessAction) => action.payload),
                                           map((payload: any[]) => this.checkAllData(payload)));

  @Effect()
  createFailMulti$ =
    this.actions$.pipe(ofType(CreateTicketSFActionTypes.CREATE_FAIL_MULTI),
                       debug('A server error occurred while Creating Salesforce ticket.'),
                       map(() => error(this.translation.translate('SF_TICKET_CREATION_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private store: Store<fromPlayers.AppState>,
              private translation: L10nTranslationService,
              private ticketService: TicketService,
              router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadCreateTicketSFErrorAction(response.status); }
  checkAllData(data: any[]): Action {
    let countFailed = 0;
    for (let i = 0; i < data.length; i++) {
      if (!data[i].success) {
        countFailed++;
      }
    }
    this.store.dispatch(new LoadPlayersIssueAction());
    if (countFailed > 0) {
      return error(this.translation.translate('SF_TICKET_CREATION_ERROR') + '  ' + countFailed + '/' + data.length + ' tickets',
                   this.translation.translate('TOAST_ERROR_TITLE'));
    } else {
      return success(this.translation.translate('SF_TICKET_CREATION_SUCCESS'), this.translation.translate('TOAST_SUCCESS_TITLE'));
    }
  }
}
