import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CulturesActionTypes, LoadCulturesSuccessAction} from '../actions/cultures';
import {Culture} from '../models/culture';

export interface CulturesState {
  array: Culture[];
}

const initialState: CulturesState = {
  array: []
};

export function culturesReducer(state = initialState, action: Action): CulturesState {
  switch (action.type) {
    case CulturesActionTypes.LOAD:
      return handleLoadAction();
    case CulturesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CulturesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): CulturesState {
  return initialState;
}

function handleLoadSuccessAction(state: CulturesState, action: LoadCulturesSuccessAction): CulturesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: CulturesState): CulturesState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const culturesSelectors = {
  array: (state: CulturesState) => _.get(state, 'array', [])
};
