import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadOfficesSuccessAction, officesActionTypes} from '../actions/offices';
import {Office} from '../models/culture';

export interface OfficesState {
  array: Office[];
}

const initialState: OfficesState = {
  array: []
};

export function officesReducer(state: OfficesState = initialState, action: Action): OfficesState {
  switch (action.type) {
    case officesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: OfficesState, action: LoadOfficesSuccessAction): OfficesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

export const officesSelectors = {
  array: (state: OfficesState) => _.get(state, 'array', [])
};
