import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {Country} from '../../players/models/country';
import {LoadSfTicketDetailAction, LoadSfTicketDetailFailAction, LoadSfTicketDetailSuccessAction, SfTicketDetailActionTypes} from '../actions/SF_ticket_detail';
import {ArrayResponse, BaseEffect, error, SendBackResult} from '../index';
import {SfTicketDetailService} from '../services/SFTicketDetail.service';

@Injectable()
export class SfTicketDetailEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(ofType(SfTicketDetailActionTypes.LOAD),
                             debug('Load SfTicketDetail action received;'),
                             map((action: LoadSfTicketDetailAction) => action.payload),
                             switchMap((payload) => this.sfTicketDetailService.getTicket(payload).pipe(
                                         map((payloadR: SendBackResult<ArrayResponse<Country>>) => new LoadSfTicketDetailSuccessAction(payloadR.data.array)),
                                         catchError((res: Response) => this.catchResponseError(res)))));

  // @Effect()
  // loadFail$ = this.actions$.pipe(ofType(SfTicketDetailActionTypes.LOAD_FAIL),
  //                                debug('Load SfTicketDetail fail action received.'),
  // tslint:disable-next-line:max-line-length
  //                                map(() => error(this.translation.translate('SF_TICKET_DETAIL_LOAD_ERROR'),
  //                                this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private sfTicketDetailService: SfTicketDetailService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadSfTicketDetailFailAction(response.status); }
}
