import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {of} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {debug} from '../../../rxjs-operators';
import {LoadCurrentClientAction} from '../actions/current-client';
import {LoadExternalLinksAction} from '../actions/external-links';
import {ChangeClientAction, LoadRolesAction, UserContextActionTypes} from '../actions/user-context';
import {RouterStateUrl} from '../models/router-state-url';

@Injectable()
export class UserContextClientEffect {
  @Effect()
  changeClient$ = this.actions$.pipe(ofType(UserContextActionTypes.CHANGE_CLIENT),
                                     debug('Change client action received'),
                                     switchMap((action: ChangeClientAction) => of<Action>(new LoadCurrentClientAction(action.payload.clientId),
                                                                                          new LoadRolesAction(action.payload.clientId),
                                                                                          new LoadExternalLinksAction(action.payload.clientId))));

  @Effect()
  changeClientByUrl$ = this.actions$.pipe(ofType(ROUTER_NAVIGATION),
                                          map((action: RouterNavigationAction<RouterStateUrl>) => _.get(action.payload.routerState.params, 'clientId')),
                                          filter(clientId => !_.isEmpty(clientId)),
                                          distinctUntilChanged(),
                                          map(clientId => new ChangeClientAction({ clientId, redirect: false })));

  constructor(private actions$: Actions) {}
}
