import {Router} from '@angular/router';
import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';

import {go, replace} from '../actions/router';
import {RETURN_URL} from '../consts';

export abstract class BaseEffect {
  constructor(protected router: Router) {}

  /**
   * Main http error handler
   * @param response The http response object.
   * @returns {Observable<Action>}
   */
  protected catchResponseError(response: Response): Observable<Action> {
    if (response.status === 400) {
      const action = this.handleBadRequest(response);
      if (!_.isEmpty(action)) {
        return of(action);
      }
    }

    if (response.status === 401) {
      const action = this.handleUnauthorized();
      if (!_.isEmpty(action)) {
        return of(action);
      }
    }

    if (response.status === 403) {
      const action = this.handleAccessDenied();
      if (!_.isEmpty(action)) {
        return of(action);
      }
    }

    if (response.status === 404) {
      const action = this.handleNotFound();
      if (!_.isEmpty(action)) {
        return of(action);
      }
    }

    if (response.status === 409) {
      const action = this.handleConflict(response);
      if (!_.isEmpty(action)) {
        return of(action);
      }
    }

    return of(this.handleUnhandledError(response));
  }

  /**
   * Custom handler in case of a 400 Bad Request http response.
   * @virtual
   * @param response The Http Response object
   * @returns {Action}
   */
  protected handleBadRequest(response: Response): Action { return undefined; }

  /**
   * Custom handler in case of a 401 Unauthorized http response.
   * @virtual
   * @return {Action}
   */
  protected handleUnauthorized(): Action {
    if (this.router.url !== '/login' && this.router.url !== '/dashboard') {
      localStorage.setItem(RETURN_URL, this.router.url);
    }
    return go(['/login']);
  }

  protected handleAccessDenied(): Action { return replace(['/access-denied']); }

  protected handleNotFound(): Action { return replace(['/not-found']); }

  /**
   * Custom handler in case of a 409 Conflict http response.
   * @virtual
   * @param response The Http Response object
   * @returns {Action}
   */
  protected handleConflict(response: Response): Action { return undefined; }

  /**
   * Custom handler in case of 500 Internal Server Error or any other unhandled http error.
   * @abstract
   * @param response The Http Response object
   * @returns {Action}
   */
  protected abstract handleUnhandledError(response: Response): Action;
}
