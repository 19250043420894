import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {LoadTimezonesFailAction, LoadTimezonesSuccessAction, TimezonesActionTypes} from '../actions/timezones';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';
import {Timezone} from '../models/timezone';
import {TimezonesService} from '../services/timezones.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class TimezonesEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(ofType(TimezonesActionTypes.LOAD),
                             debug('Load timezones action received;'),
                             switchMap(() => this.timezonesService.getList().pipe(
                                         map((payload: SendBackResult<ArrayResponse<Timezone>>) => new LoadTimezonesSuccessAction(payload.data.array)),
                                         catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(TimezonesActionTypes.LOAD_FAIL),
                                 debug('Load timezones fail action received.'),
                                 map(() => error(this.translation.translate('TIMEZONES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private timezonesService: TimezonesService, router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadTimezonesFailAction(response.status); }
}
