import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArrayResponse} from '../models/array-response';
import {Office} from '../models/culture';
import {SendBackResult} from '../models/send-back-result';
import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class ClientConfigurationService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'ClientConfigurationService'; }

  protected get isAnonymous(): boolean { return false; }

  getList(clientId: string): Observable<SendBackResult<ArrayResponse<any>>> { return this.get<ArrayResponse<any>>(`/client/${clientId}/Configurations`); }
}
