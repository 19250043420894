import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {L10nTranslationModule} from 'angular-l10n';
import {PopoverModule} from 'ngx-bootstrap/popover';

import {SharedModule} from '../shared';

import {AuthRoutingModule} from './auth-routing.module';
import {CallbackComponent} from './pages/callback/callback.component';
import {CluffComponent} from './pages/cluff/cluff.component';
import {ConsentFormComponent} from './pages/consent-form/consent-form.component';
import {LoginComponent} from './pages/login/login.component';
import {LostPasswordComponent} from './pages/lost-password/lost-password.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AnonymousUserService} from './services/anonymous-user.service';

@NgModule({
  imports: [AuthRoutingModule, CommonModule, PopoverModule, ReactiveFormsModule, SharedModule, L10nTranslationModule],
  declarations:
    [CallbackComponent, LoginComponent, LostPasswordComponent, ResetPasswordComponent, CluffComponent, PrivacyPolicyComponent, ConsentFormComponent],

})
export class AuthModule {
}
