import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {AudioMedia} from '../models/audio-media';
import {AudioPlanningFilterForm} from '../models/audio-planning-filter-form';

export const AudioPlaylistsActionTypes = {
  TOGGLE_SEARCH: type('[Audio Playlist] Toggle Search Form'),
  SEARCH: type('[Audio Playlist] Search'),
  CHANGE_PAGE_SIZE: type('[Audio Playlist] Change Page Size'),
  PAGE_TO: type('[Audio Playlist] Page To'),
  ORDER_BY: type('[Audio Playlist] Order By'),
  LOAD: type('[Audio Playlist] Load'),
  LOAD_SUCCESS: type('[Audio Playlist] Load Success'),
  LOAD_FAIL: type('[Audio Playlist] Load Fail'),
  TOGGLE_PLAY: type('[Audio Playlist] Toggle Play'),
  CLEAR: type('[Audio Playlist] Clear Data'),
  EMPTY: type('[Audio Playlist] Empty Data'),
};

export class ToggleSearchAudioPlaylistsAction implements Action {
  readonly type = AudioPlaylistsActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchAudioPlaylistsAction implements Action {
  readonly type = AudioPlaylistsActionTypes.SEARCH;

  constructor(public payload?: AudioPlanningFilterForm) {}
}

export class ChangeAudioPlaylistsPageSizeAction implements Action {
  readonly type = AudioPlaylistsActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageAudioPlaylistsToAction implements Action {
  readonly type = AudioPlaylistsActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderAudioPlaylistsByAction implements Action {
  readonly type = AudioPlaylistsActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadAudioPlaylistsAction implements Action {
  readonly type = AudioPlaylistsActionTypes.LOAD;

  constructor(public payload?: boolean) {}
}

export class LoadAudioPlaylistsSuccessAction implements Action {
  readonly type = AudioPlaylistsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<AudioMedia>) {}
}

export class LoadAudioPlaylistsFailAction implements Action {
  readonly type = AudioPlaylistsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ToggleAudioPlaylistsPlayAction implements Action {
  readonly type = AudioPlaylistsActionTypes.TOGGLE_PLAY;

  constructor(public payload?: string) {}
}

export class ClearAudioPlaylistsAction implements Action {
  readonly type = AudioPlaylistsActionTypes.CLEAR;

  constructor() {}
}
export class EmptyAudioPlaylistsAction implements Action {
  readonly type = AudioPlaylistsActionTypes.EMPTY;

  constructor() {}
}
