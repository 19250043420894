import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {CommandsActionTypes, LoadCommandsAction, LoadCommandsSuccessAction, ToggleSearchCommandsAction} from '../actions/commands';
import {Command} from '../models/command';

export interface CommandsState {
  showFilter: boolean;
  array: Command[];
  count: number;
}

const initialState: CommandsState = {
  showFilter: false,
  array: [],
  count: 0
};

export function commandsReducer(state = initialState, action: Action): CommandsState {
  switch (action.type) {
    case CommandsActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case CommandsActionTypes.CLEAR:
      return handleClearAction(state);
    case CommandsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CommandsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: CommandsState, action: ToggleSearchCommandsAction): CommandsState {
  const newState: CommandsState = { showFilter: action.payload, array: state.array, count: state.count };
  return newState;
}

function handleClearAction(state: CommandsState): CommandsState {
  const newState: CommandsState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleLoadSuccessAction(state: CommandsState, action: LoadCommandsSuccessAction): CommandsState {
  const newState: CommandsState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count };
  return newState;
}

function handleLoadFailAction(state: CommandsState): CommandsState {
  const newState: CommandsState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleChangeClientAction(): CommandsState {
  return { showFilter: false, array: [], count: 0 };
}

export const commandsSelectors = {
  showFilter: (state: CommandsState) => _.get(state, 'showFilter', false),
  array: (state: CommandsState) => _.get(state, 'array', []),
  count: (state: CommandsState) => _.get(state, 'count', 0)
};
