import {Action} from '@ngrx/store';

import {Culture} from '../models/culture';
import {type} from '../utilities/type';

export const CulturesActionTypes = {
  LOAD: type('[Cultures] Load'),
  LOAD_SUCCESS: type('[Cultures] Load Success'),
  LOAD_FAIL: type('[Cultures] Load Fail')
};

export class LoadCulturesAction implements Action {
  readonly type = CulturesActionTypes.LOAD;

  constructor() {}
}

export class LoadCulturesSuccessAction implements Action {
  readonly type = CulturesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Culture[]) {}
}

export class LoadCulturesFailAction implements Action {
  readonly type = CulturesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
