import {Action} from '@ngrx/store';

import {Preference} from '../models/preferences';
import {type} from '../utilities/type';

export const preferencesActionTypes = {
  LOAD: type('[Preferences] Load'),
  LOAD_SUCCESS: type('[Preferences] Load Success'),
  LOAD_FAIL: type('[Preferences] Load Fail'),

  SET: type('[Preferences] Set'),
  SET_SUCCESS: type('[Preferences] Set Success'),
  SET_FAIL: type('[Preferences] Set Fail')
};

export class LoadPreferencesAction implements Action {
  readonly type = preferencesActionTypes.LOAD;
  constructor() {}
}

export class LoadPreferencesSuccessAction implements Action {
  readonly type = preferencesActionTypes.LOAD_SUCCESS;
  constructor(public payload?: Preference[]) {}
}

export class LoadPreferencesFailAction implements Action {
  readonly type = preferencesActionTypes.LOAD_FAIL;
  constructor(public payload?: string) {}
}

export class SetPreferencesAction implements Action {
  readonly type = preferencesActionTypes.SET;
  constructor(public payload?: Preference) {}
}

export class SetPreferencesSuccessAction implements Action {
  readonly type = preferencesActionTypes.SET_SUCCESS;
  constructor(public payload?: Preference[]) {}
}

export class SetPreferencesFailAction implements Action {
  readonly type = preferencesActionTypes.SET_FAIL;
  constructor(public payload?: string) {}
}
