import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Country} from '../../players/models/country';
import {AppSettingsService, ArrayResponse, BaseRestService, SendBackResult} from '../index';

@Injectable({ providedIn: 'root' })
export class SfTicketDetailService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'SfTicketDetailService'; }

  protected get isAnonymous(): boolean { return false; }

  getTicket(deviceID: string): Observable<SendBackResult<ArrayResponse<any>>> {
    // deviceID = 'mcu-17297';
    console.log(deviceID)
    return this.get<ArrayResponse<any>>(`/salesforce/tickets?deviceid=${deviceID}`);
  }
}
