import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared/actions/user-context';
import {AudioProgramsActionTypes, LoadAudioProgramsSuccessAction} from '../actions/audio-programs';
import {AudioProgram} from '../models/audio-program';

export interface AudioProgramsState {
  array: AudioProgram[];
}

const initialState: AudioProgramsState = {
  array: []
};

export function audioProgramsReducer(state = initialState, action: Action): AudioProgramsState {
  switch (action.type) {
    case AudioProgramsActionTypes.LOAD:
      return handleLoadAction();
    case AudioProgramsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case AudioProgramsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): AudioProgramsState {
  return initialState;
}

function handleLoadSuccessAction(state: AudioProgramsState, action: LoadAudioProgramsSuccessAction): AudioProgramsState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: AudioProgramsState): AudioProgramsState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): AudioProgramsState {
  return { array: [] };
}

export const audioProgramsSelectors = {
  array: (state: AudioProgramsState) => _.get(state, 'array', [])
};
