import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import {catchError, map, switchMap} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {CurrentClientActionTypes, LoadCurrentClientAction, LoadCurrentClientFailAction, LoadCurrentClientSuccessAction} from '../actions/current-client';
import {error} from '../actions/toasts';
import {Client} from '../models/client';
import {SendBackResult} from '../models/send-back-result';
import {ClientsService} from '../services/clients.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class CurrentClientEffect extends BaseEffect {
  @Effect()
  load$ = this.actions$.pipe(
    ofType(CurrentClientActionTypes.LOAD),
    debug('Load current client info action received.'),
    switchMap((action: LoadCurrentClientAction) => this.clientsService.getOne(action.payload)
                                                     .pipe(map((payload: SendBackResult<Client>) => new LoadCurrentClientSuccessAction(payload.data)),
                                                           catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(CurrentClientActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while loading the current client details info.'),
                                 map(() => error(this.translation.translate('CURRENT_CLIENT_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions, private translation: L10nTranslationService, private clientsService: ClientsService, router: Router) { super(router); }

  protected handleUnhandledError(response: Response): Action { return new LoadCurrentClientFailAction(response.status); }
}
