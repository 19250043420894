import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArrayResponse} from '../models/array-response';
import {Culture} from '../models/culture';
import {SendBackResult} from '../models/send-back-result';
import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class CulturesService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'CulturesService'; }

  protected get isAnonymous(): boolean { return true; }

  getList(): Observable<SendBackResult<ArrayResponse<Culture>>> { return this.get<ArrayResponse<Culture>>(`/cultures`); }
}
