import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router';
import {ModalComponent, ModalConfimationModule} from '@storever/modal-confirmation';
import {L10nTranslationModule} from 'angular-l10n';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ImageToDataUrlModule} from 'ngx-image2dataurl';
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToastModule} from 'primeng/toast';

import {IssuePlayerDetailModalComponent} from '../daryl/components/issue-player-detail-modal/issue-player-detail-modal.component';
import {UsersModule} from '../users/users.module';

import {BlockUIComponent} from './components/block-ui/block-ui.component';
import {ChangeAvatarComponent} from './components/change-avatar/change-avatar.component';
import {CreateTicketSfModalComponent} from './components/create-ticket-sf-modal/create-ticket-sf-modal.component';
import {DataTableComponent} from './components/datatable/datatable.component';
import {HeaderSubtitleComponent} from './components/header-subtitle/header-subtitle.component';
import {ImpersonateUserComponent} from './components/impersonate-user/impersonate-user.component';
import {QuickSearchResultsComponent} from './components/quick-search-results/quick-search-results.component';
import {ScrollToTopComponent} from './components/scroll-to-top/scroll-to-top.component';
import {SendBackResultComponent} from './components/send-back-result/send-back-result.component';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {WarningMessageComponent} from './components/warning-message/warning-message.component';
import {DataTableActionsTemplateDirective} from './directives/datatable-actions-template.directive';
import {DataTableActionsDirective} from './directives/datatable-actions.directive';
import {DataTableColumnCellDirective} from './directives/datatable-column-cell.directive';
import {DataTableColumnHeaderDirective} from './directives/datatable-column-header.directive';
import {DataTableColumnDirective} from './directives/datatable-column.directive';
import {DataTableDetailsTemplateDirective} from './directives/datatable-details-template.directive';
import {DataTableDetailsDirective} from './directives/datatable-details.directive';
import {DataTableEmptyTemplateDirective} from './directives/datatable-empty-template.directive';
import {DataTableEmptyDirective} from './directives/datatable-empty.directive';
import {DateTimePickerDirective} from './directives/date-time-picker.directive';
import {MustBeAuthenticatedGuard} from './guards/must-be-authenticated.guard';
import {AppLayoutComponent} from './pages/app-layout/app-layout.component';
import {AuthLayoutComponent} from './pages/auth-layout/auth-layout.component';
import {ErrorLayoutComponent} from './pages/error-layout/error-layout.component';
import {AudioTypeIconPipe} from './pipes/audio-type-icon.pipe';
import {AudioTypeTitlePipe} from './pipes/audio-type-title.pipe';
import {AvatarPipe} from './pipes/avatar.pipe';
import {ClientsFilterPipe} from './pipes/clients-filter.pipe';
import {CommonCulturePipe} from './pipes/common-culture.pipe';
import {DateMoment} from './pipes/date-moment.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {ImpersonableUsersFilterCountPipe} from './pipes/impersonable-users-filter-count.pipe';
import {ImpersonableUsersFilterPipe} from './pipes/impersonable-users-filter.pipe';
import {MessagePipe} from './pipes/message.pipe';
import {MomentPipe} from './pipes/moment.pipe';
import {Nl2brPipe} from './pipes/nl2br.pipe';
import {NotDiscardedPipe} from './pipes/not-discarded.pipe';
import {PaginatePipe} from './pipes/paginate.pipe';
import {TernaryPipe} from './pipes/ternary.pipe';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {AlertsPlayerDeconnectService} from './services/alerts-player-deconnect.service';
import {AlertsService} from './services/alerts.service';
import {AppSettingsService} from './services/app-settings.service';
import {Auth0AuthenticationService} from './services/auth0-authentication.service';
import {Auth0ManagementService} from './services/auth0-management.service';
import {BlockUIService} from './services/block-ui.service';
import {BodyService} from './services/body.service';
import {ClientsService} from './services/clients.service';
import {CulturesService} from './services/cultures.service';
import {DateTimeFormatService} from './services/date-time-format.service';
import {ExternalLinksService} from './services/external-links.service';
import {GroupRolesTemplatesService} from './services/group-roles-templates.service';
import {GroupRolesService} from './services/group-roles.service';
import {ImpersonableUsersService} from './services/impersonable-users.service';
import {InterceptorSpinnerService} from './services/interceptor-spinner.service';
import {LayoutService} from './services/layout.service';
import {OfficesService} from './services/offices.service';
import {PasswordStrengthService} from './services/password-strength.service';
import {QuickSearchService} from './services/quick-search.service';
import {ScrollToTopService} from './services/scroll-to-top.service';
import {TimezonesService} from './services/timezones.service';
import {UserContextService} from './services/user-context.service';
import {IsAfterValidatorDirective} from './validators/is-after/directive';
import {IsBeforeValidatorDirective} from './validators/is-before/directive';
import {IsBetweenValidatorDirective} from './validators/is-between/directive';
import {IsSameOrAfterValidatorDirective} from './validators/is-same-or-after/directive';
import {MomentValidatorDirective} from './validators/moment/directive';

export const httpInterceptorProviders = [
  // { provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: InterceptorSpinnerService, multi: true},
];

@NgModule({
  imports: [
    BsDropdownModule,
    CommonModule,
    FormsModule,
    ModalModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    InputSwitchModule,
    L10nTranslationModule,
    ImageToDataUrlModule,
    NgProgressHttpModule,
    ModalConfimationModule,
    NgProgressModule.withConfig({ spinnerPosition: 'right', color: '#0080ff' }),
    ToastModule,
    // NgProgressModule.withConfig({ spinnerPosition: 'right', color: '#0080ff' }),
    // NgProgressHttpModule,
  ],
  exports: [
    // Components
    BlockUIComponent,
    ChangeAvatarComponent,
    DataTableComponent,
    ImpersonateUserComponent,
    QuickSearchResultsComponent,
    ScrollToTopComponent,
    SendBackResultComponent,
    TopMenuComponent,
    WarningMessageComponent,
    // Directives
    DataTableActionsDirective,
    DataTableActionsTemplateDirective,
    DataTableColumnDirective,
    DataTableColumnCellDirective,
    DataTableColumnHeaderDirective,
    DataTableDetailsDirective,
    DataTableDetailsTemplateDirective,
    DataTableEmptyDirective,
    DataTableEmptyTemplateDirective,
    DateTimePickerDirective,
    // Pages
    AppLayoutComponent,
    AuthLayoutComponent,
    ErrorLayoutComponent,
    // Pipes
    AvatarPipe,
    ClientsFilterPipe,
    CommonCulturePipe,
    ImpersonableUsersFilterPipe,
    ImpersonableUsersFilterCountPipe,
    MessagePipe,
    MomentPipe,
    Nl2brPipe,
    NotDiscardedPipe,
    PaginatePipe,
    TernaryPipe,
    TimeAgoPipe,
    DateMoment,
    DurationPipe,
    AudioTypeTitlePipe,
    AudioTypeIconPipe,
    // Validators
    IsAfterValidatorDirective,
    IsBeforeValidatorDirective,
    IsBetweenValidatorDirective,
    IsSameOrAfterValidatorDirective,
    MomentValidatorDirective,
    // modules
    InputSwitchModule,
    ToastModule,
    ModalConfimationModule,
    IssuePlayerDetailModalComponent,
    CreateTicketSfModalComponent,
    HeaderSubtitleComponent
  ],
  entryComponents: [ModalComponent],
  declarations: [
    // Components
    BlockUIComponent,
    ChangeAvatarComponent,
    DataTableComponent,
    ImpersonateUserComponent,
    QuickSearchResultsComponent,
    ScrollToTopComponent,
    SendBackResultComponent,
    TopMenuComponent,
    IssuePlayerDetailModalComponent,
    // Directives
    DataTableActionsDirective,
    DataTableActionsTemplateDirective,
    DataTableColumnDirective,
    DataTableColumnCellDirective,
    DataTableColumnHeaderDirective,
    DataTableDetailsDirective,
    DataTableDetailsTemplateDirective,
    DataTableEmptyDirective,
    DataTableEmptyTemplateDirective,
    DateTimePickerDirective,
    // Pages
    AppLayoutComponent,
    AuthLayoutComponent,
    ErrorLayoutComponent,
    // Pipes
    AvatarPipe,
    ClientsFilterPipe,
    CommonCulturePipe,
    ImpersonableUsersFilterPipe,
    ImpersonableUsersFilterCountPipe,
    MessagePipe,
    MomentPipe,
    Nl2brPipe,
    NotDiscardedPipe,
    PaginatePipe,
    TernaryPipe,
    TimeAgoPipe,
    DateMoment,
    DurationPipe,
    AudioTypeTitlePipe,
    AudioTypeIconPipe,
    // Validators
    IsAfterValidatorDirective,
    IsBeforeValidatorDirective,
    IsBetweenValidatorDirective,
    IsSameOrAfterValidatorDirective,
    MomentValidatorDirective,
    WarningMessageComponent,
    CreateTicketSfModalComponent,
    HeaderSubtitleComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // Guards
        MustBeAuthenticatedGuard,
        // Services
        AlertsService,
        AppSettingsService,
        Auth0AuthenticationService,
        Auth0ManagementService,
        BlockUIService,
        BodyService,
        ClientsService,
        CulturesService,
        OfficesService,
        DateTimeFormatService,
        ExternalLinksService,
        GroupRolesTemplatesService,
        GroupRolesService,
        ImpersonableUsersService,
        LayoutService,
        PasswordStrengthService,
        // { provide: BrowserXhr, useClass: ProgressBrowserXhr },
        httpInterceptorProviders,
        QuickSearchService,
        ScrollToTopService,
        TimezonesService,
        UserContextService,
        AlertsPlayerDeconnectService,
        // { provide: ToastOptions, useClass: ToastOptionsService },
      ]
    };
  }
}
