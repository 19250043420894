import {Action} from '@ngrx/store';
import * as _ from 'lodash';
import {LoadProductionLineSuccessAction, productionLineActionTypes} from '../actions/production-line';

export interface ProductionLineState {
  // tslint:disable-next-line:no-any
  array: any[];
}

const initialState: ProductionLineState = {
  array: []
};

export function productionLineReducer(state: ProductionLineState = initialState, action: Action): ProductionLineState {
  switch (action.type) {
    case productionLineActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    default:
      return state;
  }
}

function handleLoadSuccessAction(state: ProductionLineState, action: LoadProductionLineSuccessAction): ProductionLineState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

export const productionLineSelectors = {
  array: (state: ProductionLineState) => _.get(state, 'array', []),
};
