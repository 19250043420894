import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {MessageService} from 'primeng/api';
import {of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';

import {ErrorToastAction, InfoToastAction, SuccessToastAction, ToastsAction, ToastsMethodCall, WarningToastAction} from '../actions/toasts';

@Injectable()
export class ToastsEffect {
  @Effect({ dispatch: false })
  error$ = this.actions$.pipe(ofType(ToastsAction.ERROR),
                              map((action: ErrorToastAction) => action.payload),
                              switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                          { severity: 'error', summary: payload.title, detail: payload.message, sticky: true, closable: true }))));

  @Effect({ dispatch: false })
  info$ = this.actions$.pipe(ofType(ToastsAction.INFO),
                             map((action: InfoToastAction) => action.payload),
                             switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                         { severity: 'info', summary: payload.title, detail: payload.message, closable: true, life: 10000 }))));

  @Effect({ dispatch: false })
  success$ = this.actions$.pipe(ofType(ToastsAction.SUCCESS),
                                map((action: SuccessToastAction) => action.payload),
                                switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                            { severity: 'success', summary: payload.title, detail: payload.message, closable: true, life: 10000 }))));

  @Effect({ dispatch: false })
  warning$ = this.actions$.pipe(ofType(ToastsAction.WARNING),
                                map((action: WarningToastAction) => action.payload),
                                switchMap((payload: ToastsMethodCall) => of(this.messageService.add(
                                            { severity: 'warn', summary: payload.title, detail: payload.message, closable: true, life: 10000 }))));

  // @Effect({ dispatch: false })
  // custom$ = this.actions$.pipe(ofType(ToastsAction.CUSTOM),
  //                              map((action: CustomToastAction) => action.payload),
  //                              switchMap((payload: ToastsMethodCall) =>
  //         of(this.messageService.add({severity: 'info', summary: payload.title, detail: payload.message, closable: true, life: 10000}))
  //       )
  //                              );

  @Effect({ dispatch: false }) clearAll$ = this.actions$.pipe(ofType(ToastsAction.CLEAR_ALL), tap(() => this.messageService.clear()));

  constructor(private actions$: Actions, private messageService: MessageService) {}
}
