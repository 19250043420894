import {Action} from '@ngrx/store';

import {GroupRole} from '../models/group-role';
import {type} from '../utilities/type';

export const GroupRolesTemplatesActionTypes = {
  LOAD: type('[Group Roles Templates] Load'),
  LOAD_SUCCESS: type('[Group Roles Templates] Load Success'),
  LOAD_FAIL: type('[Group Roles Templates] Load Fail')
};

export class LoadGroupRolesTemplatesAction implements Action {
  readonly type = GroupRolesTemplatesActionTypes.LOAD;
  constructor() {}
}

export class LoadGroupRolesTemplatesSuccessAction implements Action {
  readonly type = GroupRolesTemplatesActionTypes.LOAD_SUCCESS;
  constructor(public payload?: GroupRole[]) {}
}

export class LoadGroupRolesTemplatesFailAction implements Action {
  readonly type = GroupRolesTemplatesActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}
