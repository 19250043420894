import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {AudioPlaylistStatsActionTypes, LoadAudioPlaylistStatsSuccessAction} from '../actions/audio-playlist-stats';
import {
  AudioPlaylistsActionTypes,
  LoadAudioPlaylistsSuccessAction,
  ToggleAudioPlaylistsPlayAction,
  ToggleSearchAudioPlaylistsAction
} from '../actions/audio-playlists';
import {AudioMedia} from '../models/audio-media';
import {PlaylistStats} from '../models/playlist-stats';

export interface AudioPlaylistsState {
  showFilter: boolean;
  array: AudioMedia[];
  count: number;
  stats: PlaylistStats;
  playing: string;
}

const initialState: AudioPlaylistsState = {
  showFilter: false,
  array: [],
  count: 0,
  stats: undefined,
  playing: undefined
};

export function audioPlaylistsReducer(state = initialState, action: Action): AudioPlaylistsState {
  switch (action.type) {
    case AudioPlaylistsActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case AudioPlaylistsActionTypes.CLEAR:
      return handleClearAction(state);
    case AudioPlaylistsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case AudioPlaylistsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case AudioPlaylistStatsActionTypes.LOAD:
      return handleLoadStatsAction(state);
    case AudioPlaylistStatsActionTypes.LOAD_SUCCESS:
      return handleLoadStatsSuccessAction(state, action);
    case AudioPlaylistStatsActionTypes.LOAD_FAIL:
      return handleLoadStatsFailAction(state);
    case AudioPlaylistsActionTypes.TOGGLE_PLAY:
      return handleTogglePlayAction(state, action);
    default:
      return state;
  }
}

function handleToggleSearchAction(state: AudioPlaylistsState, action: ToggleSearchAudioPlaylistsAction): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: action.payload, array: state.array, count: state.count, stats: state.stats, playing: state.playing };
  return newState;
}

function handleClearAction(state: AudioPlaylistsState): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: state.showFilter, array: [], count: 0, stats: state.stats, playing: undefined };
  return newState;
}

function handleLoadSuccessAction(state: AudioPlaylistsState, action: LoadAudioPlaylistsSuccessAction): AudioPlaylistsState {
  const newState:
    AudioPlaylistsState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count, stats: state.stats, playing: undefined };
  return newState;
}

function handleLoadFailAction(state: AudioPlaylistsState): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: state.showFilter, array: [], count: 0, stats: state.stats, playing: undefined };
  return newState;
}

function handleChangeClientAction(): AudioPlaylistsState {
  // !!! this showFilter override all showFilter
  return { showFilter: false, array: [], count: 0, stats: undefined, playing: undefined };
}

function handleLoadStatsAction(state: AudioPlaylistsState): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: state.showFilter, array: state.array, count: state.count, stats: undefined, playing: state.playing };
  return newState;
}

function handleLoadStatsSuccessAction(state: AudioPlaylistsState, action: LoadAudioPlaylistStatsSuccessAction): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: state.showFilter, array: state.array, count: state.count, stats: action.payload, playing: state.playing };
  return newState;
}

function handleLoadStatsFailAction(state: AudioPlaylistsState): AudioPlaylistsState {
  const newState: AudioPlaylistsState = { showFilter: state.showFilter, array: state.array, count: state.count, stats: undefined, playing: state.playing };
  return newState;
}

function handleTogglePlayAction(state: AudioPlaylistsState, action: ToggleAudioPlaylistsPlayAction): AudioPlaylistsState {
  if (state.playing === action.payload) {
    return {...state, playing: undefined };
  } else {
    return {...state, playing: action.payload };
  }
}
export const audioPlaylistsSelectors = {
  showFilter: (state: AudioPlaylistsState) => _.get(state, 'showFilter', false),
  array: (state: AudioPlaylistsState) => _.get(state, 'array', []),
  count: (state: AudioPlaylistsState) => _.get(state, 'count', 0),
  stats: (state: AudioPlaylistsState) => _.get(state, 'stats'),
  playing: (state: AudioPlaylistsState) => _.get(state, 'playing')
};
