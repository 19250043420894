import {Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import * as moment from 'moment';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {Observable} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

import {LoadClientConfigurationAction, User} from '../..';
import {environment} from '../../../../environments/environment';
import * as fromUsers from '../../../users/reducers';
import {DiscardAlertAction, LoadAlertsAction} from '../../actions/alerts';
import {LoadImpersonableUsersAction, ReloadImpersonableUsersAction} from '../../actions/impersonable-users';
import {ToggleSidebarAction} from '../../actions/layout';
import {LoadMyClientsAction} from '../../actions/my-clients';
import {LoadOfficesAction} from '../../actions/offices';
import {LoadProductionLineAction} from '../../actions/production-line';
import {
  ChangeQuickSearchPageSizeAction,
  CloseQuickSearchAction,
  GotoPlayerAction,
  OrderQuickSearchByAction,
  PageQuickSearchToAction,
  QuickSearchAction
} from '../../actions/quick-search';
import {StartImpersonationAction, StopImpersonationAction} from '../../actions/user-context';
import {LoadUserInfoAction, LogoutAction} from '../../actions/user-info';
import {CUSTOM_CLAIMS_IS_STOREVER, SCROLL_OFFSET} from '../../consts';
import {AppSettings, BrandSettings} from '../../models/app-settings';
import {Auth0UserInfo} from '../../models/auth0-user-info';
import {Client} from '../../models/client';
import {DiscardableAlertMessage} from '../../models/discardable-alert-message';
import {ExternalLink} from '../../models/external-link';
import {ImpersonableUserVM} from '../../models/impersonable-user.vm';
import {ImpersonateUser} from '../../models/impersonate-user';
import {LayoutType} from '../../models/layout-type.enum';
import {QuickSearchResult} from '../../models/quick-search-result';
import {Role} from '../../models/role';
import * as fromShared from '../../reducers';
import * as fromRoot from '../../reducers';
import {AppSettingsService} from '../../services/app-settings.service';
import {LayoutService} from '../../services/layout.service';

@Component({
  selector: 'storever-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent implements OnInit {
  sidebarClosed$: Observable<boolean>;
  canChangeClient$: Observable<boolean>;
  myClients$: Observable<Client[]>;
  clientName$: Observable<string>;
  userInfo$: Observable<Auth0UserInfo>;
  impersonableUsers$: Observable<ImpersonableUserVM[]>;
  impersonatedUser$: Observable<ImpersonateUser>;
  roles$: Observable<Role[]>;
  brand$: Observable<BrandSettings>;
  quickSearchResults$: Observable<QuickSearchResult[]>;
  quickSearchResultsCount$: Observable<number>;
  showQuickSearchResults$: Observable<boolean>;
  quickSearchResultsPage$: Observable<number>;
  quickSearchResultsPageSize$: Observable<number>;
  quickSearchResultsOrderBy$: Observable<string>;
  alerts$: Observable<DiscardableAlertMessage[]>;
  externalLinks$: Observable<ExternalLink[]>;
  // tslint:disable-next-line:no-any
  dashboardLink$: Observable<any[]>;
  // tslint:disable-next-line:no-any
  playersLink$: Observable<any[]>;
  musicPreview$: Observable<any[]>;
  // tslint:disable-next-line:no-any
  usersLink$: Observable<any[]>;
  ticketsLink$: Observable<any[]>;
  clientConfiguration$: Observable<any[]>;
  scrollToTopVisible = false;
  playerQuickSearchOpen = false;
  isTicketClientConfiguration = false;
  isShown = false;
  isStorever = false;
  copyrightYear: number;
  availableLanguages: any[];
  currentClientId: string; // this is only used for display and shouldn't be used to know which client has been selected
  playerQuickSearch: string;
  user$: Observable<User>;
  isStorever$: Observable<boolean>;
  @ViewChild('clientDropdown', { read: BsDropdownDirective }) clientDropdown: BsDropdownDirective;
  @ViewChild('clientDropdownToggle', { read: ElementRef }) clientDropdownToggle: ElementRef;
  @ViewChild('clientDropdownFilter', { read: ElementRef }) clientDropdownFilter: ElementRef;
  @ViewChild('clientDropdownFiltered', { read: ElementRef }) clientDropdownFiltered: ElementRef;
  clientId: string;
  currentApplicationVersion: string;
  get isDaryl(): boolean { return window.location.href.search('daryl') !== -1; }

  get hasExternalLinks$(): Observable<boolean> { return this.externalLinks$.pipe(map(links => links.length > 0)); }

  constructor(private store: Store<fromShared.AppState>,
              private storeUsers: Store<fromUsers.AppState>,
              private layoutService: LayoutService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              appSettingsService: AppSettingsService,
              private translation: L10nTranslationService,
              @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
              @Inject(L10N_LOCALE) public locale: L10nLocale) {
    this.sidebarClosed$ = this.store.pipe(select(fromShared.selectors.getSidebarClosed));
    this.myClients$ = this.store.pipe(select(fromShared.selectors.getMyClients));
    this.canChangeClient$ = this.myClients$.pipe(filter(clients => clients != undefined), map(clients => clients.length > 1));
    this.clientName$ = this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => !_.isEmpty(client)), map(client => client.name));
    this.userInfo$ = this.store.pipe(select(fromShared.selectors.getUserInfo));
    this.impersonableUsers$ = this.store.pipe(select(fromShared.selectors.getImpersonableUsers));
    this.impersonatedUser$ = this.store.pipe(select(fromShared.selectors.getImpersonateUser));
    this.roles$ = this.store.pipe(select(fromShared.selectors.getMyRoles));
    // this.user$ = this.storeUsers.pipe(select(fromUsers.selectors.getUserDetails));
    // this.isStorever$ = this.user$.pipe(map(user => _.get(user, 'isStorever', false)));
    this.brand$ = appSettingsService.settings$.pipe(map(appSettings => appSettings.brand));
    this.quickSearchResults$ = this.store.pipe(select(fromShared.selectors.getQuickSearchResults));
    this.quickSearchResultsCount$ = this.store.pipe(select(fromShared.selectors.getQuickSearchCount));
    this.showQuickSearchResults$ = this.store.pipe(select(fromShared.selectors.getQuickSearchShowModal));
    this.clientConfiguration$ = this.store.pipe(select(fromShared.selectors.getClientConfiguration));
    const quickSearchFilter$ = this.store.pipe(select(fromShared.selectors.getQuickSearchFilter));
    // tslint:disable-next-line:no-shadowed-variable
    this.quickSearchResultsPage$ = quickSearchFilter$.pipe(map(filter => filter.$page));
    // tslint:disable-next-line:no-shadowed-variable
    this.quickSearchResultsPageSize$ = quickSearchFilter$.pipe(map(filter => filter.$length));
    // tslint:disable-next-line:no-shadowed-variable
    this.quickSearchResultsOrderBy$ = quickSearchFilter$.pipe(map(filter => filter.$orderBy));
    this.alerts$ = this.store.pipe(select(fromShared.selectors.getAlerts));
    this.externalLinks$ = this.store.pipe(select(fromShared.selectors.getExternalLinks));
    this.dashboardLink$ = this.store.pipe(select(fromShared.selectors.getCurrentClient),
                                          filter(client => client != undefined),
                                          tap(client => (this.clientId = client.uuid)),
                                          map(client => ['/', client.uuid, 'dashboard']));
    this.playersLink$ =
      this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => client != undefined), map(client => ['/', client.uuid, 'players']));
    this.usersLink$ =
      this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => client != undefined), map(client => ['/', client.uuid, 'users']));
    this.ticketsLink$ =
      this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => client != undefined), map(client => ['/', client.uuid, 'tickets']));
    this.musicPreview$ =
      this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => client != undefined), map(client => ['/', client.uuid, 'music-preview']));

    this.copyrightYear = moment().year();
    this.availableLanguages = this.l10nConfig.schema;
  }

  ngOnInit(): void {

    this.store.select(fromRoot.selectors.getCurrentClient).subscribe((data) => {
      if (data && data.name) {
        this.store.dispatch(new LoadClientConfigurationAction());
      }
    });
    this.clientConfiguration$.subscribe((data) => {
      this.isTicketClientConfiguration = false;
      for (let j = 0; j < data.length; j++) {
        if (data[j].name === 'Support.DisplayTickets') {
          this.isTicketClientConfiguration = true;
        }
      }
    });
    this.userInfo$.subscribe((data) => {
      this.isStorever = false;
      if (data && data[CUSTOM_CLAIMS_IS_STOREVER] === true) {
        this.isStorever = true;
      }
    });
    this.layoutService.setLayout(LayoutType.Full);
    this.store.dispatch(new LoadUserInfoAction());
    this.store.dispatch(new LoadMyClientsAction());
    this.store.dispatch(new LoadAlertsAction());
    this.store.dispatch(new LoadOfficesAction());
    // this.store.dispatch(new LoadProductionLineAction());
    setInterval(() => this.store.dispatch(new LoadAlertsAction()), 600000);
    this.currentApplicationVersion = environment.appVersion;
    this.cache();
  }
  cache(): void {
    if (!localStorage.getItem('buildVersion') || localStorage.getItem('buildVersion').replace(/"/g, '') !== this.currentApplicationVersion) {
      localStorage.setItem('buildVersion', JSON.stringify(this.currentApplicationVersion));
      window.location.reload(true);
    }
  }

  toggleSidebar(): void {
    this.store.dispatch(new ToggleSidebarAction());
    this.layoutService.toggleSidebar();
  }
  playerQuickSearchFocus(): void { this.playerQuickSearchOpen = true; }
  playerQuickSearchBlur(): void { this.playerQuickSearchOpen = false; }
  playerQuickSearchKeyUp($event: KeyboardEvent): void {
    if ($event.keyCode === 13) {
      this.quickSearchPlayer($event);
    }
  }
  quickSearchPlayer($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.store.dispatch(new QuickSearchAction(this.playerQuickSearch));
    this.playerQuickSearch = undefined;
  }
  quickSearchPageChanged(page: number): void { this.store.dispatch(new PageQuickSearchToAction(page)); }
  quickSearchPageSizeChanged(pageSize: number): void { this.store.dispatch(new ChangeQuickSearchPageSizeAction(pageSize)); }
  quickSearchOrderByChanged(orderBy: string): void { this.store.dispatch(new OrderQuickSearchByAction(orderBy)); }
  closeQuickSearchResults(): void { this.store.dispatch(new CloseQuickSearchAction()); }
  gotoPlayer(player: QuickSearchResult): void {
    this.closeQuickSearchResults();
    this.store.dispatch(new GotoPlayerAction(player));
  }
  selectLanguage(language: L10nLocale): void {
    // this.locale.language = language;
    this.translation.setLocale(language);
  }

  isInRole$(role: string): Observable<boolean> { return this.roles$.pipe(map(roles => _.findIndex(roles, ['code', role]) > -1)); }
  // tslint:disable-next-line:no-any
  isRouteActive(commands: any[]): boolean {
    if (!commands) {
      return false;
    }
    return this.router.isActive(this.router.createUrlTree(commands), false);
  }
  // tslint:disable-next-line:no-any
  impersonate(action: 'Enter'|'Leave', modal: any): void {
    if (action === 'Enter') {
      this.store.dispatch(new LoadImpersonableUsersAction());
      modal.open();
    } else {
      this.store.dispatch(new StopImpersonationAction());
    }
  }
  reloadImpersonableUsers(): void { this.store.dispatch(new ReloadImpersonableUsersAction()); }
  impersonateUser(user: ImpersonateUser): void { this.store.dispatch(new StartImpersonationAction(user)); }
  logout(): void {
    const homePath = this.router.serializeUrl(this.router.createUrlTree(['']));
    const returnTo = `${location.protocol}//${location.host}${homePath}`;
    this.store.dispatch(new LogoutAction(returnTo));
  }
  dismiss(alertMessageId: number): void { this.store.dispatch(new DiscardAlertAction(alertMessageId)); }
  onShown(): void {
    // if (this.clientDropdownFilter) {
    setTimeout(() => this.clientDropdownFilter.nativeElement.focus(), 100);
    // }
  }
  addInputEnter() { this.clientDropdownFiltered.nativeElement.click(); }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: Event): void {
    const scrollFromTop: number = window.pageYOffset || document.documentElement.scrollTop || document.querySelector('body').scrollTop;
    this.scrollToTopVisible = scrollFromTop > SCROLL_OFFSET;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event: MouseEvent): void {
    if (!this.isDaryl && this.clientDropdown.isOpen && $event.button !== 2 && !this.clientDropdownToggle.nativeElement.contains($event.target) &&
        !this.clientDropdownFilter.nativeElement.contains($event.target)) {
      this.clientDropdown.hide();
    }
    this.isShown = true;
  }

  @HostListener('keyup.esc')
  onEsc(): void {
    if (this.clientDropdown.isOpen) {
      this.clientDropdown.hide();
    }
  }
}
