import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BACKGROUND_XHR_PARAM} from '../consts';
import {AlertMessage} from '../models/alert-message';
import {ArrayResponse} from '../models/array-response';
import {SendBackResult} from '../models/send-back-result';

import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class AlertsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'SharedAlertsService'; }

  protected get isAnonymous(): boolean { return false; }

  getCurrentMessages(clientId: string): Observable<SendBackResult<ArrayResponse<AlertMessage>>> {
    return this.get<ArrayResponse<AlertMessage>>(`/clients/${clientId}/alert-messages?${BACKGROUND_XHR_PARAM}`);
  }
}
