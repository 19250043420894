import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {DEFAULT_PAGE_SIZE, UserContextActionTypes} from '../../shared';
import {
  ChangeInventoryPageSizeAction,
  ClearInventoryAction,
  InventoryActionTypes,
  LoadInventoryAction,
  LoadInventoryFailAction,
  LoadInventorySuccessAction,
  OrderInventoryByAction,
  PageInventoryToAction,
  SearchInventoryAction,
  ToggleSearchInventoryAction
} from '../actions/inventory';
import {Inventory} from '../models/inventory';
import {InventoryFilterForm} from '../models/inventory-filter-form';
import {AudioPlaylistsState} from './audio-playlists';

export interface InventoryState {
  showFilter: boolean;
  array: Inventory[];
  count: number;
}

const initialState: InventoryState = {
  showFilter: true,
  array: [],
  count: 0,
};

export function inventoryReducer(state = initialState, action: Action): InventoryState {
  switch (action.type) {
    case InventoryActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case InventoryActionTypes.CLEAR:
      return handleClearAction(state);
    case InventoryActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case InventoryActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    /*
    case InventoryActionTypes.LOAD:
      return handleLoadAction();
    */
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    default:
      return state;
  }
}

function handleToggleSearchAction(state: InventoryState, action: ToggleSearchInventoryAction): InventoryState {
  const newState: InventoryState = { showFilter: action.payload, array: state.array, count: state.count };
  return newState;
}

function handleClearAction(state: InventoryState): InventoryState {
  const newState: InventoryState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleLoadSuccessAction(state: InventoryState, action: LoadInventorySuccessAction): InventoryState {
  const newState: InventoryState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count };
  return newState;
}

function handleLoadFailAction(state: InventoryState): InventoryState {
  const newState: InventoryState = { showFilter: state.showFilter, array: [], count: 0 };
  return newState;
}

function handleChangeClientAction(): InventoryState {
  return { showFilter: true, array: [], count: 0 };
}
/*
function handleLoadAction(): InventoryState {
  return initialState;
}
*/
export const inventorySelectors = {
  showFilter: (state: InventoryState) => _.get(state, 'showFilter', true),
  array: (state: InventoryState) => _.get(state, 'array', []),
  count: (state: InventoryState) => _.get(state, 'count', 0),
};
