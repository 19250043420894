import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {GoAction, ReplaceAction, RouterActions} from '../actions/router';

@Injectable()
export class RouterEffects {
  @Effect({ dispatch: false })
  navigate$ = this.actions$.pipe(ofType(RouterActions.GO),
                                 map((action: GoAction) => action.payload),
                                 tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras })));

  @Effect({ dispatch: false })
  replace$ = this.actions$.pipe(ofType(RouterActions.REPLACE),
                                map((action: ReplaceAction) => action.payload),
                                tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras, replaceUrl: true })));

  @Effect({ dispatch: false }) navigateBack$ = this.actions$.pipe(ofType(RouterActions.BACK), tap(() => this.location.back()));

  @Effect({ dispatch: false }) navigateForward$ = this.actions$.pipe(ofType(RouterActions.FORWARD), tap(() => this.location.forward()));

  constructor(private actions$: Actions, private router: Router, private location: Location) {}
}
