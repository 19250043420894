import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthLayoutComponent} from '../shared';

import {OtpGuard} from './guards/otp.guard';
import {CallbackComponent} from './pages/callback/callback.component';
import {CluffComponent} from './pages/cluff/cluff.component';
import {ConsentFormComponent} from './pages/consent-form/consent-form.component';
import {LoginComponent} from './pages/login/login.component';
import {LostPasswordComponent} from './pages/lost-password/lost-password.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';

const routes: Routes = [{
  path: '',
  component: AuthLayoutComponent,
  children: [
    { path: 'login', component: LoginComponent, data: { title: 'AUTH_LOGIN_PAGE_TITLE' } },
    { path: 'callback', component: CallbackComponent, data: { title: 'AUTH_LOGIN_PAGE_TITLE' } },
    { path: 'lost-password', component: LostPasswordComponent, data: { title: 'AUTH_LOST_PASSWORD_PAGE_TITLE' } },
    { path: 'lost-password/:email', component: LostPasswordComponent, data: { title: 'AUTH_LOST_PASSWORD_PAGE_TITLE' } },
    { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'AUTH_RESET_PASSWORD_PAGE_TITLE' } },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'privacy-policy' } },
    { path: 'terms-of-use', component: CluffComponent, data: { title: 'terms-of-use' } },
    { path: 'consent-form', component: ConsentFormComponent, data: { title: 'consent-form' } }
  ]
}];

@NgModule({ imports: [RouterModule.forChild(routes)], exports: [RouterModule], providers: [OtpGuard] })
export class AuthRoutingModule {
}
