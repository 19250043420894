<table class="table {{additionalClassName}}"
       [class.table-striped]="striped"
       [class.table-bordered]="bordered"
       [class.table-hover]="hover"
       [class.table-condensed]="condensed"
       [class.master-details]="!!details">
  <thead>
  <tr *ngIf="!disablePagination || !!actions">
    <th [attr.colspan]="columns.length + (details ? 1 : 0)">
      <div *ngIf="!disablePagination" class="pull-left">
        {{ 'SHOW_X_ELEMENTS_START' | translate:locale.language }}
        <select [(ngModel)]="itemsPerPage" (change)="pageSizeChanged()">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="-1">{{'PAGE_SIZE_ALL' | translate:locale.language}}</option>
        </select>
        {{ 'SHOW_X_ELEMENTS_END' | translate:locale.language }}
      </div>
      <div *ngIf="actions" class="pull-right {{actions.className}}">
        <ng-template [ngTemplateOutlet]="actions.template"></ng-template>
      </div>
    </th>
  </tr>
  <tr>
    <th *ngIf="details">&nbsp;</th>
    <th *ngFor="let col of columns"
        [class]="col.className || ''"
        [class.sortable]="col.sortable"
        [class.sort-asc]="isSortedAsc(col.sortableProp || col.prop)"
        [class.sort-desc]="isSortedDesc(col.sortableProp || col.prop)"
        (click)="toggleSort(col.sortableProp || col.prop, col.sortable)">
      <ng-template [ngIf]="!col.headerTemplate">{{col.name}}</ng-template>
      <ng-template *ngIf="col.headerTemplate"
                   [ngTemplateOutlet]="col.headerTemplate"
                   [ngTemplateOutletContext]="{ column: col }"></ng-template>
    </th>
  </tr>
  </thead>
  <tbody *ngIf="data.length > 0">
  <ng-template ngFor let-row [ngForOf]="data">
    <tr>
      <td *ngIf="details">
        <i class="row-details"
           [class.row-details-close]="!isSelected(row)"
           [class.row-details-open]="isSelected(row)"
           (click)="toggleRowSelection(row)"></i>
      </td>
      <td *ngFor="let col of columns" [class]="col.className || ''">
        <ng-template [ngIf]="!col.cellTemplate">
          {{readProperty(row, col.prop)}}
        </ng-template>
        <ng-template *ngIf="col.cellTemplate"
                  [ngTemplateOutlet]="col.cellTemplate"
                  [ngTemplateOutletContext]="{ value: readProperty(row, col.prop), row: row, column: col }">
        </ng-template>
      </td>
    </tr>
    <tr *ngIf="details" class="collapse" [class.in]="isSelected(row)">
      <td [attr.colspan]="columns.length + 1">
        <ng-template [ngTemplateOutlet]="details.template" [ngTemplateOutletContext]="{ row: row }"></ng-template>
      </td>
    </tr>
  </ng-template>
  </tbody>
  <tbody *ngIf="data.length === 0">
  <tr>
    <td *ngIf="empty; else defaultEmpty"
        class="no-data {{empty.className}}"
        [attr.colspan]="columns.length + (details != undefined ? 1 : 0)">
      <ng-template [ngTemplateOutlet]="empty.template"></ng-template>
    </td>
    <ng-template #defaultEmpty>
      <td [attr.colspan]="columns.length + (details != undefined ? 1 : 0)" class="no-data">
        {{ emptyTextKey | translate:locale.language }}
      </td>
    </ng-template>
  </tr>
  </tbody>
  <tfoot *ngIf="!disablePagination">
  <tr>
    <td [attr.colspan]="columns.length + (details ? 1 : 0)">
      <div class="pull-left pagination-size">{{'PAGINATION_INFO' | translate:locale.language:pageInfo}}</div>
      <pagination [totalItems]="count"
                  [itemsPerPage]="itemsPerPage"
                  [boundaryLinks]="true"
                  [maxSize]="5"
                  [rotate]="false"
                  [firstText]="'PAGER_FIRST' | translate:locale.language"
                  [previousText]="'PAGER_PREVIOUS' | translate:locale.language"
                  [nextText]="'PAGER_NEXT' | translate:locale.language"
                  [lastText]="'PAGER_LAST' | translate:locale.language"
                  [(ngModel)]="currentPage"
                  (pageChanged)="gotoPage($event)"
                  class="pull-right"></pagination>
    </td>
  </tr>
  </tfoot>
</table>
