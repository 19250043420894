import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

import {BlockUIService} from './block-ui.service';

@Injectable({ providedIn: 'root' })
export class InterceptorSpinnerService implements HttpInterceptor {

  private count = 0;
  private prevCount = 0;
  private msg: string;

  constructor(public blockUIService: BlockUIService, private activatedRoute: ActivatedRoute) {}

  // tslint:disable-next-line:typedef
  manage(started, req, ok) {
    // Log when response observable either completes or errors
    const elapsed = Date.now() - started;
    if (req.method === 'GET') {
      this.count--;
      this.msg = `${req.method} '${req.urlWithParams}' ${ok} in ${elapsed} ms. / ${this.count}`;
    } else {
      this.msg = `${req.method} '${req.urlWithParams}' ${ok} in ${elapsed} ms.`;
    }
    if (isDevMode()) {
      // TODO: remove domain from logs.
      // tslint:disable-next-line:no-console
      console.log('[INTERCEPTOR]: ', this.msg);
    }
    if (this.count <= 0) {
      // this.progress.done();
      this.blockUIService.endXHR('')
    }
  }

  // tslint:disable-next-line:no-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    if (req.method === 'GET') {
      this.count++;
    }

    if (this.count === 1) {
      this.prevCount++;

      if (!window.location.href.includes('music-preview')) {
        // this.blockUIService.startXHR('')
      }
    }

    // tslint:disable-next-line:no-any
    const handleObs: Observable<HttpEvent<any>> = next.handle(req);
    return handleObs.pipe(tap(event => ok = (event instanceof HttpResponse) ? 'succeed' : '', error => {
                            this.manage(started, req, ok);
                            ok = 'error'
                          }), finalize(() => { this.manage(started, req, ok); }));
  }
}
