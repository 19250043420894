import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadSfTicketDetailSuccessAction, SfTicketDetailActionTypes} from '../actions/SF_ticket_detail';

export interface SFTicketDetailState {
  array: any[];
}

const initialState: SFTicketDetailState = {
  array: []
};

export function sfTicketDetailReducer(state = initialState, action: Action): SFTicketDetailState {
  switch (action.type) {
    case SfTicketDetailActionTypes.LOAD:
      return handleLoadAction();
    case SfTicketDetailActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case SfTicketDetailActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): SFTicketDetailState { return initialState; }

function handleLoadSuccessAction(state: SFTicketDetailState, action: LoadSfTicketDetailSuccessAction): SFTicketDetailState {
  const newState = _.clone(state);
  console.log(newState, action.payload)
  newState.array = action.payload[0];
  return newState;
}

function handleLoadFailAction(state: SFTicketDetailState): SFTicketDetailState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const sfTicketDetailSelectors = {
  array: (state: SFTicketDetailState) => _.get(state, 'array', [])
};
