import {Action} from '@ngrx/store';

import {ArrayResponse, type} from '../../shared';
import {Command} from '../models/command';
import {CommandsFilterForm} from '../models/commands-filter-form';

export const CommandsActionTypes = {
  TOGGLE_SEARCH: type('[Commands] Toggle Search Form'),
  SEARCH: type('[Commands] Search'),
  CHANGE_PAGE_SIZE: type('[Commands] Change Page Size'),
  PAGE_TO: type('[Commands] Page To'),
  ORDER_BY: type('[Commands] Order By'),
  LOAD: type('[Commands] Load'),
  LOAD_SUCCESS: type('[Commands] Load Success'),
  LOAD_FAIL: type('[Commands] Load Fail'),
  CLEAR: type('[Commands] Clear Data')
};

export class ToggleSearchCommandsAction implements Action {
  readonly type = CommandsActionTypes.TOGGLE_SEARCH;

  constructor(public payload?: boolean) {}
}

export class SearchCommandsAction implements Action {
  readonly type = CommandsActionTypes.SEARCH;

  constructor(public payload?: CommandsFilterForm) {}
}

export class ChangeCommandsPageSizeAction implements Action {
  readonly type = CommandsActionTypes.CHANGE_PAGE_SIZE;

  constructor(public payload?: number) {}
}

export class PageCommandsToAction implements Action {
  readonly type = CommandsActionTypes.PAGE_TO;

  constructor(public payload?: number) {}
}

export class OrderCommandsByAction implements Action {
  readonly type = CommandsActionTypes.ORDER_BY;

  constructor(public payload?: string) {}
}

export class LoadCommandsAction implements Action {
  readonly type = CommandsActionTypes.LOAD;

  constructor() {}
}

export class LoadCommandsSuccessAction implements Action {
  readonly type = CommandsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ArrayResponse<Command>) {}
}

export class LoadCommandsFailAction implements Action {
  readonly type = CommandsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}

export class ClearCommandsAction implements Action {
  readonly type = CommandsActionTypes.CLEAR;

  constructor() {}
}
