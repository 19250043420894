<div *ngIf="isNew; then newPassword else resetPassword"></div>
<ng-template #newPassword>
  <form class="reset-form" [formGroup]="resetPasswordForm" (ngSubmit)="submitForm(resetPasswordForm.value)" novalidate>
    <h3 class="font-brand" translate>AUTH_NEW_PASSWORD_TITLE</h3>
    <p translate>AUTH_NEW_PASSWORD_PARA</p>
    <storever-send-back-result [result]="resultCode">
      <div class="alert alert-danger error" translate>AUTH_NEW_PASSWORD_ERROR</div>
      <div class="alert alert-success success">
        {{ 'AUTH_NEW_PASSWORD_SUCCESS' | translate:locale.language }}
        <a [routerLink]="['/login']" class="alert-link" translate>AUTH_GOTO_LOGIN_PAGE</a>.
      </div>
    </storever-send-back-result>
    <input type="hidden" formControlName="email"/>
    <input type="hidden" formControlName="code"/>
    <div class="form-group">
      <div class="new-password">
        <label class="control-label visible-ie8 visible-ie9" translate>AUTH_NEW_PASSWORD_NEW</label>
        <div class="input-group password-toggle">
          <input #newPasswordCtrl class="form-control form-control-solid placeholder-no-fix" type="password" autocomplete="off"
                 [placeholder]="'AUTH_NEW_PASSWORD_NEW' | translate:locale.language" formControlName="newPassword"/>
          <a (click)="newPasswordCtrl.type = newPasswordCtrl.type === 'password' ? 'text' : 'password'">Toggle</a>
          <span class="input-group-addon strength-desc"
                [class.very-weak]="score === 'VERY_WEAK'"
                [class.weak]="score === 'WEAK'"
                [class.reasonable]="score === 'REASONABLE'"
                [class.strong]="score === 'STRONG'"
                [class.very-strong]="score === 'VERY_STRONG'"
                [popover]="strengthDesc"
                [popoverTitle]="'CHANGE_PASSWORD_STRENGTH' | translate:locale.language"
                triggers="mouseenter:mouseleave"
                placement="left">{{strength}}</span>
        </div>
        <div class="strength-bar"
             [class.very-weak]="score === 'VERY_WEAK'"
             [class.weak]="score === 'WEAK'"
             [class.reasonable]="score === 'REASONABLE'"
             [class.strong]="score === 'STRONG'"
             [class.very-strong]="score === 'VERY_STRONG'"></div>
      </div>
      <span *ngIf="resetPasswordForm.get('newPassword').dirty && resetPasswordForm.get('newPassword').hasError('required')"
            class="help-block error" translate>AUTH_NEW_PASSWORD_NEW_REQUIRED</span>
      <span *ngIf="resetPasswordForm.get('newPassword').dirty && resetPasswordForm.get('newPassword').hasError('minlength')"
            class="help-block error" translate>AUTH_NEW_PASSWORD_NEW_MINLENGTH</span>
    </div>
    <div class="form-group confirm-password">
      <label class="control-label visible-ie8 visible-ie9" translate>AUTH_NEW_PASSWORD_CONFIRM</label>
      <div class="password-toggle">
        <input #confirmPasswordCtrl class="form-control form-control-solid placeholder-no-fix" type="password" autocomplete="off"
               [placeholder]="'AUTH_NEW_PASSWORD_CONFIRM' | translate:locale.language" formControlName="confirmPassword"/>
        <a (click)="confirmPasswordCtrl.type = confirmPasswordCtrl.type === 'password' ? 'text' : 'password'">Toggle</a>
      </div>
      <span *ngIf="resetPasswordForm.get('confirmPassword').hasError('equalTo')"
            class="help-block error" translate>AUTH_NEW_PASSWORD_CONFIRM_MISMATCH</span>
    </div>
    <div class="form-actions">
      <button type="submit" class="btn brand uppercase pull-right" [disabled]="!resetPasswordForm.valid || submitting">
        <ng-template #default>{{ 'AUTH_NEW_PASSWORD_SUBMIT' | translate:locale.language }}</ng-template>
        <ng-template [ngIf]="submitting" [ngIfElse]="default">{{ 'AUTH_NEW_PASSWORD_SUBMITTING' | translate:locale.language }}</ng-template>
      </button>
    </div>
  </form>
  <storever-block-ui [isRunning]="submitting"></storever-block-ui>
</ng-template>
<ng-template #resetPassword>
  <form class="reset-form" [formGroup]="resetPasswordForm" (ngSubmit)="submitForm(resetPasswordForm.value)" novalidate>
    <h3 class="font-brand" translate>AUTH_RESET_PASSWORD_TITLE</h3>
    <p translate>AUTH_RESET_PASSWORD_PARA</p>
    <storever-send-back-result [result]="resultCode">

      <div class="alert alert-danger error" translate>{{errorMsg}}</div>
      <div class="alert alert-success success">
        {{ 'AUTH_RESET_PASSWORD_SUCCESS' | translate:locale.language }}
        <a [routerLink]="['/login']" class="alert-link" translate>AUTH_GOTO_LOGIN_PAGE</a>.
      </div>
    </storever-send-back-result>
    <input type="hidden" formControlName="email"/>
    <input type="hidden" formControlName="code"/>
    <div class="form-group">
      <div class="new-password">
        <label class="control-label visible-ie8 visible-ie9" translate>AUTH_RESET_PASSWORD_NEW</label>
        <div class="input-group password-toggle">
          <input #newPasswordCtrl2 class="form-control form-control-solid placeholder-no-fix" type="password" autocomplete="off"
                 [placeholder]="'AUTH_NEW_PASSWORD_NEW' | translate:locale.language" formControlName="newPassword"/>
          <a (click)="newPasswordCtrl2.type = newPasswordCtrl2.type === 'password' ? 'text' : 'password'">Toggle</a>
          <span class="input-group-addon strength-desc"
                [class.very-weak]="score === 'VERY_WEAK'"
                [class.weak]="score === 'WEAK'"
                [class.reasonable]="score === 'REASONABLE'"
                [class.strong]="score === 'STRONG'"
                [class.very-strong]="score === 'VERY_STRONG'"
                [popover]="strengthDesc"
                [popoverTitle]="'CHANGE_PASSWORD_STRENGTH' | translate:locale.language"
                triggers="mouseenter:mouseleave"
                placement="left">{{strength}}</span>
        </div>
        <div class="strength-bar"
             [class.very-weak]="score === 'VERY_WEAK'"
             [class.weak]="score === 'WEAK'"
             [class.reasonable]="score === 'REASONABLE'"
             [class.strong]="score === 'STRONG'"
             [class.very-strong]="score === 'VERY_STRONG'"></div>
      </div>
      <span *ngIf="resetPasswordForm.get('newPassword').dirty && resetPasswordForm.get('newPassword').hasError('required')"
            class="help-block error" translate>AUTH_RESET_PASSWORD_NEW_REQUIRED</span>
      <span *ngIf="resetPasswordForm.get('newPassword').dirty && resetPasswordForm.get('newPassword').hasError('minlength')"
            class="help-block error" translate>AUTH_RESET_PASSWORD_NEW_MINLENGTH</span>
    </div>
    <div class="form-group confirm-password">
      <label class="control-label visible-ie8 visible-ie9" translate>AUTH_RESET_PASSWORD_CONFIRM</label>
      <div class="password-toggle">
        <input #confirmPasswordCtrl2 class="form-control form-control-solid placeholder-no-fix" type="password" autocomplete="off"
               [placeholder]="'AUTH_NEW_PASSWORD_CONFIRM' | translate:locale.language" formControlName="confirmPassword"/>
        <a (click)="confirmPasswordCtrl2.type = confirmPasswordCtrl2.type === 'password' ? 'text' : 'password'">Toggle</a>
      </div>
      <span *ngIf="resetPasswordForm.get('confirmPassword').hasError('equalTo')"
            class="help-block error" translate>AUTH_RESET_PASSWORD_CONFIRM_MISMATCH</span>
    </div>
    <div class="form-actions">
      <button type="submit" class="btn brand uppercase pull-right" [disabled]="!resetPasswordForm.valid || submitting">
        <ng-template #default>{{ 'AUTH_RESET_PASSWORD_SUBMIT' | translate:locale.language }}</ng-template>
        <ng-template [ngIf]="submitting" [ngIfElse]="default">{{ 'AUTH_RESET_PASSWORD_SUBMITTING' | translate:locale.language }}</ng-template>
      </button>
    </div>
  </form>
  <storever-block-ui [isRunning]="submitting"></storever-block-ui>
</ng-template>
