import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';

import {AppSettingsService, BaseRestService, GroupRole, GroupRoleTemplate} from '../../shared';
import {SendBackResult} from '../../shared/models/send-back-result';
import {SendBackResultCode} from '../../shared/models/send-back-result-code.enum';
import {GroupRoleEx} from '../models/group-role-ex';

@Injectable({ providedIn: 'root' })
export class GroupRolesExService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'GroupRolesExService'; }

  protected get isAnonymous(): boolean { return false; }

  getOne(clientId: string, groupRoleId: number): Observable<SendBackResult<GroupRoleEx>> {
    if (groupRoleId === 0) {
      return of({ data: undefined, statusCode: SendBackResultCode.Success, message: undefined, log: undefined });
    }
    return this.get<GroupRoleEx>(`/clients/${clientId}/groups-roles/${groupRoleId}`);
  }

  create(clientId: string, groupRole: GroupRole): Observable<SendBackResult<GroupRoleEx>> {
    return this.post<GroupRoleEx>(`/clients/${clientId}/groups-roles`, groupRole);
  }

  createTemplate(clientId: string, groupRoleTemplate: GroupRoleTemplate): Observable<SendBackResult<GroupRoleEx>> {
    return this.post<GroupRoleEx>(`/clients/${clientId}/groups-roles`, groupRoleTemplate);
  }

  update(clientId: string, groupRoleId: number, groupRole: GroupRoleEx): Observable<SendBackResult<GroupRoleEx>> {
    return this.put<GroupRoleEx>(`/clients/${clientId}/groups-roles/${groupRoleId}`, groupRole);
  }

  remove(clientId: string, groupRoleId: number): Observable<Response> { return this.delete(`/clients/${clientId}/groups-roles/${groupRoleId}`); }

  moveUsers(clientId: string, fromGroupRoleId: number, toGroupRoleId: number): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/clients/${clientId}/group-roles/${fromGroupRoleId}/move`, toGroupRoleId);
  }
}
