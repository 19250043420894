import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {L10N_LOCALE, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {ImageResult, Options} from 'ngx-image2dataurl';
import {MessageService} from 'primeng/api';

import {ErrorToastAction} from '../..';
import * as fromShared from '../../reducers';

const DEFAULT_IMAGE_URL = 'https://www.placehold.it/200x150/EFEFEF/AAAAAA?text=no+image';

@Component({
  selector: 'storever-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeAvatarComponent {
  @Input() submitting = false;
  @Output() saveAvatar = new EventEmitter<string>();
  src = DEFAULT_IMAGE_URL;
  resizeOptions: Options = { resize: { maxHeight: 150, maxWidth: 200 }, allowedExtensions: ['jpg', 'jpeg', 'png'] };
  imageData: string;

  get isDefaultImage(): boolean { return _.isEmpty(this.src) || this.src === DEFAULT_IMAGE_URL; }

  constructor(private messageService: MessageService,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              cdr: ChangeDetectorRef,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService) {}

  imageSelected(imageResult: ImageResult): void {
    if (!_.isEmpty(imageResult.error)) {
      switch (imageResult.error) {
        case 'Extension Not Allowed':
          this.store.dispatch(new ErrorToastAction(
            { message: this.translation.translate('IMAGE_UPLOAD_INVALID_EXTENSION'), title: this.translation.translate('TOAST_ERROR_TITLE') }));
          break;
        case 'Image processing error':
          this.store.dispatch(new ErrorToastAction(
            { message: this.translation.translate('IMAGE_UPLOAD_PROCESSING_ERROR'), title: this.translation.translate('TOAST_ERROR_TITLE') }));
          break;
        default:
          this.store.dispatch(new ErrorToastAction(
            { message: this.translation.translate('IMAGE_UPLOAD_GENERIC_ERROR'), title: this.translation.translate('TOAST_ERROR_TITLE') }));
          break;
      }
    } else {
      this.src = (imageResult.resized && imageResult.resized.dataURL) || imageResult.dataURL;
      this.imageData = imageResult.dataURL;
    }
  }

  removeImage(): void {
    this.src = DEFAULT_IMAGE_URL;
    this.imageData = undefined;
  }

  submitForm(): void {
    if (!_.isEmpty(this.imageData)) {
      this.saveAvatar.emit(this.imageData);
    }

    this.removeImage();
  }

  hasChanges(): boolean { return !this.isDefaultImage; }
}
