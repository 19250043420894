import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {DEFAULT_PAGE_SIZE} from '../../consts';
import {QuickSearchResult} from '../../models/quick-search-result';

@Component({
  selector: 'storever-quick-search-results',
  templateUrl: './quick-search-results.component.html',
  styleUrls: ['./quick-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickSearchResultsComponent implements OnChanges {
  @ViewChild('quickSearchResultsModal') modal: ModalDirective;
  @Input() results: QuickSearchResult[] = [];
  @Input() count = 0;
  @Input() currentPage = 1;
  @Input() itemsPerPage = DEFAULT_PAGE_SIZE;
  @Input() orderBy: string;
  @Input() showModal = false;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageSizeChanged = new EventEmitter<number>();
  @Output() orderByChanged = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();
  @Output() gotoPlayer = new EventEmitter<QuickSearchResult>();

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'showModal')) {
      const change = _.get(changes, 'showModal');
      const value: boolean = change.currentValue;
      if (this.modal) {
        if (value) {
          this.modal.show();
        } else {
          this.modal.hide();
        }
      }
    }
  }
}
