import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ArrayResponse} from '../models/array-response';
import {Client} from '../models/client';
import {SendBackResult} from '../models/send-back-result';
import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class ClientsService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'ClientsService'; }

  protected get isAnonymous(): boolean { return false; }

  getMine(): Observable<SendBackResult<ArrayResponse<Client>>> { return this.get<ArrayResponse<Client>>(`/clients/mine`); }

  getOne(clientId: string): Observable<SendBackResult<Client>> { return this.get<Client>(`/clients/${clientId}`); }
}
