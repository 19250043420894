import {Action} from '@ngrx/store';

import {type} from '../utilities/type';

export const SfTicketDetailActionTypes = {
  LOAD: type('[SfTicketDetail] Load'),
  LOAD_SUCCESS: type('[SfTicketDetail] Load Success'),
  LOAD_FAIL: type('[SfTicketDetail] Load Fail')
};

export class LoadSfTicketDetailAction implements Action {
  readonly type = SfTicketDetailActionTypes.LOAD;

  constructor(public payload?: string) { console.log(payload) }
}

export class LoadSfTicketDetailSuccessAction implements Action {
  readonly type = SfTicketDetailActionTypes.LOAD_SUCCESS;

  constructor(public payload?: any[]) {}
}

export class LoadSfTicketDetailFailAction implements Action {
  readonly type = SfTicketDetailActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
