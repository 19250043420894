
<div id="content">

  <div id="menu-top">
    <button (click)="goBack()" class="btn default">{{'GO_BACK' | translate:locale.language}}</button>
    <div id="flags">
      <img (click)="setCurrentLanguage('fr')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/france-3-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('en')" src="https://cdn2.iconfinder.com/data/icons/flags/flags/48/united-kingdom-great-britain.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('de')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/germany-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('nl')" src="https://cdn4.iconfinder.com/data/icons/world-flags-6/900/netherlands_dutch_holland_national_country_flag-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('es')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/spain-128.png" alt="" width="25px"/>
    </div>
  </div>



  <span id="terms-of-use"><h1>MCube Group</h1></span>

  <span class="title">{{'CLUF_TERMS_OF_USE' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_TERMS_OF_USE_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_USE_OF_OUR_SERVICES' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_USE_OF_OUR_SERVICES_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_PROTECTION_OF_PRIVACY' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_PROTECTION_OF_PRIVACY_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_CONTENT' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_CONTENT_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_AMENDMENT_AND_TERMINATION' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_AMENDMENT_AND_TERMINATION_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_WARRANTIES_AND_DISCLAIMERS' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_WARRANTIES_AND_DISCLAIMERS_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_ACCESS_RIGHTS' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_ACCESS_RIGHTS_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_CHANGES_TO_THE_TERMS' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_CHANGES_TO_THE_TERMS_TEXT' | translate:locale.language}}</span>

  <span class="title">{{'CLUF_APPLICABLE_LAW' | translate:locale.language}}</span>
  <span class="texte">{{'CLUF_APPLICABLE_LAW_TEXT' | translate:locale.language}}</span>
</div>

