import * as _ from 'lodash';
import * as moment from 'moment';
import {AlertPlayersDeconnect} from '../../players/models/alert-player-deconnect';
import {AlertPlayerDeconnectActionTypes, EditAlertPlayerDeconnectAction} from '../actions/alerts-player-deconnect';

export interface AlertPlayersDeconnectState {
  array: AlertPlayersDeconnect[];
}

const initialState: AlertPlayersDeconnectState = {
  array: []
};
export function alertPlayersDeconnectReducer(state = initialState, action: any): AlertPlayersDeconnectState {
  switch (action.type) {
    case AlertPlayerDeconnectActionTypes.LOAD_SUCCESS:
    case AlertPlayerDeconnectActionTypes.SAVE_SUCCESS:
      return action.payload;
    case AlertPlayerDeconnectActionTypes.ADD:
      return handleAddAction(state);
    case AlertPlayerDeconnectActionTypes.DELETE_SUCCESS:
      return handleDeleteAddedAction(state, action);
    case AlertPlayerDeconnectActionTypes.EDIT:
      return handleEditAction(state, action);
    case AlertPlayerDeconnectActionTypes.FAIL:
      return state;
    default:
      return state;
  }
}

function handleDeleteAddedAction(state: AlertPlayersDeconnectState, action: any): AlertPlayersDeconnectState {
  const newState = _.clone(state);
  newState.array = newState.array.filter(item => item.id !== action.payload.payload);
  return newState;
}

function handleEditAction(state: AlertPlayersDeconnectState, action: EditAlertPlayerDeconnectAction): AlertPlayersDeconnectState {
  const newState = _.clone(state);
  newState.array = newState.array.map(item => item.id === action.payload.id ? Object.assign({}, item, action.payload) : item);
  return newState;
}
function handleAddAction(state: AlertPlayersDeconnectState): AlertPlayersDeconnectState {
  const newState = _.clone(state);
  newState.array.push({ id: 0, created: moment(), updated: moment(), frequency: 'D', services: 0, isActive: true, sending: false });
  return newState;
}

export const alertsPlayersDeconnectSelectors = {
  array: (state: AlertPlayersDeconnectState) => _.get(state, 'array', [])
};
