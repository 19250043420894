import {Action} from '@ngrx/store';
import {type} from '../../shared';
import {PlayerReportOffline} from '../models/player-report';

export const PlayerReportActionTypes = {
  LOAD: type('[PLAYER REPORT] OFFLINE'),
  LOAD_SUCCESS: type('[PLAYER REPORT] OFFLINE SUCCESS'),
  LOAD_FAIL: type('[PLAYER REPORT] OFFLINE FAIL'),
};

export class LoadPlayerReportOfflineAction implements Action {
  readonly type = PlayerReportActionTypes.LOAD;
  constructor(public payload: string) {} // reportid
}

export class LoadPlayerReportOfflineSuccessAction implements Action {
  readonly type = PlayerReportActionTypes.LOAD_SUCCESS;
  constructor(public payload: PlayerReportOffline) {}
}

export class LoadPlayerReportOfflineFailAction implements Action {
  readonly type = PlayerReportActionTypes.LOAD_FAIL;
  constructor(public payload: number) {}
}
