import {Action} from '@ngrx/store';

import {GroupRole} from '../models/group-role';
import {type} from '../utilities/type';

export const GroupRolesActionTypes = {
  LOAD: type('[Group Roles] Load'),
  LOAD_SUCCESS: type('[Group Roles] Load Success'),
  LOAD_FAIL: type('[Group Roles] Load Fail')
};

export class LoadGroupRolesAction implements Action {
  readonly type = GroupRolesActionTypes.LOAD;
  constructor() {}
}

export class LoadGroupRolesSuccessAction implements Action {
  readonly type = GroupRolesActionTypes.LOAD_SUCCESS;
  constructor(public payload?: GroupRole[]) {}
}

export class LoadGroupRolesFailAction implements Action {
  readonly type = GroupRolesActionTypes.LOAD_FAIL;
  constructor(public payload?: number) {}
}
