import {Location} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';

import {ACCESS_TOKEN} from '../../../shared/consts';
import {tokenNotExpired} from '../../../shared/utilities/token-not-expired';

@Component({ selector: 'storever-privacy-policy', templateUrl: './privacy-policy.component.html', styleUrls: ['./privacy-policy.component.scss'] })
export class PrivacyPolicyComponent implements OnInit, OnDestroy, AfterViewInit {

  lang: string;
  currentLang: string;
  fragment: string;
  // tslint:disable-next-line:no-any
  param: any;
  consentForm = false;

  constructor(private _location: Location,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private route: ActivatedRoute,
              private _router: Router,
              cdr: ChangeDetectorRef) {

    // this.currentLang = this.locale.getCurrentLanguage();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngOnInit(): void {
    this.param = window.location.search;
    this.param = this.param.split('&');
    let j;
    for (j = 0; j < this.param.length; j++) {
      if (this.param[j].toLowerCase().indexOf('consent-form') !== -1) {
        this.consentForm = true;
      }
    }
    if (!tokenNotExpired(ACCESS_TOKEN)) {
      if (!this.consentForm) {
        this._router.navigateByUrl('/login');
      }
    }
  }
  // tslint:disable-next-line:no-any
  deCode(q: any): any { return unescape(this.param[q].split('=')[1].replace(/\s/g, '')); }

  ngOnDestroy(): void {
    // this.locale.setCurrentLanguage(this.currentLang);
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) {
    }
  }

  setCurrentLanguage(lang: string): void {
    // this.locale.setCurrentLanguage(lang);
  }

  goBack(): void { this._location.back(); }
}
