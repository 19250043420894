

<div id="content">

  <div id="menu-top">
    <!--<button (click)="goBack()" class="btn default">{{'GO_BACK' | translate:lang}}</button>-->
    <div id="flags">
      <img (click)="setCurrentLanguage('fr')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/france-3-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('en')" src="https://cdn2.iconfinder.com/data/icons/flags/flags/48/united-kingdom-great-britain.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('de')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/germany-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('nl')" src="https://cdn4.iconfinder.com/data/icons/world-flags-6/900/netherlands_dutch_holland_national_country_flag-128.png" alt="" width="25px"/>
      <img (click)="setCurrentLanguage('es')" src="https://cdn0.iconfinder.com/data/icons/flags-of-the-world-2/128/spain-128.png" alt="" width="25px"/>
    </div>
  </div>



  <!--<span id="terms-of-use"><h1>STOREVER</h1></span>-->

  <!--<span class="title">{{'CLUF_TERMS_OF_USE' | translate:lang}}</span>-->
  <!--<span class="texte">{{'CLUF_TERMS_OF_USE_TEXT' | translate:lang}}</span>-->

  <div >
    <p>{{concentForm.text1}}
      <a href="/privacy-policy?consent-form=true" >{{concentForm.privacy_policy}}</a>
      {{concentForm.text2}}
      <a href="/terms-of-use?consent-form=true" fragment="terms-of-use" title="" rel="license" >{{concentForm.terms_of_use}}</a>
      {{concentForm.text3}}</p>
    <p>{{concentForm.text4}}</p>
    <div align="center">
      <a class="btn default" (click)="redirectionForm()">{{concentForm.button}}</a>
    </div>

  </div>
</div>

