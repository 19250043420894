import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {catchError, delayWhen, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';

import {debug} from '../../../rxjs-operators';
import {GroupRolesActionTypes, LoadGroupRolesFailAction, LoadGroupRolesSuccessAction} from '../actions/group-roles';
import {error} from '../actions/toasts';
import {ArrayResponse} from '../models/array-response';
import {GroupRole} from '../models/group-role';
import {SendBackResult} from '../models/send-back-result';
import * as fromShared from '../reducers';
import {GroupRolesService} from '../services/group-roles.service';

import {BaseEffect} from './base.effect';

@Injectable()
export class GroupRolesEffect extends BaseEffect {
  private client$ = this.store.pipe(select(fromShared.selectors.getCurrentClient), filter(client => !_.isEmpty(client)));

  @Effect()
  load$ = this.actions$.pipe(
    ofType(GroupRolesActionTypes.LOAD),
    delayWhen(() => this.client$),
    debug('Group roles load action received.'),
    withLatestFrom(this.client$),
    switchMap(([action, client]) => this.groupRolesService.getList(client.uuid)
                                      .pipe(map((payload: SendBackResult<ArrayResponse<GroupRole>>) => new LoadGroupRolesSuccessAction(payload.data.array)),
                                            catchError((res: Response) => this.catchResponseError(res)))));

  @Effect()
  loadFail$ = this.actions$.pipe(ofType(GroupRolesActionTypes.LOAD_FAIL),
                                 debug('A server error occurred while retrieving group roles list.'),
                                 map(() => error(this.translation.translate('GROUP_ROLES_LOAD_ERROR'), this.translation.translate('TOAST_ERROR_TITLE'))));

  constructor(private actions$: Actions,
              private store: Store<fromShared.AppState>,
              private translation: L10nTranslationService,
              private groupRolesService: GroupRolesService,
              router: Router) {
    super(router);
  }

  protected handleUnhandledError(response: Response): Action { return new LoadGroupRolesFailAction(response.status); }
}
