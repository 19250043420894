import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadTimezonesSuccessAction, TimezonesActionTypes} from '../actions/timezones';
import {Timezone} from '../models/timezone';

export interface TimezonesState {
  array: Timezone[];
}

const initialState: TimezonesState = {
  array: []
};

export function timezonesReducer(state = initialState, action: Action): TimezonesState {
  switch (action.type) {
    case TimezonesActionTypes.LOAD:
      return handleLoadAction();
    case TimezonesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case TimezonesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): TimezonesState {
  return initialState;
}

function handleLoadSuccessAction(state: TimezonesState, action: LoadTimezonesSuccessAction): TimezonesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: TimezonesState): TimezonesState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const timezonesSelectors = {
  array: (state: TimezonesState) => _.get(state, 'array', [])
};
