import {Action} from '@ngrx/store';

import {type} from '../../shared';
import {AudioProgram} from '../models/audio-program';

export const AudioProgramsActionTypes = {
  LOAD: type('[Audio Programs] Load'),
  LOAD_SUCCESS: type('[Audio Programs] Load Success'),
  LOAD_FAIL: type('[Audio Programs] Load Fail')
};

export class LoadAudioProgramsAction implements Action {
  readonly type = AudioProgramsActionTypes.LOAD;

  constructor() {}
}

export class LoadAudioProgramsSuccessAction implements Action {
  readonly type = AudioProgramsActionTypes.LOAD_SUCCESS;

  constructor(public payload?: AudioProgram[]) {}
}

export class LoadAudioProgramsFailAction implements Action {
  readonly type = AudioProgramsActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
