import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {L10N_LOCALE, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as auth0 from 'auth0-js';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable, of, Subscription, throwError, timer} from 'rxjs';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';

import {ACCESS_TOKEN, DEFAULT_RETURN_URL, EXPIRES_AT, ID_TOKEN, MANAGEMENT_API_TOKEN, RETURN_URL, USER_INFO} from '../consts';
import {Auth0UserInfo} from '../models/auth0-user-info';
import {JwtHelper} from '../utilities/jwt-helper';

import {AppSettingsService} from './app-settings.service';
import {BaseService} from './base.service';
import {BlockUIService} from './block-ui.service';

@Injectable({ providedIn: 'root' })
export class Auth0AuthenticationService extends BaseService {
  handleAuthenticationError: string;
  private webAuth$: Observable<auth0.WebAuth>;
  private parseHashSubscription: Subscription;
  private renewAuthSubscription: Subscription;
  private refreshSubscription: Subscription;

  constructor(private appSettingsService: AppSettingsService,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private router: Router,
              private translation: L10nTranslationService,
              private blockUIService: BlockUIService) {
    super();
    this.webAuth$ = this.appSettingsService.settings$.pipe(map(settings => new auth0.WebAuth({
      clientID: settings.auth0.clientId,
      domain: settings.auth0.domain,
      responseType: 'token id_token',
      audience: 'https://api.storever.com/',
      redirectUri: `${location.protocol}//${location.host}/callback`,
      scope: 'openid profile email' // TODO add scope from storever api
    })));
    this.scheduleRenewal();
  }

  login(): void {

    this.webAuth$
      .pipe(withLatestFrom(this.appSettingsService.settings$.pipe(map(settings => settings.brand)),
                           this.appSettingsService.settings$.pipe(map(settings => settings.api))))
      .subscribe(([webAuth, brand, api]) => {
        // config params for hosted page
        const options: auth0.AuthorizeOptions = {};
        _.set(options, 'lang', this.locale.language);
        _.set(options, 'logo', `${location.protocol}//${location.host}/assets/images/brands/${brand.id}/logo-auth.png`);
        _.set(options, 'primary_color', brand.primaryColor);
        _.set(options, 'api_url', api.url);
        _.set(options, 'forgot_password_link', `${location.protocol}//${location.host}/lost-password`);
        // end config params for hosted page
        webAuth.authorize(options);
      });
  }

  handleAuthentication(): void {
    if (this.parseHashSubscription) {
      this.parseHashSubscription.unsubscribe();
    }

    this.handleAuthenticationError = undefined;
    this.parseHashSubscription = this.webAuth$.subscribe(webAuth => webAuth.parseHash((err, authResult) => {
      if (err) {
        this.error('Authentication error (see details for more info):', err);
        if (err instanceof Error) {
          this.handleAuthenticationError = `[${err.name}] ${err.message}`;
        } else {
          const error: auth0.Auth0Error = err;
          this.handleAuthenticationError = `[${error.error}] ${error.errorDescription}`;
        }
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.redirect();
      } else {
        this.warn('No authentication occurred?', authResult);
      }
    }));
  }

  logout(returnTo: string): void {
    this.blockUIService.show();
    this.unscheduleRenewal();
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(MANAGEMENT_API_TOKEN);
    localStorage.removeItem(RETURN_URL);
    localStorage.removeItem(USER_INFO);
    this.webAuth$.pipe(withLatestFrom(this.appSettingsService.settings$.pipe(map(settings => settings.auth0.clientId)))).subscribe(([webAuth, clientID]) => {
      const options: auth0.LogoutOptions = { returnTo, clientID };
      webAuth.logout(options);
    });
    sessionStorage.clear();
  }

  // tslint:disable-next-line:no-any
  getUserInfo(reload: boolean = false): Observable<any> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (!accessToken) {
      return throwError(new Error('Access token must exist to fetch user info.'));
    }

    if (reload) {
      localStorage.removeItem(USER_INFO);
    }

    const userInfoCache = JSON.parse(localStorage.getItem(USER_INFO));
    if (userInfoCache) {
      if (this.isCurrentUser(userInfoCache, accessToken)) {
        return of(userInfoCache);
      } else {
        localStorage.removeItem(USER_INFO);
      }
    }

    return this.webAuth$.pipe(switchMap(webAuth => Observable.create(observer => webAuth.client.userInfo(accessToken, (err, userInfo) => {
      if (err) {
        observer.error(err);
      } else {
        localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
        observer.next(userInfo);
      }

      observer.complete();
    }))));
  }

  // linkIdentity(connection: string, primaryUserId: string): Observable<any> {
  //   return this.appSettingsService.settings$
  //     .map(settings => {
  //       // config params for hosted page
  //       const options: auth0.AuthorizeOptions = { connection, redirectUri: `${location.protocol}//${location.host}/silent.html` };
  //       _.set(options, 'link_to', primaryUserId);
  //       _.set(options, 'lang', this.translation.getLanguage());
  //       _.set(options, 'logo', `${location.protocol}//${location.host}/assets/images/brands/${settings.brand.id}/logo-auth.png`);
  //       _.set(options, 'primary_color', settings.brand.primaryColor);
  //       _.set(options, 'api_url', settings.api.url);
  //       _.set(options, 'forgot_password_link', `${location.protocol}//${location.host}/lost-password`);
  //       // end config params for hosted page
  //       return options;
  //     })
  //     .withLatestFrom(this.webAuth$)
  //     .switchMap(([options, webAuth]) => Observable.create(observer => webAuth.popup.authorize(options, (err, result) => {
  //       if (err) {
  //         observer.error(err);
  //       } else {
  //         observer.next(result);
  //       }
  //
  //       observer.complete();
  //     })));
  // }

  private scheduleRenewal(): void {
    // tslint:disable-next-line:variable-name
    const expires_at = JSON.parse(localStorage.getItem(EXPIRES_AT));
    if (expires_at <= new Date().getTime()) {
      // access token is already expired!
      return;
    }

    this.log('Schedule the refresh of the tokens.');
    this.unscheduleRenewal();
    const source = of(expires_at).pipe(switchMap(expiresAt => {
      const now = Date.now();

      // Use half the delay in a timer to run the refresh before the tokens expire.
      return timer(Math.max(1, (expiresAt - now) / 2));
    }));

    // Once the delay time from above is reached, get a new JWT.
    this.refreshSubscription = source.subscribe(() => this.renewAuthentication());
  }

  private unscheduleRenewal(): void {
    if (this.refreshSubscription) {
      this.log('Unchedule the refresh of the tokens.');
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  private renewAuthentication(): void {
    if (this.renewAuthSubscription) {
      this.renewAuthSubscription.unsubscribe();
    }

    this.renewAuthSubscription = this.webAuth$.subscribe(webAuth => {
      this.log('Renewing access token using silent authentication.');
      const options: auth0.RenewAuthOptions = { redirectUri: `${location.protocol}//${location.host}/silent.html`, usePostMessage: true };
      webAuth.renewAuth(options, (err, result) => {
        if (err) {
          this.error('Failed to renew access token (see details for more info):', err);
          return;
        }

        const authResult: auth0.Auth0DecodedHash = result;
        if (authResult && authResult.accessToken && authResult.idToken) {
          const duration = moment.duration(authResult.expiresIn, 'seconds').humanize();
          this.log(`Access token renewed successfully for ${duration}`);
          this.setSession(authResult);
        } else {
          this.warn('Renew authentication did nothing.', authResult);
        }
      });
    });
  }

  private setSession(authResult: auth0.Auth0DecodedHash): void {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
    localStorage.setItem(ID_TOKEN, authResult.idToken);
    localStorage.setItem(EXPIRES_AT, expiresAt);
    this.scheduleRenewal();
  }

  private redirect(): void {
    const returnUrl = localStorage.getItem(RETURN_URL);
    if (returnUrl) {
      localStorage.removeItem(RETURN_URL);
      this.router.navigateByUrl(returnUrl);
    } else {
      this.router.navigate(DEFAULT_RETURN_URL);
    }
  }

  private isCurrentUser(userInfo: Auth0UserInfo, accessToken: string): boolean {
    const jwt = new JwtHelper();
    const accessTokenInfo = jwt.decodeToken(accessToken);
    return userInfo.sub === accessTokenInfo.sub;
  }
}
