import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {L10N_LOCALE, L10nLocale} from 'angular-l10n';
import * as _ from 'lodash';
import {ModalDirective} from 'ngx-bootstrap/modal';

import {ImpersonableUserVM} from '../../models/impersonable-user.vm';
import {ImpersonateUser} from '../../models/impersonate-user';

@Component({
  selector: 'storever-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonateUserComponent {
  @ViewChild('impersonateUserModal') modal: ModalDirective;
  @Input() users: ImpersonableUserVM[] = [];
  @Output() impersonate = new EventEmitter<ImpersonateUser>();
  @Output() reload = new EventEmitter<void>();

  itemsPerPage = 10;
  currentPage = 1;
  userFilter = '';

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, cdr: ChangeDetectorRef) {}

  open(): void {
    if (this.users) {
      this.itemsPerPage = 10;
      this.currentPage = 1;
      this.userFilter = '';
      this.modal.show();
    }
  }

  close(): void { this.modal.hide(); }

  reloadList(): void { this.reload.emit(); }

  impersonateUser(userId: string): void {
    if (userId) {
      const user = _.find(this.users, u => u.uuid === userId);
      this.impersonate.emit({ uuid: user.uuid, name: user.name });
      this.modal.hide();
    }
  }

  pageSizeChanged(itemsPerPage: number) { this.itemsPerPage = +itemsPerPage; }

  gotoPage(page: number) { this.currentPage = +page; }
}
