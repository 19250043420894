import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {SendBackResultCode} from '../../models/send-back-result-code.enum';

@Component({
  selector: 'storever-send-back-result',
  templateUrl: './send-back-result.component.html',
  styleUrls: ['./send-back-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendBackResultComponent {
  @Input() result: SendBackResultCode;

  get isSuccess(): boolean { return this.result === SendBackResultCode.Success; }

  get isWarning(): boolean { return this.result === SendBackResultCode.Warning; }

  get isError(): boolean { return this.result === SendBackResultCode.Error; }

  get isDeleted(): boolean { return this.result === SendBackResultCode.Deleted; }

  get isAccessDenied(): boolean { return this.result === SendBackResultCode.AccessDenied; }
}
