import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {L10N_LOCALE, L10nLocale, L10nTranslationService} from 'angular-l10n';
import * as _ from 'lodash';
import {CustomValidators} from 'ng2-validation';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {debug} from '../../../../rxjs-operators';
import {PasswordStrengthService, SendBackResultCode} from '../../../shared';
import {ResetPasswordForm} from '../../models/reset-password-form';
import {AnonymousUserService} from '../../services/anonymous-user.service';

@Component({ selector: 'storever-reset-password', templateUrl: './reset-password.component.html', styleUrls: ['./reset-password.component.scss'] })
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;
  submitting = false;
  resultCode: SendBackResultCode;
  score: string;
  strength: string;
  strengthDesc: string;
  isNew: boolean;
  errorMsg: string;
  private subs: Subscription[] = [];

  constructor(private userService: AnonymousUserService,
              private passwordStrengthService: PasswordStrengthService,
              fb: FormBuilder,
              @Inject(L10N_LOCALE) public locale: L10nLocale,
              private translation: L10nTranslationService,
              activatedRoute: ActivatedRoute) {
    this.strength = 'N/A';
    this.strengthDesc = 'N/A';
    this.score = undefined;
    const newPassword = new FormControl(undefined, Validators.compose([Validators.required, Validators.minLength(4)]));
    const confirmPassword = new FormControl(undefined, CustomValidators.equalTo(newPassword));
    this.resetPasswordForm =
      fb.group({ email: [activatedRoute.snapshot.queryParams['email']], code: [activatedRoute.snapshot.queryParams['code']], newPassword, confirmPassword });
    this.isNew = activatedRoute.snapshot.queryParams['new'] === 'true';
  }

  ngOnInit(): void {

    this.errorMsg = 'AUTH_RESET_PASSWORD_ERROR';
    this.subs.push(this.passwordStrengthService.loadCommonPassword('/assets/common-passwords.json')
                     .pipe(
                       switchMap(() => this.passwordStrengthService.loadTrigraphMap('/assets/trigraphs.json')),
                       switchMap(() => this.resetPasswordForm.get('newPassword').valueChanges),
                       )
                     .subscribe(password => {
                       if (!_.isEmpty(password)) {
                         const result = this.passwordStrengthService.check(password);
                         this.strength = this.translation.translate('PASSWORD_STRENGTH_' + result.strengthCode);
                         this.strengthDesc = this.translation.translate('PASSWORD_STRENGTH_' + result.strengthCode + '_DESC');
                         this.score = result.strengthCode;
                       } else {
                         this.score = undefined;
                         this.strength = 'N/A';
                         this.strengthDesc = 'N/A';
                       }
                     }));
  }

  ngOnDestroy(): void {
    // super.cancelParamSubscriptions();
    while (this.subs.length > 0) {
      const sub: Subscription = this.subs.pop();
      if (sub) {
        sub.unsubscribe();
      }
    }
  }

  submitForm(form: ResetPasswordForm): void {
    if (form.newPassword !== form.confirmPassword) {
      return;
    }

    this.submitting = true;
    this.subs.push(this.userService.resetPassword(form.email, form.code, form.newPassword)
                     .pipe(debug('Reset password result'))
                     .subscribe(
                       res => {
                         this.submitting = false;
                         this.resultCode = res.statusCode;
                       },
                       (res) => {
                         this.errorMsg = res.error.translatableMessage && (res.error.translatableMessage !== undefined) ? res.error.translatableMessage
                                                                                                                        : 'AUTH_RESET_PASSWORD_ERROR';
                         this.submitting = false;
                         this.resultCode = SendBackResultCode.Error;
                       }));
  }

  tryAgain($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.submitting = false;
    this.resultCode = undefined;
  }
}
