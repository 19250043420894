import {Action} from '@ngrx/store';

import {ShippingInfo, type} from '../../shared';

export const ShippingInfoActionTypes = {
  LOAD: type('[Player] Load Shipping Info'),
  LOAD_SUCCESS: type('[Player] Load Shipping Info Success'),
  LOAD_FAIL: type('[Player] Load Shipping Info Fail')
};

export class LoadShippingInfoAction implements Action {
  readonly type = ShippingInfoActionTypes.LOAD;

  constructor() {}
}

export class LoadShippingInfoSuccessAction implements Action {
  readonly type = ShippingInfoActionTypes.LOAD_SUCCESS;

  constructor(public payload?: ShippingInfo[]) {}
}

export class LoadShippingInfoFailAction implements Action {
  readonly type = ShippingInfoActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
