import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {ActionReducerMap, createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import * as _ from 'lodash';

import {Auth0UserInfo} from '../models/auth0-user-info';
import {Preference} from '../models/preferences';
import {RouterStateUrl} from '../models/router-state-url';

import {alertsReducer, alertsSelectors, AlertsState} from './alerts';
import {alertPlayersDeconnectReducer, AlertPlayersDeconnectState, alertsPlayersDeconnectSelectors} from './alertsPlayerDeconnected';
import {clientConfigurationReducer, clientConfigurationSelectors, ClientConfigurationState} from './client-configuration';
import {culturesReducer, culturesSelectors, CulturesState} from './cultures';
import {externalLinksReducer, externalLinksSelectors, ExternalLinksState} from './external-links';
import {groupRolesReducer, groupRolesSelectors, GroupRolesState} from './group-roles';
import {groupRolesTemplatesReducer, groupRolesTemplatesSelectors, GroupRolesTemplatesState} from './group-roles-templates';
import {impersonableUsersReducer, impersonableUsersSelectors, ImpersonableUsersState} from './impersonable-users';
import {layoutReducer, layoutSelectors, LayoutState} from './layout';
import {myClientsReducer, myClientsSelectors, MyClientsState} from './my-clients';
import {officesReducer, officesSelectors, OfficesState} from './offices';
import {productionLineReducer, productionLineSelectors, ProductionLineState} from './production-line';
import {quickSearchReducer, quickSearchSelectors, QuickSearchState} from './quick-search';
import {sfTicketDetailReducer, sfTicketDetailSelectors, SFTicketDetailState} from './sfTicketDetail';
import {timezonesReducer, timezonesSelectors, TimezonesState} from './timezones';
import {userContextReducer, userContextSelectors, UserContextState} from './user-context';
import {userInfoReducer, userInfoSelectors, UserInfoState} from './user-info';

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  layout: LayoutState;
  myClients: MyClientsState;
  userInfo: UserInfoState;
  userContext: UserContextState;
  impersonableUsers: ImpersonableUsersState;
  cultures: CulturesState;
  sfTicket: SFTicketDetailState;
  productionLine: ProductionLineState;
  offices: OfficesState;
  clientConfiguration: ClientConfigurationState;
  timezones: TimezonesState;
  quickSearch: QuickSearchState;
  alerts: AlertsState;
  groupRoles: GroupRolesState;
  groupRolesTemplates: GroupRolesTemplatesState;
  externalLinks: ExternalLinksState;
  alertsPlayersDeconnect: AlertPlayersDeconnectState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  layout: layoutReducer,
  myClients: myClientsReducer,
  userInfo: userInfoReducer,
  userContext: userContextReducer,
  impersonableUsers: impersonableUsersReducer,
  cultures: culturesReducer,
  sfTicket: sfTicketDetailReducer,
  offices: officesReducer,
  clientConfiguration: clientConfigurationReducer,
  timezones: timezonesReducer,
  quickSearch: quickSearchReducer,
  alerts: alertsReducer,
  groupRoles: groupRolesReducer,
  groupRolesTemplates: groupRolesTemplatesReducer,
  externalLinks: externalLinksReducer,
  alertsPlayersDeconnect: alertPlayersDeconnectReducer,
  productionLine: productionLineReducer,
};

const rootSelectors = {
  router: (state: any) => _.get(state, 'router'),
  layout: (state: AppState) => _.get(state, 'layout'),
  myClients: (state: AppState) => _.get(state, 'myClients'),
  userInfo: (state: AppState) => _.get(state, 'userInfo'),
  userContext: (state: AppState) => _.get(state, 'userContext'),
  impersonableUsers: (state: AppState) => _.get(state, 'impersonableUsers'),
  cultures: (state: AppState) => _.get(state, 'cultures'),
  sfTicket: (state: AppState) => _.get(state, 'sfTicket'),
  offices: (state: AppState) => _.get(state, 'offices'),
  clientConfiguration: (state: AppState) => _.get(state, 'clientConfiguration'),
  timezones: (state: AppState) => _.get(state, 'timezones'),
  quickSearch: (state: AppState) => _.get(state, 'quickSearch'),
  alerts: (state: AppState) => _.get(state, 'alerts'),
  groupRoles: (state: AppState) => _.get(state, 'groupRoles'),
  groupRolesTemplates: (state: AppState) => _.get(state, 'groupRolesTemplates'),
  externalLinks: (state: AppState) => _.get(state, 'externalLinks'),
  alertsPlayersDeconnect: (state: AppState) => _.get(state, 'alertsPlayersDeconnect'),
  productionLine: (state: AppState) => _.get(state, 'productionLine')
};

export interface Selectors {
  getRouterState: MemoizedSelector<AppState, any>;
  getNavigationId: MemoizedSelector<AppState, any>;
  getSidebarClosed: MemoizedSelector<AppState, any>;
  getWarningMessagesClosed: MemoizedSelector<AppState, any>;
  getLanguage: MemoizedSelector<AppState, any>;
  getMyClients: MemoizedSelector<AppState, any>;
  getUserInfo: MemoizedSelector<AppState, Auth0UserInfo>;
  getCurrentClient: MemoizedSelector<AppState, any>;
  getMyRoles: MemoizedSelector<AppState, any>;
  getImpersonateUser: MemoizedSelector<AppState, any>;
  getImpersonableUsers: MemoizedSelector<AppState, any>;
  getCultures: MemoizedSelector<AppState, any>;
  getSfTicket: MemoizedSelector<AppState, any>;
  getOffices: MemoizedSelector<AppState, any>;
  getClientConfiguration: MemoizedSelector<AppState, any>;
  getTimezones: MemoizedSelector<AppState, any>;
  getQuickSearchFilter: MemoizedSelector<AppState, any>;
  getQuickSearchResults: MemoizedSelector<AppState, any>;
  getQuickSearchCount: MemoizedSelector<AppState, any>;
  getQuickSearchShowModal: MemoizedSelector<AppState, any>;
  getAlerts: MemoizedSelector<AppState, any>;
  getGroupRoles: MemoizedSelector<AppState, any>;
  getGroupRolesTemplates: MemoizedSelector<AppState, any>;
  getExternalLinks: MemoizedSelector<AppState, any>;
  getAlertsPlayersDeconnect: MemoizedSelector<AppState, any>;
  getPreferences: MemoizedSelector<AppState, Preference[]>;
  getPreferencesByName: MemoizedSelectorWithProps<AppState, string, any>;
  getProductionLine: MemoizedSelector<AppState, any[]>;
}

export const selectors: Selectors = {
  getRouterState: createSelector(rootSelectors.router, (router: RouterReducerState) => _.get(router, 'state')),
  getNavigationId: createSelector(rootSelectors.router, (router: RouterReducerState) => _.get(router, 'navigationId')),
  getSidebarClosed: createSelector(rootSelectors.layout, layoutSelectors.sidebarClosed),
  getWarningMessagesClosed: createSelector(rootSelectors.layout, layoutSelectors.warningMessageClosed),
  getLanguage: createSelector(rootSelectors.layout, layoutSelectors.language),
  getMyClients: createSelector(rootSelectors.myClients, myClientsSelectors.array),
  getUserInfo: createSelector(rootSelectors.userInfo, userInfoSelectors.value),
  getCurrentClient: createSelector(rootSelectors.userContext, userContextSelectors.client),
  getMyRoles: createSelector(rootSelectors.userContext, userContextSelectors.roles),
  getImpersonateUser: createSelector(rootSelectors.userContext, userContextSelectors.impersonate),
  getImpersonableUsers: createSelector(rootSelectors.impersonableUsers, impersonableUsersSelectors.array),
  getCultures: createSelector(rootSelectors.cultures, culturesSelectors.array),
  getSfTicket: createSelector(rootSelectors.sfTicket, sfTicketDetailSelectors.array),
  getOffices: createSelector(rootSelectors.offices, officesSelectors.array),
  getClientConfiguration: createSelector(rootSelectors.clientConfiguration, clientConfigurationSelectors.array),
  getTimezones: createSelector(rootSelectors.timezones, timezonesSelectors.array),
  getQuickSearchFilter: createSelector(rootSelectors.quickSearch, quickSearchSelectors.filter),
  getQuickSearchResults: createSelector(rootSelectors.quickSearch, quickSearchSelectors.array),
  getQuickSearchCount: createSelector(rootSelectors.quickSearch, quickSearchSelectors.count),
  getQuickSearchShowModal: createSelector(rootSelectors.quickSearch, quickSearchSelectors.showModal),
  getAlerts: createSelector(rootSelectors.alerts, alertsSelectors.array),
  getGroupRoles: createSelector(rootSelectors.groupRoles, groupRolesSelectors.array),
  getGroupRolesTemplates: createSelector(rootSelectors.groupRolesTemplates, groupRolesTemplatesSelectors.array),
  getExternalLinks: createSelector(rootSelectors.externalLinks, externalLinksSelectors.array),
  getAlertsPlayersDeconnect: createSelector(rootSelectors.alertsPlayersDeconnect, alertsPlayersDeconnectSelectors.array),
  getProductionLine: createSelector(rootSelectors.productionLine, productionLineSelectors.array),
  getPreferences: createSelector(rootSelectors.layout, layoutSelectors.preferences),
  getPreferencesByName: createSelector(rootSelectors.layout, (state: LayoutState, props: string) => layoutSelectors.preferencesByName(state, props)),
};
