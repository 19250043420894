import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {CountriesActionTypes, LoadCountriesSuccessAction} from '../actions/countries';
import {Country} from '../models/country';

export interface CountriesState {
  array: Country[];
}

const initialState: CountriesState = {
  array: []
};

export function countriesReducer(state = initialState, action: Action): CountriesState {
  switch (action.type) {
    case CountriesActionTypes.LOAD:
      return handleLoadAction();
    case CountriesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case CountriesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): CountriesState {
  return initialState;
}

function handleLoadSuccessAction(state: CountriesState, action: LoadCountriesSuccessAction): CountriesState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: CountriesState): CountriesState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

export const countriesSelectors = {
  array: (state: CountriesState) => _.get(state, 'array', [])
};
