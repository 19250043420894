import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {
  ChangeQuickSearchPageSizeAction,
  LoadQuickSearchAction,
  LoadQuickSearchSuccessAction,
  OrderQuickSearchByAction,
  PageQuickSearchToAction,
  QuickSearchAction,
  QuickSearchActionType
} from '../actions/quick-search';
import {DEFAULT_PAGE_SIZE} from '../consts';
import {QuickSearchFilter} from '../models/quick-search-filter';
import {QuickSearchResult} from '../models/quick-search-result';

export interface QuickSearchState {
  filter: QuickSearchFilter;
  array: QuickSearchResult[];
  count: number;
  showModal: boolean;
}

const DEFAULT_QUICK_SEARCH: QuickSearchFilter = {
  pattern: '',
  $page: 1,
  $length: DEFAULT_PAGE_SIZE,
  $orderBy: undefined
};
const initialState: QuickSearchState = {
  filter: DEFAULT_QUICK_SEARCH,
  array: [],
  count: 0,
  showModal: false
};

export function quickSearchReducer(state = initialState, action: Action): QuickSearchState {
  switch (action.type) {
    case QuickSearchActionType.SEARCH:
      return handleSearchAction(action);
    case QuickSearchActionType.PAGE_TO:
      return handlePageToAction(state, action);
    case QuickSearchActionType.CHANGE_PAGE_SIZE:
      return handleChangePageSizeAction(state, action);
    case QuickSearchActionType.ORDER_BY:
      return handleOrderByAction(state, action);
    case QuickSearchActionType.LOAD:
      return handleLoadAction(state, action);
    case QuickSearchActionType.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case QuickSearchActionType.LOAD_VALIDATION_FAIL:
    case QuickSearchActionType.LOAD_FAIL:
      return handleLoadFailActions();
    case QuickSearchActionType.CLOSE:
      return handleCloseAction();
    default:
      return state;
  }
}

function handleSearchAction(action: QuickSearchAction): QuickSearchState {
  const newState: QuickSearchState = { filter: Object.assign({}, DEFAULT_QUICK_SEARCH, { pattern: action.payload }), array: [], count: 0, showModal: false };
  return newState;
}

function handlePageToAction(state: QuickSearchState, action: PageQuickSearchToAction): QuickSearchState {
  const newState: QuickSearchState = _.cloneDeep(state);
  newState.filter.$page = action.payload;
  return newState;
}

function handleChangePageSizeAction(state: QuickSearchState, action: ChangeQuickSearchPageSizeAction): QuickSearchState {
  const newState: QuickSearchState = _.cloneDeep(state);
  newState.filter.$length = action.payload;
  return newState;
}

function handleOrderByAction(state: QuickSearchState, action: OrderQuickSearchByAction): QuickSearchState {
  const newState: QuickSearchState = _.cloneDeep(state);
  newState.filter.$orderBy = action.payload;
  return newState;
}

function handleLoadAction(state: QuickSearchState, action: LoadQuickSearchAction): QuickSearchState {
  if (action.payload) {
    return state;
  }

  const newState: QuickSearchState = _.cloneDeep(state);
  newState.array = [];
  newState.count = 0;
  return newState;
}

function handleLoadSuccessAction(state: QuickSearchState, action: LoadQuickSearchSuccessAction): QuickSearchState {
  const newState: QuickSearchState = _.cloneDeep(state);
  newState.array = action.payload.array;
  newState.count = action.payload.count;
  if (action.payload.count > 1) {
    newState.showModal = true;
  }

  return newState;
}

function handleLoadFailActions(): QuickSearchState {
  return initialState;
}

function handleCloseAction(): QuickSearchState {
  return initialState;
}

export const quickSearchSelectors = {
  filter: (state: QuickSearchState) => _.get(state, 'filter', DEFAULT_QUICK_SEARCH),
  array: (state: QuickSearchState) => _.get(state, 'array', []),
  count: (state: QuickSearchState) => _.get(state, 'count', 0),
  showModal: (state: QuickSearchState) => _.get(state, 'showModal', false)
};
