import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppSettingsService, BaseRestService, SendBackResult} from '../../shared';

@Injectable({ providedIn: 'root' })
export class AnonymousUserService extends BaseRestService {
  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'AnonymousUserService'; }

  protected get isAnonymous(): boolean { return true; }

  isOtpAuthorized(email: string, code: string): Observable<SendBackResult<{}>> {
    /*return this.appSettingsService.settings$
      .switchMap(() => this.post(`/users/otp-validation`, { email, code }));*/
    return this.post<{}>(`/users/otp-validation`, { email, code });
  }

  lostPassword(email: string): Observable<SendBackResult<{}>> { return this.post<{}>(`/users/lost-password`, { email }); }

  resetPassword(email: string, code: string, password: string): Observable<SendBackResult<{}>> {
    return this.post<{}>(`/users/reset-password`, { email, code, password });
  }
}
