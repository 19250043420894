import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AlertPlayersDeconnect} from '../../players/models/alert-player-deconnect';
import {SendBackResult} from '../models/send-back-result';
import {AppSettingsService} from './app-settings.service';
import {BaseRestService} from './base-rest.service';

@Injectable({ providedIn: 'root' })
export class AlertsPlayerDeconnectService extends BaseRestService {

  constructor(http: HttpClient, appSettingsService: AppSettingsService) { super(http, appSettingsService); }

  protected get className(): string { return 'AlertsPlayerDeconnectService'; }
  protected get isAnonymous(): boolean { return false; }

  // tslint:disable-next-line:no-any
  debugApi(resp: any): any {
    resp.subscribe(data => {
      // tslint:disable-next-line:no-console
      console.log(data);
    });
    return resp;
  }
  getList(uuid: string): Observable<SendBackResult<AlertPlayersDeconnect[]>> { return this.get(`/clients/${uuid}/alerts/playerOffline`); }
  saveList(uuid: string, alerts: AlertPlayersDeconnect[]): Observable<SendBackResult<AlertPlayersDeconnect[]>> {
    return this.put<AlertPlayersDeconnect[]>(`/clients/${uuid}/alerts/playerOffline`, alerts);
  }
  // tslint:disable-next-line:no-any
  deleteElem(uuid: string, action: any): Observable<Response> {
    const id = action.payload;
    return this.delete(`/clients/${uuid}/alerts/playerOffline/${id}`);
  }
  // tslint:disable-next-line:no-any
  test(uuid: string, action: any): Observable<SendBackResult<AlertPlayersDeconnect[]>> {
    return this.post(`/clients/${uuid}/alerts/playerOffline/${action.payload}/test`, { id: action.payload });
  }
}
