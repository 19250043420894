import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {UserContextActionTypes} from '../../shared';
import {
  AudioScheduledMessagesActionTypes,
  LoadAudioScheduledMessagesSuccessAction,
  ToggleAudioScheduledMessagesPlayAction,
  ToggleSearchAudioScheduledMessagesAction
} from '../actions/audio-scheduled-messages';
import {AudioScheduledMessage} from '../models/audio-scheduled-message';

export interface AudioScheduledMessagesState {
  showFilter: boolean;
  array: AudioScheduledMessage[];
  count: number;
  playing: string;
}

const initialState: AudioScheduledMessagesState = {
  showFilter: false,
  array: [],
  count: 0,
  playing: undefined
};

export function audioScheduledMessagesReducer(state = initialState, action: Action): AudioScheduledMessagesState {
  switch (action.type) {
    case AudioScheduledMessagesActionTypes.TOGGLE_SEARCH:
      return handleToggleSearchAction(state, action);
    case AudioScheduledMessagesActionTypes.CLEAR:
      return handleClearAction(state);
    case AudioScheduledMessagesActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case AudioScheduledMessagesActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    case UserContextActionTypes.CHANGE_CLIENT:
      return handleChangeClientAction();
    case AudioScheduledMessagesActionTypes.TOGGLE_PLAY:
      return handleTogglePlayAction(state, action);
    default:
      return state;
  }
}

function handleToggleSearchAction(state: AudioScheduledMessagesState, action: ToggleSearchAudioScheduledMessagesAction): AudioScheduledMessagesState {
  const newState: AudioScheduledMessagesState = { showFilter: action.payload, array: state.array, count: state.count, playing: state.playing };
  return newState;
}

function handleClearAction(state: AudioScheduledMessagesState): AudioScheduledMessagesState {
  const newState: AudioScheduledMessagesState = { showFilter: state.showFilter, array: [], count: 0, playing: undefined };
  return newState;
}

function handleLoadSuccessAction(state: AudioScheduledMessagesState, action: LoadAudioScheduledMessagesSuccessAction): AudioScheduledMessagesState {
  const newState: AudioScheduledMessagesState = { showFilter: state.showFilter, array: action.payload.array, count: action.payload.count, playing: undefined };
  return newState;
}

function handleLoadFailAction(state: AudioScheduledMessagesState): AudioScheduledMessagesState {
  const newState: AudioScheduledMessagesState = { showFilter: state.showFilter, array: [], count: 0, playing: undefined };
  return newState;
}

function handleChangeClientAction(): AudioScheduledMessagesState {
  return { showFilter: false, array: [], count: 0, playing: undefined };
}

function handleTogglePlayAction(state: AudioScheduledMessagesState, action: ToggleAudioScheduledMessagesPlayAction): AudioScheduledMessagesState {
  if (state.playing === action.payload) {
    return {...state, playing: undefined };
  } else {
    return {...state, playing: action.payload };
  }
}

export const audioScheduledMessagesSelectors = {
  showFilter: (state: AudioScheduledMessagesState) => _.get(state, 'showFilter', false),
  array: (state: AudioScheduledMessagesState) => _.get(state, 'array', []),
  count: (state: AudioScheduledMessagesState) => _.get(state, 'count', 0),
  playing: (state: AudioScheduledMessagesState) => _.get(state, 'playing')
};
