import {Action} from '@ngrx/store';
import {type} from '../../shared';

export const CreatePostponeActionTypes = {
  CREATE: type('[Postpone] Create'),
  CREATE_SUCCESS: type('[Postpone] Create Success'),
  CREATE_FAIL: type('[Postpone] Create Fail')
};

export class CreatePostponeAction implements Action {
  readonly type = CreatePostponeActionTypes.CREATE;
  constructor(public payload?: any) {}
}

export class CreatePostponeSuccessAction implements Action {
  readonly type = CreatePostponeActionTypes.CREATE_SUCCESS;
  constructor(public payload?: string) {}
}

export class CreatePostponeFailAction implements Action {
  readonly type = CreatePostponeActionTypes.CREATE_FAIL;
  constructor(public payload?: number) {}
}
