export const ID_TOKEN = 'id_token';
export const ACCESS_TOKEN = 'access_token';
export const EXPIRES_AT = 'expires_at';
export const RETURN_URL = 'return_url';
export const USER_INFO = 'user_info';
export const DEFAULT_RETURN_URL: any[] = [''];
export const CUSTOM_CLAIMS_NAMESPACE = 'https://storever.com/';
export const CUSTOM_CLAIMS_NAME = CUSTOM_CLAIMS_NAMESPACE + 'name';
// export const CUSTOM_CLAIMS_LANG = CUSTOM_CLAIMS_NAMESPACE + 'lang';
export const CUSTOM_CLAIMS_DEFAULT_CLIENT_ID = CUSTOM_CLAIMS_NAMESPACE + 'default_client_uuid';
// export const CUSTOM_CLAIMS_DEFAULT_CLIENT_NAME = CUSTOM_CLAIMS_NAMESPACE + 'default_client_name';
export const CUSTOM_CLAIMS_IS_STOREVER = CUSTOM_CLAIMS_NAMESPACE + 'is_storever';
export const CUSTOM_CLAIMS_USER_UUID = CUSTOM_CLAIMS_NAMESPACE + 'user_uuid';
export const IMPERSONATION_TOKEN = 'impersonation_token';
export const IMPERSONATION_DURATION = 20 * 60; // 20 minutes in seconds
export const MANAGEMENT_API_TOKEN = 'management_api_token';
export const DEFAULT_PAGE_SIZE = 10;
export const SCROLL_OFFSET = 300;
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const BACKGROUND_XHR_PARAM = 'z47uiTo-gz-lygIvB8-UaU07tHU19Q27';

export const PLANNING = 'showPlanning';
export const PLAYLIST = 'showPlaylist';
export const PLAY_LOGS = 'showPlayLogs';
export const EVENT_LOGS = 'showEventLogs';
export const COMMAND_LOGS = 'showCommandLogs';
export const SCHEDULED_MESSAGES = 'showScheduledMessages';
export const UPDATE_SERVER = 'showUpdateServer';
export const LOGS_ON_SERVER = 'showLogsOnServer';
export const OPENING_HOURS = 'showOpeningHours';
export const SHOW_UPDATE_SCHEDULE = 'showUpdateSchedule';
export const INVENTORY = 'showInventory';
