<form class="forget-form" [formGroup]="lostPasswordForm" (ngSubmit)="submitForm(lostPasswordForm.value)" novalidate>

  <div *ngIf="!email">
    <h3 class="font-brand" translate>AUTH_LOST_PASSWORD_TITLE</h3>
    <p translate>AUTH_LOST_PASSWORD_PARA</p>
  </div>

  <div *ngIf="email">
    <h3 class="font-brand" translate>AUTH_RENEW_PASSWORD_TITLE</h3>
    <p translate>AUTH_RENEW_PASSWORD_PARA</p>
  </div>

  <storever-send-back-result [result]="resultCode">
    <div class="alert alert-danger error" translate>AUTH_LOST_PASSWORD_ERROR</div>
    <div class="alert alert-success success">
      {{ 'AUTH_LOST_PASSWORD_SUCCESS' | translate:locale.language }}
      <a [routerLink]="['/login']" class="alert-link" translate>AUTH_GOTO_LOGIN_PAGE</a>.
    </div>
  </storever-send-back-result>
  <div class="form-group">
    <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
    <label class="control-label visible-ie8 visible-ie9" translate>AUTH_LOST_PASSWORD_EMAIL</label>
    <input class="form-control placeholder-no-fix" type="text" autocomplete="off"
           [placeholder]="'AUTH_LOST_PASSWORD_EMAIL' | translate:locale.language" [formControl]="lostPasswordForm.get('email')" />
    <span *ngIf="lostPasswordForm.get('email').dirty && lostPasswordForm.get('email').hasError('required')"
          class="help-block error" translate>AUTH_LOST_PASSWORD_EMAIL_REQUIRED</span>
    <span *ngIf="lostPasswordForm.get('email').dirty && lostPasswordForm.get('email').hasError('email')"
          class="help-block error" translate>AUTH_LOST_PASSWORD_EMAIL_INVALID</span>
  </div>
  <div class="form-actions">
    <button type="button" class="btn brand btn-outline" onclick="history.back();" [disabled]="submitting" translate>AUTH_LOST_PASSWORD_BACK</button>
    <button type="submit" class="btn brand uppercase pull-right" [disabled]="!lostPasswordForm.valid || submitting">
      <ng-template #default>{{ 'AUTH_LOST_PASSWORD_SUBMIT' | translate:locale.language }}</ng-template>
      <ng-template [ngIf]="submitting" [ngIfElse]="default">{{ 'AUTH_LOST_PASSWORD_SUBMITTING' | translate:locale.language }}</ng-template>
    </button>
  </div>
</form>
<storever-block-ui [isRunning]="submitting"></storever-block-ui>
