import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {Product, UserContextActionTypes} from '../../shared';
import {LoadProductsSuccessAction, ProductsActionTypes} from '../actions/products';

export interface ProductsState {
  array: Product[];
}

const initialState: ProductsState = {
  array: []
};

export function productsReducer(state = initialState, action: Action): ProductsState {
  switch (action.type) {
    case ProductsActionTypes.LOAD:
      return handleLoadAction();
    case ProductsActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case ProductsActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    // case UserContextActionTypes.CHANGE_CLIENT:
    //   return handleChangeClientAction();
    default:
      return state;
  }
}

function handleLoadAction(): ProductsState {
  return initialState;
}

function handleLoadSuccessAction(state: ProductsState, action: LoadProductsSuccessAction): ProductsState {
  const newState = _.clone(state);
  newState.array = action.payload;
  return newState;
}

function handleLoadFailAction(state: ProductsState): ProductsState {
  const newState = _.clone(state);
  newState.array = [];
  return newState;
}

function handleChangeClientAction(): ProductsState {
  return { array: [] };
}

export const productsSelectors = {
  array: (state: ProductsState) => _.get(state, 'array', [])
};
