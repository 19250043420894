import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';
import {Culture} from '../models/culture';

@Pipe({ name: 'commonCulture' })
export class CommonCulturePipe implements PipeTransform {
  transform(value: Culture[], common: boolean): Culture[] {
    if (value == undefined) {
      return undefined;
    }

    return _.filter(value, c => c.common === common);
  }
}
