import {Action} from '@ngrx/store';

import {Office} from '..';
import {type} from '../utilities/type';

export const officesActionTypes = {
  LOAD: type('[Offices] Load'),
  LOAD_SUCCESS: type('[Offices] Load Success'),
  LOAD_FAIL: type('[Offices] Load Fail')
};

export class LoadOfficesAction implements Action {
  readonly type = officesActionTypes.LOAD;

  constructor() {}
}

export class LoadOfficesSuccessAction implements Action {
  readonly type = officesActionTypes.LOAD_SUCCESS;

  constructor(public payload?: Office[]) {}
}

export class LoadOfficesFailAction implements Action {
  readonly type = officesActionTypes.LOAD_FAIL;

  constructor(public payload?: number) {}
}
