import {Action} from '@ngrx/store';
import * as _ from 'lodash';

import {LoadUserInfoSuccessAction, UserInfoActionTypes} from '../actions/user-info';
import {Auth0UserInfo} from '../models/auth0-user-info';

export interface UserInfoState {
  value: Auth0UserInfo;
}

const initialState: UserInfoState = {
  value: undefined
};

export function userInfoReducer(state = initialState, action: Action): UserInfoState {
  switch (action.type) {
    case UserInfoActionTypes.LOAD:
      return handleLoadAction();
    case UserInfoActionTypes.LOAD_SUCCESS:
      return handleLoadSuccessAction(state, action);
    case UserInfoActionTypes.LOAD_FAIL:
      return handleLoadFailAction(state);
    default:
      return state;
  }
}

function handleLoadAction(): UserInfoState {
  return initialState;
}

function handleLoadSuccessAction(state: UserInfoState, action: LoadUserInfoSuccessAction): UserInfoState {
  const newState = _.clone(state);
  newState.value = action.payload;
  return newState;
}

function handleLoadFailAction(state: UserInfoState): UserInfoState {
  const newState = _.clone(state);
  newState.value = undefined;
  return newState;
}

export const userInfoSelectors = {
  value: (state: UserInfoState) => _.get(state, 'value')
};
